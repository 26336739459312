/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Text, Flex } from '@aws-amplify/ui-react';
import Select from 'react-select';

interface Props {
  title: string;
  value: any;
  handleChange: (e: { label: string; value: string }) => any;
  options: {
    label: string;
    value: string | number;
  }[];
  minWidth?: string;
  inputId: string;
  ariaLabel: string;
}

const InsightsSelect: React.FC<Props> = ({
  title,
  value,
  handleChange,
  options,
  minWidth = '150px',
  inputId,
  ariaLabel,
}) => (
  <Flex direction="column" gap="0.25rem" testId={`select-insights-input-${title}`} minWidth={minWidth}>
    <Text
      className="select-business-label"
      data-testid="select-business-label"
      style={{ marginBottom: '0px', fontWeight: '400' }}
    >
      {title}
    </Text>
    <Select
      value={value}
      inputId={inputId}
      options={options}
      maxMenuHeight={215}
      onChange={handleChange}
      aria-label={ariaLabel}
    />
  </Flex>
);

export default InsightsSelect;

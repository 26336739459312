import { SavedReportDataItems } from 'models';
import makeVarPersisted from 'apollo/MakeVarPersisted';

const initialState: SavedReportDataItems = {
  savedAnswers: [],
};

/**
 * Answers that have been saved to the server.
 */
export const latestSavedReportDataItems = makeVarPersisted<SavedReportDataItems>(
  initialState,
  'aspLatestSavedReportDataItems'
);

import { gql } from '@apollo/client';

export const GET_CONSENT_FORM = gql`
  query GetAuthoriseDetails($dsp: String) {
    getConsentFormURL(dsp: $dsp) {
      consentFormUrl
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
    }
  }
`;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ReportListActions } from 'components/ReportComponents/ReportList/ReportList';
import { SavedActionListNumericItem } from 'models';
import actionListAverage from 'services/actionListAverage';
import actionListCount from 'services/actionListCount';
import actionListSum from 'services/actionListSum';
import { currencyFormat } from './CurrencyFormat';

export const getActionResultAndLabel = (
  action: ReportListActions,
  items: SavedActionListNumericItem[],
  formatted = true
) => {
  let actionResult;
  let actionLabel;
  const sumValue = actionListSum((items || []).map((x) => Number(x.dspValue) || Number(x.userValue) || 0));
  switch (action) {
    case ReportListActions.SUM:
      actionLabel = 'Total';
      actionResult = formatted ? currencyFormat.format(sumValue) : sumValue;
      break;
    case ReportListActions.COUNT:
      actionLabel = 'Count';
      actionResult = `${actionListCount((items || []).map((x) => Number(x.dspValue) || Number(x.userValue)))}`;
      break;
    case ReportListActions.AVG:
      actionLabel = 'Average';
      actionResult = currencyFormat.format(
        Math.floor(actionListAverage((items || []).map((x) => Number(x.dspValue) || Number(x.userValue) || 0)))
      );
      break;
    /* istanbul ignore next */
    default:
      actionLabel = '';
      actionResult = '';
  }
  return { actionLabel, actionResult };
};

/*
  Assumes string input of:
    (1) 10 numbers e.g., 0422123456, or 
    (2) a '+' followed by 11 numbers e.g., +61422123456
  
  If (1) then should return 0422 123 456
  If (2) then should return +61 422 123 456

  Also accepts differing formats on input such as 04 22 12 34 56

*/

export const formatPhoneNumber = (number: string) =>
  number.charAt(0).match(/\+/)
    ? number.replace(/ /g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
    : number.replace(/ /g, '').replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useReactiveVar } from '@apollo/client';
import { Flex, Table, TableCell, TableHead, TableBody, TableRow, Text, VisuallyHidden } from '@aws-amplify/ui-react';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { DataItem, SavedActionListNumeric, SavedReportDataItems } from 'models';
import { compareTopicStrings } from 'utils/CompareTopicStrings';
import { currencyFormat } from 'utils/CurrencyFormat';

import { ReactComponent as TopRemainingIconSvg } from 'assets/icon-asterix-blue.svg';
import './TopRemaining.css';

interface TopRemainingProps {
  accountType: string;
}

const TopRemainingReview = (props: TopRemainingProps) => {
  const { accountType } = props;
  const savedItems = useReactiveVar(savedReportDataItems);
  const topTwoRemainingItems = getTopTwoRemainingItems(savedItems, accountType);

  if (!topTwoRemainingItems.length) return null;

  const minifiedTitle = `Name and value of the two largest remaining ${accountType.toLowerCase()} accounts`;

  return (
    <Flex direction="column" rowGap="0" aria-live="polite" paddingTop="8px">
      <Text testId="top-remaining-title" fontWeight="semibold" marginLeft="-12px">
        <TopRemainingIconSvg style={{ verticalAlign: 'super', marginRight: '4px' }} />
        {minifiedTitle}
      </Text>
      <Table className="remaining-items-table" testId="top-remaining-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <VisuallyHidden>account</VisuallyHidden>
            </TableCell>
            <TableCell>
              <VisuallyHidden>amount</VisuallyHidden>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topTwoRemainingItems.map((account) => (
            <TableRow key={`${account.responseUid}-review`}>
              <TableCell testId="top-remaining-table-cell-type">{account.accountName}</TableCell>
              <TableCell className="remaining-items-table-right-col" testId="top-remaining-table-cell-amount">
                {currencyFormat.format(Number(account.dspValue ?? account.userValue))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Flex>
  );
};

export const findSortDisplays = (dataItems: DataItem[]) => {
  const sortDisplays = dataItems
    .filter((item) => item.action.match(/SORTDISPLAY/))
    .map((sortDisplayItem) => {
      const item = sortDisplayItem.action.match(/SORTDISPLAY=(\d{4})(?:_.|\s|$)/);
      return item !== null ? item[1] : null;
    });

  return dataItems.filter((dataItem) => sortDisplays.includes(dataItem.dataItemId));
};

export const getTopTwoRemainingItems = (savedItems: SavedReportDataItems, accountType: string) =>
  savedItems.savedAnswers
    .filter((i) => {
      const dataItem = i.savedReportDataItem as SavedActionListNumeric;
      return (
        compareTopicStrings(i.topic!, accountType) &&
        dataItem.action?.match(/listsum/i) &&
        dataItem.dataItemText?.match(/remaining/i)
      );
    })
    .flatMap((i) => (i.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems)
    .sort((a, b) => Number(b.dspValue || b.userValue) - Number(a.dspValue || a.userValue))
    .slice(0, 2);

export default TopRemainingReview;

import { makeVar } from '@apollo/client';

/**
 * Represents a request to hold the saveReport mutex.
 * Used as a unique identifier for the request (by object equality).
 * Resolve with the mutex release callback to give the caller the mutex.
 */
interface SaveReportMutexCall {
  resolve: (value: () => void) => void;
  reject: (reason?: any) => void;
}

/**
 * Reactive var with some info that can be displayed for debug purposes.
 */
export const saveReportMutexDebugState = makeVar({ queued: 0 });

/**
 * All current requests for the save report mutex.
 *
 * The first element represents the call that currently has the lock.
 */
const saveReportMutexCalls: SaveReportMutexCall[] = [];

/**
 * Generate a 'release' callback that will release the save report mutex for
 * the given token (by removing it from the calls queue)
 *
 * @param mutexToken the call object representing the specific call
 * @returns a callback that will release the mutex token, and give it to the next caller if present
 */
const releaseSaveReportMutex = (mutexToken: SaveReportMutexCall) => () => {
  const firstToken = saveReportMutexCalls[0];

  if (firstToken === mutexToken) {
    // remove the token
    saveReportMutexCalls.shift();
    saveReportMutexDebugState({ queued: saveReportMutexCalls.length });
    // resolve new first token with its own release function
    const newFirstToken = saveReportMutexCalls[0];
    if (newFirstToken) {
      newFirstToken.resolve(releaseSaveReportMutex(newFirstToken));
    }
  } else {
    // Not a recoverable state
    console.error('releaseSaveReportMutex got wrong token in release call');
  }
};

// TODO: allow passing in timeout that will remove this call from the queue and reject the promise
export const acquireSaveReportMutex = async () => {
  let mutexToken: SaveReportMutexCall | undefined;
  const mutexPromise = new Promise<ReturnType<typeof releaseSaveReportMutex>>((resolve, reject) => {
    mutexToken = { resolve, reject };
  });

  // mutexToken will always be definedbecause the promise executor runs synchronously, but this proves it to TS.
  if (!mutexToken) {
    throw new Error('failed to generate mutex token for save report');
  }

  saveReportMutexCalls.push(mutexToken);
  saveReportMutexDebugState({ queued: saveReportMutexCalls.length });

  // If this is already first in the queue, resolve immediately with the release callback
  if (saveReportMutexCalls[0] === mutexToken) {
    mutexToken.resolve(releaseSaveReportMutex(mutexToken));
  }

  return mutexPromise;
};

import { externalLinks } from 'lib/externalLinks';

export const InsightsMeasures = [
  {
    name: 'Sales to wages ratio',
    tagName: 'sales to wages ratio',
    key: 'M12',
    title: 'Quarterly sales to wages ratio',
    shortTitle: 'sales to wages ratio',
    whatIsIt: 'The total sales for a given period divided by the total wages for the same period.',
    whyItMatters:
      'This data provides an indication of how much sales revenue is made for each dollar spent on employee wages and how this has changed over time.',
    keepInMind: 'The figures are estimates and can change as more data comes in.',
    color: '#901216',
    link: externalLinks.absBusinessIndicatorsSalesToWages,
    showOnWidget: false,
    label: 'Ratio',
    isPercentage: false,
  },
  {
    name: 'Sales',
    tagName: 'income from sales',
    key: 'M2',
    title: 'Quarterly percentage change in sales of goods and services',
    shortTitle: 'sales of goods and services',
    whatIsIt: 'The money businesses make by selling goods or services.',
    whyItMatters: 'This data shows how active an industry has been over time.',
    color: '#2CAFFE',
    link: externalLinks.absBusinessIndicatorsSales,
    showOnWidget: true,
  },
  {
    name: 'Wages',
    tagName: 'wages',
    key: 'M6',
    title: 'Quarterly percentage change in wages and salaries',
    shortTitle: 'wages and salaries',
    whatIsIt: 'The money paid to employees by organisations before tax and other deductions are taken out.',
    whyItMatters: 'This data shows how wages paid by businesses in an industry has changed over time.',
    color: '#134A71',
    link: externalLinks.absBusinessIndicatorsWages,
    showOnWidget: true,
  },
  {
    name: 'Profits',
    tagName: 'profits',
    key: 'M8',
    title: 'Quarterly percentage change in company gross operating profits',
    shortTitle: 'company gross operating profits',
    whatIsIt: 'The money an organisation makes less expenses and before income tax is taken out.',
    whyItMatters: 'This data gives an indication of how profitable businesses in an industry have been over time.',
    keepInMind: 'The figures are estimates and can change as more data comes in.',
    color: '#DE7C13',
    link: externalLinks.absBusinessIndicatorsProfit,
    showOnWidget: true,
  },
];

import { gql } from '@apollo/client';

export const CLAIM_OBLIGATION = gql`
  query ClaimObligation($organisationId: String, $role: String, $dsp: String, $noObligation: NoObligation) {
    claimObligation(organisationId: $organisationId, role: $role, dsp: $dsp, noObligation: $noObligation) {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
      dspAbn
      dspBusinessName
      pegaAbn
      pegaBusinessName
      obligationId
      organisationId
    }
  }
`;

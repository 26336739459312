import Error from 'components/Error/Error';
import { ErrorPageStatus } from 'enums/ErrorPageStatus';
import { ErrorContent } from './content';

// ErrorContent[0] = 404 Error Page
// ErrorContent[1] = 403 Error page revoke access from DSP
// ErrorContent[2] = 500 Error
// ErrorContent[3] = 401 Error
// ErrorContent[4] = 404 Generic Error Page

interface Props {
  selectedError?: number;
}

const ErrorPage: React.FC<Props> = ({ selectedError }) => {
  const selectErrorPage = ErrorContent[selectedError ?? ErrorPageStatus.Generic404];
  return (
    <Error
      title={selectErrorPage.title}
      summary={selectErrorPage.summary}
      content={selectErrorPage.content}
      img={selectErrorPage.img}
    />
  );
};
export default ErrorPage;

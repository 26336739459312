import { DspDataItemObject, SavedActionListNumericItem, SavedReportDataItem } from 'models';
import { cleanseDspDataItems } from 'utils/cleanseDspDataItems';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { savingLocalAnswersToServer, syncingDspItems } from 'apollo/states/operationsInProgress';

/**
 * Update local saved answers with new values from DSP.
 *
 * @param uncleansedDspDataItems new dsp data items from getReport query, without cleansing
 * @returns promise of a boolean indicating success of sync
 */
export const syncFetchedDspDataItems = (uncleansedDspDataItems: DspDataItemObject[]) =>
  new Promise<boolean>((resolve) => {
    const beginSync = () => {
      syncingDspItems(true);
      const result = actualSync(uncleansedDspDataItems);
      syncingDspItems(false);
      resolve(result);
    };

    if (!savingLocalAnswersToServer()) {
      beginSync();
      return;
    }

    const syncOrWait = (saving: boolean) => {
      if (saving) {
        savingLocalAnswersToServer.onNextChange(syncOrWait);
      } else {
        beginSync();
      }
    };

    savingLocalAnswersToServer.onNextChange(syncOrWait);
  });

/**
 *
 * @param uncleansedDspDataItems dspData items directly from graphQL with no cleanup yet
 * @returns true if sync was successful (including if nothing had changed)
 */
const actualSync = (uncleansedDspDataItems: DspDataItemObject[]) => {
  const newDspDataItems = cleanseDspDataItems(uncleansedDspDataItems);

  // loop for optimistic locking: repeat if data changes between read and write
  let retries = 0;
  const maxRetries = 3;
  while (retries < maxRetries) {
    const { savedAnswers } = savedReportDataItems();

    const [changed, mappedSavedAnswers] = mapWithDirtyState(savedAnswers, (savedItem) =>
      updateSavedAnswerDspValue(savedItem, newDspDataItems)
    );

    if (changed && savedReportDataItems().savedAnswers !== savedAnswers) {
      // changes to save, but baseline changed so not safe to save
      retries += 1;
      // eslint-disable-next-line no-continue
      continue;
    }

    // nothing changed during mapping, safe to update without clobbering values
    if (changed) savedReportDataItems({ savedAnswers: mappedSavedAnswers });

    // always update DSP values, even if no mapped answers changed
    const previousReportData = currentReportData();
    currentReportData({ ...previousReportData, dspDataItems: newDspDataItems });

    return true;
  }

  return false;
};

/**
 * Update a saved item if it is mapped from DSP and the DSP item has changed
 *
 * @param savedItem current saved item that may be updated
 * @param changedDspDataItems data items that have changed since last DSP update
 * @returns tuple of changed and the item. When changed is false it is savedItem unmodified, if true it is
 *   a copy of savedItem with updates.
 */
const updateSavedAnswerDspValue = (
  savedItem: SavedReportDataItem,
  changedDspDataItems: DspDataItemObject[]
): readonly [boolean, SavedReportDataItem] => {
  const { savedReportDataItem } = savedItem;

  // Only SavedActionListNumeric appears to have DSP mappings
  if (!('selectedReportDataItems' in savedReportDataItem)) return [false, savedItem];

  const copyChangedDspValuesToSelectedReportDataItem = (
    item: SavedActionListNumericItem
  ): [boolean, SavedActionListNumericItem] => {
    const { responseUid } = item;

    const dspItem = changedDspDataItems.find((i) => i.responseUid === responseUid);

    // no updated item to copy values from
    if (!dspItem) return [false, item];

    let itemChanged = false;
    // always an overwrite if there are changes, otherwise this is discarded
    const changedSelectedItem: SavedActionListNumericItem = { ...item, overwrite: true };

    if (item.accountName !== dspItem.accountName) {
      itemChanged = true;
      changedSelectedItem.accountName = dspItem.accountName;
    }
    if (item.class !== dspItem.class) {
      itemChanged = true;
      changedSelectedItem.class = dspItem.class;
    }
    if (item.code !== dspItem.code) {
      itemChanged = true;
      changedSelectedItem.code = dspItem.code;
    }
    if (item.dspValue !== dspItem.dspValue) {
      itemChanged = true;
      changedSelectedItem.dspValue = dspItem.dspValue;
    }
    if (item.type !== dspItem.type) {
      itemChanged = true;
      changedSelectedItem.type = dspItem.type;
    }
    if (item.suggestForDataItem !== dspItem.suggestForDataItem) {
      itemChanged = true;
      changedSelectedItem.suggestForDataItem = dspItem.suggestForDataItem;
    }
    // These timestamps update every time we read from the DSP
    // so we only want to update them if something else has already changed
    if (itemChanged && item.dspDateRead !== dspItem.dspDateSave) {
      changedSelectedItem.dspDateRead = dspItem.dspDateSave;
    }

    if (!itemChanged) return [false, item];

    return [true, changedSelectedItem];
  };

  // Filter to exclude items that mapped to archived/removed accounts
  const stillValidSelectedReportDataItems = savedReportDataItem.selectedReportDataItems.filter((item) => {
    const { responseUid } = item;
    const dspItem = changedDspDataItems.find((i) => i.responseUid === responseUid);
    const hasDspItem = typeof dspItem !== 'undefined';
    const isManualEntry = typeof item.userValue !== 'undefined';
    return isManualEntry || hasDspItem;
  });

  const lengthChanged = stillValidSelectedReportDataItems.length !== savedReportDataItem.selectedReportDataItems.length;

  const [changed, updatedSelectedReportDataItems] = mapWithDirtyState(
    stillValidSelectedReportDataItems,
    copyChangedDspValuesToSelectedReportDataItem
  );

  if (!lengthChanged && !changed) return [false, savedItem];

  const changedItem = {
    ...savedItem,
    savedReportDataItem: {
      ...savedReportDataItem,
      selectedReportDataItems: updatedSelectedReportDataItems,
    },
  };

  return [true, changedItem];
};

// Can make a version with different input and output type,
// but would not be able to return unchanged input array
const mapWithDirtyState = <T>(
  input: Array<T>,
  callback: (item: T) => readonly [boolean, T]
): readonly [boolean, Array<T>] => {
  let dirty = false;
  const result = input.map((i) => {
    const [changed, mapped] = callback(i);
    if (changed) dirty = true;
    return mapped;
  });

  return [dirty, dirty ? result : input] as const;
};

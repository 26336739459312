import { Text } from '@aws-amplify/ui-react';
import { ReportStatus } from 'enums/ReportStatus';
import dayjs from 'dayjs';
import { DAYNUM_MONTHNAME_YEAR } from 'utils/dayjsFormats';

const getProgressContent = (status: ReportStatus, dueDate: number, dateSubmitted?: number): string => {
  const dueDateObj = dayjs(dueDate).startOf('day');
  const dateSubmittedObj = dayjs(dateSubmitted);

  if (status === ReportStatus.UnderReview || status === ReportStatus.New || status === ReportStatus.Edit) {
    const today = dayjs().startOf('day');

    if (dueDateObj.isSame(today, 'day')) return 'Due today';

    const dueDays = dueDateObj.diff(today, 'days');
    const displayDueDays = Math.abs(dueDays);

    return `${displayDueDays} ${displayDueDays === 1 ? 'day' : 'days'} ${dueDays > 0 ? 'left to complete' : 'overdue'}`;
  }

  if (status === ReportStatus.NotSubmitted) {
    return '';
  }

  if (status === ReportStatus.Submitted || status === ReportStatus.SubmittedFeedback) {
    if (dateSubmitted) {
      return `Submitted ${dateSubmittedObj.format(DAYNUM_MONTHNAME_YEAR)}`;
    }

    return '';
  }

  return 'Loading...';
};

interface ReportProgressTextProps {
  status: ReportStatus;
  dueDate: number;
  dateSubmitted?: number;
}
const ReportProgressText = ({ status, dueDate, dateSubmitted }: ReportProgressTextProps) => {
  const content = getProgressContent(status, dueDate, dateSubmitted);
  return (
    <Text className="report-progress-text" testId="report-progress-text">
      {content}
    </Text>
  );
};

export default ReportProgressText;

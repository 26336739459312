import { Flex, Loader, VisuallyHidden } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

import './LoadingSpinner.css';

interface LoadingSpinnerProps {
  fullPage?: boolean;
}
const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ fullPage = false }) => (
  <Flex height={!fullPage ? '50%' : ''} className="spinning-loader-container" role="status">
    <VisuallyHidden>Loading...</VisuallyHidden>
    <Loader testId="spinning-loader" className="spinning-loader" aria-label="Loading..." />
  </Flex>
);

export default LoadingSpinner;

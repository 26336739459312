import { Menu, MenuItem, MenuButton } from '@aws-amplify/ui-react';

import { ReactComponent as TrashIconSvg } from 'assets/icon-trash.svg';
import { ReactComponent as MoreIconSvg } from 'assets/icon-more-horizontal.svg';
import { ReactComponent as EditIconSvg } from 'assets/icon-edit.svg';
import './ReportList.scss';

interface MoreMenuProps {
  removeCallback: () => void;
  editCallback: () => void;
  editDisabled: boolean;
}

const MoreMenu = (props: MoreMenuProps) => {
  const { removeCallback, editCallback, editDisabled } = props;

  return (
    <Menu
      className="report-list-actions-menu"
      size="small"
      menuAlign="end"
      trigger={
        <MenuButton
          autoFocus
          className="report-list-item-action-btn"
          title="more"
          testId="report-list-item-action-btn"
          onKeyDown={(event) => {
            if (event.code === 'Enter') event.nativeEvent.preventDefault();
          }}
        >
          <MoreIconSvg />
        </MenuButton>
      }
    >
      <MenuItem
        testId="report-list-more-edit-btn"
        onClick={editCallback}
        data-testid="report-list-more-edit-btn"
        isDisabled={editDisabled}
      >
        <EditIconSvg width={20} />
        Edit
      </MenuItem>
      <MenuItem testId="report-list-more-remove-btn" onClick={removeCallback} data-testid="report-list-more-remove-btn">
        <TrashIconSvg width={20} />
        Delete
      </MenuItem>
    </Menu>
  );
};

export default MoreMenu;

// noinspection GraphQLUnresolvedReference

import { gql } from '@apollo/client';
import { GetReportResponse } from 'models';

// subset of GetReportResponse
export type GetDspDataItemsResponse = {
  getReport: Pick<GetReportResponse['getReport'], 'success' | 'errorMessages'> & {
    report: Pick<GetReportResponse['getReport']['report'], 'reportStatus' | 'dspDataItems'>;
  };
};

export const GET_DSP_DATA_ITEMS = gql`
  query GetDspDataItems($dspProvider: String!, $userReportId: ID!, $organisationId: ID!) {
    getReport(dspProvider: $dspProvider, userReportId: $userReportId, organisationId: $organisationId) {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
      report {
        reportStatus
        dspDataItems {
          accountName
          class
          code
          dspDateSave
          dspValue
          isActive
          responseUid
          type
          suggestForDataItem
        }
      }
    }
  }
`;

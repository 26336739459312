import { TopicDetail } from 'models';
import { CompiledRule } from 'utils/parseTopicRule';

/**
 * Define an "opaque" type that is stored as a simple type but cannot be accidentally swapped.
 */
type Opaque<K, T> = T & { __TYPE__: K };

/**
 * Topic name, from TopicDetail.topic on the API but with real spaces instead of %20.
 *
 * Used to find relevant data items by their .topic field.
 *
 * The part of the type after string prevents TS from inferring that this is just
 * a string, so that it will not let us accidentally use this as a TopicKey.
 */
export type TopicName = Opaque<'TopicName', string>;

/**
 * Topic URL key, the path component for a topic.
 *
 * Located here in the path: /report/{TopicKey}
 *
 * The part of the type after string prevents TS from inferring that this is just
 * a string, so that it will not let us accidentally use this as a TopicName.
 */
export type TopicKey = Opaque<'TopicKey', string>;

export type TopicTotalDisplay = 'SHOW' | 'HIDE';

// Would this and similar client-side versions of types be better named like LocalTopicDetail?
/**
 * Similar to TopicDetail from the API, but optimised for use in
 * the front-end code by including pre-computed fallbacks for all
 * possible values.
 */
export interface TopicFullDetail extends TopicDetail {
  // stronger type
  topic: TopicName;

  // Changed to be required as all fallbacks are pre-computed
  topicTitle: string;
  topicShortTitle: string;
  topicTotalText: string;
  totalDisplay: TopicTotalDisplay;

  // Convenience fields to be pre-computed
  key: TopicKey;
  path: string;
  isFeedback: boolean;
  // rule pre-compiled ready to efficiently apply
  compiledRule?: CompiledRule;
}

export function getTopicByKey(topics: TopicFullDetail[], key: TopicKey) {
  return topics.find((t) => t.key === key);
}

export function getTopicByName(topics: TopicFullDetail[], name: TopicName) {
  return topics.find((t) => t.topic === name);
}

export function getTopicByPath(topics: TopicFullDetail[], path: string) {
  return topics.find((t) => t.path === path);
}

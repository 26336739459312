import { SavedReportDataItem } from 'models';

export const asSavedActionNumber = (savedItem: SavedReportDataItem['savedReportDataItem'] | undefined) => {
  if (savedItem && 'savedNumber' in savedItem) return savedItem;
  return undefined;
};

export const asSavedActionText = (savedItem: SavedReportDataItem['savedReportDataItem'] | undefined) => {
  if (savedItem && 'savedText' in savedItem) return savedItem;
  return undefined;
};

/**
 * Get this back as a SavedActionListNumeric, if it is one, otherwise undefined
 * @param savedItem to check for being a SavedActionListNumeric
 * @returns the given item, or undefined if it is not a SavedActionListNumeric
 */
export const asSavedActionListNumeric = (savedItem: SavedReportDataItem['savedReportDataItem'] | undefined) => {
  if (savedItem && 'selectedReportDataItems' in savedItem) return savedItem;
  return undefined;
};

export const asSavedActionListText = (savedItem: SavedReportDataItem['savedReportDataItem'] | undefined) => {
  if (savedItem && 'savedTextList' in savedItem) return savedItem;
  return undefined;
};

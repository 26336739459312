import { useEffect, useState } from 'react';

export interface PostcodeSearchResult {
  POSTCODE: string;
  SA2_CODE_2021: string;
  SA2_NAME_2021?: string;
  RATIO_FROM_TO?: number;
}

const postcodeDataPath = `${process.env.PUBLIC_URL}/postcodeData`;

const usePostcodeRegions = (query: string | undefined) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [results, setResults] = useState<PostcodeSearchResult[]>([]);

  // Only uses first 3 characters for fetch
  const firstThreeCharacters = query?.slice(0, 3);

  useEffect(() => {
    // json files are only 3 digits, nothing else
    if (!firstThreeCharacters || !/^\d{3}$/.test(firstThreeCharacters)) {
      setError(undefined);
      setResults([]);
      setLoading(false);
      return;
    }

    // specific to this invocation, to prevent using stale response
    let staleRequest = false;

    const fetchData = async () => {
      setLoading(true);

      // postcodes starting 8 or 9 are 3 digits and need 0 prefix
      const fileName = ['8', '9'].includes(firstThreeCharacters[0])
        ? `0${firstThreeCharacters.slice(0, 2)}`
        : firstThreeCharacters;

      const dataFileFullPath = `${postcodeDataPath}/${fileName}.json`;

      try {
        const response = await fetch(dataFileFullPath);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const responseJSON = await response.json();
        const fetchedData = responseJSON as PostcodeSearchResult[];

        if (!staleRequest) {
          setError(undefined);
          setResults(fetchedData);
        }
      } catch (err) {
        if (!staleRequest) {
          setError('Postcode not found');
          setResults([]);
        }
      } finally {
        if (!staleRequest) setLoading(false);
      }
    };

    fetchData().catch(() => {
      setError('Postcode not found');
      setResults([]);
      setLoading(false);
    });

    return () => {
      staleRequest = true;
    };
  }, [firstThreeCharacters]);

  return [loading, error, results] as const;
};

export default usePostcodeRegions;

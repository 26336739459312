import useCurrentReportSteps, { StepID } from 'apollo/states/utils/useCurrentReportSteps';
import ReportButtonGroup from '../ReportButtonGroup/ReportButtonGroup';

interface ReportButtonAutoGroupProps {
  currentStep: StepID;
}

const ReportButtonAutoGroup = ({ currentStep }: ReportButtonAutoGroupProps) => {
  const steps = useCurrentReportSteps();
  const currentIndex = steps.findIndex((step) => step.id === currentStep);

  const backStep = currentIndex > 0 ? steps[currentIndex - 1] : undefined;
  const backLink = backStep?.link;
  const backButton = backLink ? { text: 'Back', link: backLink, useSave: true } : undefined;

  const nextButton = {
    text: currentStep === 'instructions' ? 'Continue' : 'Save and continue',
    link: steps[currentIndex + 1].link,
    useSave: currentStep !== 'instructions',
  };

  return <ReportButtonGroup nextButton={nextButton} backButton={backButton} />;
};

export default ReportButtonAutoGroup;

/*
Expansion of the below ranges:

range       characters
-----       ----------
!-;         ! " # $ % & ' ( ) * + , - . / 0 1 2 3 4 5 6 7 8 9 : ;
!-$         ! " # $
&-;         & ' ( ) * + , - . / 0 1 2 3 4 5 6 7 8 9 : ;
?-z         ? @ A B C D E F G H I J K L M N O P Q R S T U V W X Y Z [ \ ] ^ _ ` a b c d e f g h i j k l m n o p q r s t u v w x y z
À-Ö         À Á Â Ã Ä Å Æ Ç È É Ê Ë Ì Í Î Ï Ð Ñ Ò Ó Ô Õ Ö
Ø-ö         Ø Ù Ú Û Ü Ý Þ ß à á â ã ä å æ ç è é ê ë ì í î ï ð ñ ò ó ô õ ö
ø-ÿ         ø ù ú û ü ý þ ÿ

*/

export const allAllowedCharsRegex = /^[!-;=?-z|~À-ÖØ-öø-ÿ¥£€\s“”‘’…–]*$/gi;

export const nameRegex = /^[a-zÀ-ÖØ-öø-ÿ\-'.]+( [a-zÀ-ÖØ-öø-ÿ\-'.]+)*$/gi; // Allows letters and only a single whitespace between character groups

export const mobilePhoneRegex = /^(\+614|04)\d{8}$/g;

/* istanbul ignore file */
import 'core-js/es/object/from-entries';
import 'core-js/full/global-this';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { detect } from 'detect-browser';
import './index.css';

import { UnsupportedBrowser } from 'pages/UnsupportedBrowser/UnsupportedBrowser';
import App from './App';
import CustomAmplifyTheme from './assets/CustomAmplifyTheme';
import ScrollToTop from './lib/scrollToTop';

const browser = detect();
const unsupportedBrowser = browser?.name === 'safari' && parseInt(browser.version) <= 13;

const root = document.getElementById('root')!;

ReactDOM.createRoot(root).render(
  unsupportedBrowser ? (
    <UnsupportedBrowser />
  ) : (
    <BrowserRouter>
      <CustomAmplifyTheme>
        <ScrollToTop />
        <App />
      </CustomAmplifyTheme>
    </BrowserRouter>
  )
);

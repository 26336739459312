import { TopicName } from 'models/LocalState/TopicFullDetail';

/**
 * Calculate the total for a rule based on other topic totals
 *
 * @param rule compiled rule to use for computation
 * @param topicTotals totals for all topics needed in the rule
 * @returns final value when applying rule computatoin to topic totals
 */
export function computeRuleTotal(rule: CompiledRule, topicTotals: Record<TopicName, number>) {
  const { ruleParts } = rule;
  // assumes only + and - operations. If we add multiplication/division we would need an AST to handle
  // order of operations, and treat all as binary rather than unary operations
  return (
    ruleParts
      // get total as a positive or negative
      .map(({ operation, topicName }) => OPERATION_FUNCTIONS[operation](topicTotals[topicName] ?? 0))
      // and total them up
      .reduce((acc, value) => acc + value, 0)
  );
}

const OPERATION_FUNCTIONS: Record<RuleOperation, (value: number) => number> = {
  initial: (value) => value,
  '+': (value) => value,
  '-': (value) => value * -1,
} as const;

/**
 * Match anything that is not valid topic name placeholders.
 *
 * Capturing parens mean matches will be included in the output of split,
 * and we assume these are the operators.
 */
const RULE_TOKEN_REGEX = /([^$0-9a-zA-Z% ]+)/;

/**
 * Split rule to tokens that are either:
 *
 *  - a TopicName with $ prefix
 *  - an operation (+ or -)
 *
 * @param rule string to tokenize
 * @returns rule tokens
 */
function tokenizeRule(rule?: string) {
  return rule?.split(RULE_TOKEN_REGEX);
}

/**
 * The operations supported in rules. Initial represents the initial value
 * before any operations (i.e. the LHS of the first operation if we were
 * representing them as binary operations).
 */
const VALID_RULE_OPERATIONS = ['initial', '+', '-'] as const;

/**
 * A valid rule operation that is present in VALID_RULE_OPERATIONS,
 * or "initial" for the initial number, equivalent of a + operation but
 * distinct as it will render differently.
 */
type RuleOperation = (typeof VALID_RULE_OPERATIONS)[number];

/**
 * Represents adding or subtracting the total for a topic
 */
interface RulePart {
  operation: RuleOperation;
  topicName: TopicName;
}

export interface CompiledRule {
  // the rule that has been compiled, for reference
  rule: string;
  topicNames: TopicName[];
  ruleParts: RulePart[];
}

/**
 * Compile rule to a series of operations.
 *
 * Note this assumes the rule is well formed, and will only
 * warn of unknown operations.
 *
 * A well-formed rule:
 * - Has topic tokens that are a TopicName prefixed with "$" (e.g. $Income).
 * - Has operation tokens that are "+" or "-".
 * - Alternates topic token and operation token.
 * - Always has an odd number of tokens, because it:
 *     - Always starts with a topic token.
 *     - Always ends with a topic token.
 *
 * Examples of well-formed rules
 *
 */
export function compileRule(rule: string): CompiledRule {
  const tokens = tokenizeRule(rule) ?? [];
  if (!tokens.length) {
    console.warn(`No tokens generated for rule "${rule}"`);
  }

  const ruleParts: RulePart[] = [];
  const allTopicNames: TopicName[] = [];

  while (tokens.length) {
    const topicToken = tokens.pop();
    // first topic has no preceding operation, so it is the 'initial' value
    const operationToken = tokens.pop() ?? 'initial';

    const topicName = tokenToTopicName(topicToken);
    const validOperation = tokenToOperation(operationToken);

    if (!validOperation)
      console.warn(`Unknown operation token: "${operationToken}", fallback to "+", in rule: ${rule}`);
    const operation = validOperation ?? VALID_RULE_OPERATIONS[1];

    if (topicName) {
      // May push duplicates, but necessary to have correct order
      allTopicNames.push(topicName);
      ruleParts.push({ operation, topicName });
    } else {
      console.warn(`Topic token too short: "${topicToken}", in rule: "${rule}"`, {
        remainingTokens: JSON.stringify(tokens),
        operationToken,
        topicToken,
      });
    }
  }

  // ruleParts is built up from end to beginning, but should be in order to
  // generate the human-readable explanation
  ruleParts.reverse();
  // Put topic names in order but do not include later names
  // (not done via Set as deterministic order is essential)
  const topicNames: TopicName[] = [];
  allTopicNames.reverse().forEach((topicName) => topicNames.includes(topicName) || topicNames.push(topicName));

  return { rule, ruleParts, topicNames };
}

/**
 * Convert a token to a RuleOperation, as long as it represents a valid operation.
 *
 * @param operationToken to check for validity
 * @returns the given operation with correct type, or undefined if it is not a valid operation token
 */
function tokenToOperation(operationToken: string) {
  return (VALID_RULE_OPERATIONS as readonly string[]).includes(operationToken)
    ? (operationToken as RuleOperation)
    : undefined;
}

/**
 * Convert a token to a TopicName, as long as it superficially appears valid.
 *
 * Note: valid just means it has 2+ characters, but we could add other checks here.
 *
 * @param topicToken a token that should be a TopicName prefixed with $ (e.g. "$Income")
 */
function tokenToTopicName(topicToken: string | undefined) {
  // First character should be $, so slice that off
  const possibleTopicName = topicToken?.slice(1);
  return possibleTopicName?.length ? (possibleTopicName as TopicName) : undefined;
}

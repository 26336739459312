/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AuthState, OktaAuthOptions, UserClaims } from '@okta/okta-auth-js';
import { env } from './env';

const {
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_OKTA_DOMAIN,
  REACT_APP_OKTA_ACC,
  REACT_APP_LOGIN_CALLBACK,
  REACT_APP_GUESTROLEARN,
  REACT_APP_IDENTITYPOOLID,
  REACT_APP_APPLICATION_ID,
} = env;

export const OKTA_TOKEN_STORAGE_KEY = 'okta-token-storage-asp';

export const oktaConfig: OktaAuthOptions = {
  clientId: REACT_APP_OKTA_CLIENT_ID,
  issuer: `https://${REACT_APP_OKTA_DOMAIN}/oauth2/${REACT_APP_OKTA_ACC}`,
  redirectUri: `${window.location.origin}/${REACT_APP_LOGIN_CALLBACK}`, // this makes it so redirects to login if not logged in for secure routes
  scopes: ['openid', 'profile', 'email', 'phone', 'offline_access'],
  pkce: true,
  tokenManager: {
    storageKey: OKTA_TOKEN_STORAGE_KEY,
  },
};

export const rumConfig = {
  guestRoleArn: REACT_APP_GUESTROLEARN,
  identityPoolId: REACT_APP_IDENTITYPOOLID,
  APPLICATION_ID: REACT_APP_APPLICATION_ID,
};

// Add any new custom ABS-OKTA user claims to this type
export type AbsUserClaims = UserClaims<{
  mobile?: string;
  firstName?: string;
  lastName?: string;
}>;

export function getLocalOktaToken(): string | undefined {
  try {
    const tokenStore = localStorage.getItem(OKTA_TOKEN_STORAGE_KEY);
    if (tokenStore === null) return undefined;
    const tokenObj = JSON.parse(tokenStore) as AuthState;
    return tokenObj.idToken?.idToken;
  } catch (e) {
    return undefined;
  }
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const OKTAVERIFIED_PARAM = false;

const ErrorRedirect: React.FC<{ error: Error }> = ({ error }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (error.message === 'User status is invalid.') {
      navigate('/', { replace: true, state: { OKTAVERIFIED_PARAM: true } });
    } else {
      navigate('/error');
    }
  }, [error, navigate]);

  return null;
};

export default ErrorRedirect;

import { InlineLabel } from 'components/InlineLabel/InlineLabel';
import { useState, useEffect } from 'react';
import 'utils/animation.css';

export const GetReportTimerLabel = () => {
  const [timeMessage, setTimeMessage] = useState('');

  useEffect(() => {
    const updateTimeMessage = (message: string, delay: number) => setTimeout(() => setTimeMessage(message), delay);
    const timer1 = updateTimeMessage('This report is taking longer to load than expected.', 10000);
    const timer2 = updateTimeMessage("We're having trouble loading this report.", 30000);
    const timer3 = updateTimeMessage("We couldn't load this report. Please try again later.", 60000);
    // Clean up the timers on component unmount
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  return timeMessage ? (
    <div className="long-text fade-in">
      <InlineLabel message={timeMessage} type="info" />
    </div>
  ) : null;
};

import { from, HttpLink } from '@apollo/client';
import { env } from 'lib/env';

const { REACT_APP_APPSYNC_API_ENDPOINT } = env;

type ApiSimulationLinkModule = typeof import('./ApiSimulation/apiSimulationLink');
type CaptureApiDataLinkModule = typeof import('./ApiSimulation/captureApiDataLink');

const includeApiSimulationLink = process.env.REACT_APP_API_SIMULATION_LINK === 'active';
const shouldCaptureData = process.env.REACT_APP_CAPTURE_API_DATA === 'true';

/**
 * Conditionally create an apollo link to use with the apollo client.
 *
 * This is only used for development tooling so uses module splitting
 * to prevent downloading the link module or captured test data in
 * the main bundle.
 */
export const createASPAppLink = async (token: string | undefined) => {
  if (!includeApiSimulationLink && !shouldCaptureData) return;

  // Same as default HttpLink implicitly created by providing URI to the client
  const httpLink = new HttpLink({
    // This should be inherited according to docs, but does not seem to work in practice
    uri: REACT_APP_APPSYNC_API_ENDPOINT,
    headers: {
      authorization: token ?? '',
    },
  });

  if (!includeApiSimulationLink) {
    // Just data capture without simulation - use separate link (otherwise, data capture is done in
    // apiSimulationLink for any request that is not simulated)
    const captureApiDataLinkModule: CaptureApiDataLinkModule = await import('./ApiSimulation/captureApiDataLink');
    const captureApiDataLink = captureApiDataLinkModule.default;
    return from([captureApiDataLink, httpLink]);
  }

  const apiSimulationLinkModule: ApiSimulationLinkModule = await import('./ApiSimulation/apiSimulationLink');
  const apiSimulationLink = apiSimulationLinkModule.default;

  // By default we provide only uri to the client and it uses an implicit HttpLink.
  // This replaces it with a link-chain where apiSimulationLink can intercept requests
  // and give its own responses during development.
  // See Apollo Link docs for an overview.
  return from([apiSimulationLink, httpLink]);
};

export default createASPAppLink;

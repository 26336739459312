import { useOktaAuth } from '@okta/okta-react';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import LoadingSpinner from 'components/Loading/LoadingSpinner';

interface SecureRouteProps {
  children: ReactElement;
}
const SecureRoute = (props: SecureRouteProps) => {
  const { children } = props;
  const { authState } = useOktaAuth();

  // TODO: Just for dev environment to bypass auth guard. To be removed
  if (process.env.NODE_ENV === 'development') return children;

  if (!authState) return <LoadingSpinner />;

  return authState.isAuthenticated ? children : <Navigate to="/" />;
};
export default SecureRoute;

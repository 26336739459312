const stripSpacesAndLowercase = (text: string) => text.replaceAll('%20', '').replaceAll(' ', '').toLowerCase();

/**
 * Loose comparison of topic name strings: case insensitive and with %20 and spaces removed
 *
 * @deprecated now that %20 is replaced when mapping from TopicDetail to TopicFullDetail and
 *             with TopicName and TopicKey properly distinguished.
 *
 * @param first topic name to compare
 * @param second topic name to compare
 * @returns true if topic names match case-insensitively and without whitespace
 */
export const compareTopicStrings = (first: string, second: string): boolean =>
  stripSpacesAndLowercase(first) === stripSpacesAndLowercase(second);

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router';

import { recordRumCustomEvent, recordRumError } from 'services/awsRum';
import Dashboard from 'components/Dashboard/Dashboard';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { setErrorState } from 'utils/setErrorState';
import { AuthorisedBusiness, GetUserProfile } from 'models';
import { GET_USER_PROFILE } from 'apollo/queries/getUserProfile';
import { GET_USER_PROVIDER_REPORTS } from 'apollo/queries/getUserProviderReports';
import { ClearReportStateData, evictAllGetReportFromCache } from 'apollo/states/utils/ClearReportStateData';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSyncDspBusinessNames } from 'utils/useSyncDspBusinessNames';

const DashboardPage = () => {
  const navigate = useNavigate();
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const [callSyncDspBusinessNames] = useSyncDspBusinessNames();

  const [callGetUserProfile, { loading }] = useLazyQuery<GetUserProfile | undefined>(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: async (response) => {
      if (response?.getUserProfile.user?.accessDetails && response.getUserProfile.user.accessDetails.length > 0) {
        const sortedAuthorisedBusinesses = response.getUserProfile.user.accessDetails
          .map(
            (business): AuthorisedBusiness => ({
              dsp: business.dsp || '',
              id: business.organisationDetails?.organisationId || '',
              name: business.organisationDetails?.providerDetails.name || '',
              suburb: business.organisationDetails?.providerDetails.address?.suburb || '',
              postcode: business.organisationDetails?.providerDetails.address?.postcode || '',
              dspBusinessName: business.organisationDetails?.dspBusinessName || '',
              providerId: business.organisationDetails?.providerDetails.pegaProviderId || '',
              division: business.organisationDetails?.providerDetails.division || '',
            })
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        const serverSavedActiveBusiness = sortedAuthorisedBusinesses.find(
          (business) => business.id === response.getUserProfile.user?.lastActiveBusiness?.organisationId
        );

        const localSavedActiveBusiness = sortedAuthorisedBusinesses.find(
          (business) => business.id === currentBusinesses.activeBusiness?.id
        );

        // Setting next active business
        const nextActiveBusiness =
          localSavedActiveBusiness ?? serverSavedActiveBusiness ?? sortedAuthorisedBusinesses[0];

        if (!currentBusinesses.activeBusiness?.name)
          recordRumCustomEvent(
            RumCustomEvent.businessLoad,
            getRumAttributes({ providerId: nextActiveBusiness?.providerId })
          );

        await callSyncDspBusinessNames({
          activeBusiness: nextActiveBusiness,
          authorisedBusinesses: sortedAuthorisedBusinesses,
        });
      } else {
        await callSyncDspBusinessNames({ activeBusiness: undefined, authorisedBusinesses: [] });
      }
    },
    onError: (error) => {
      authorisedBusinesses({ activeBusiness: undefined, authorisedBusinesses: [] });
      recordRumError(error);
      setErrorState(error);
      navigate('/error');
    },
  });

  const [callGetUserProviderReports, { data }] = useLazyQuery<GetUserProfile | undefined>(GET_USER_PROVIDER_REPORTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      authorisedBusinesses({ activeBusiness: undefined, authorisedBusinesses: [] });
      recordRumError(error);
      setErrorState(error);
      navigate('/error');
    },
  });

  useEffect(() => {
    ClearReportStateData();
    evictAllGetReportFromCache();
    callGetUserProfile();
    callGetUserProviderReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || (!currentBusinesses.activeBusiness && currentBusinesses.authorisedBusinesses.length === 0)) {
    return <LoadingSpinner fullPage />;
  }

  return (
    <DashboardLayout>
      <div id="skipTarget" />
      <Dashboard data={data?.getUserProfile.user?.accessDetails} />
    </DashboardLayout>
  );
};

export default DashboardPage;

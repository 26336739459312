import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { GET_CONSENT_FORM } from 'apollo/queries/getConsentForm';

// status code doesn't currently exist in the NetworkError type defenition but it is in
// the documentation and implementation of apollo
type UpdatedNetworkError = NetworkError & {
  statusCode: number | null;
};

export const ServiceDownCheck = (setApiDown: (state: boolean) => void) => {
  // using random query until API status query is implemented
  const queryResult = useQuery(GET_CONSENT_FORM);

  useEffect(() => {
    if (!queryResult.error) return;

    const statusCode = (queryResult.error.networkError as UpdatedNetworkError)?.statusCode;

    // filter out other errors that do not mean API is down
    if (typeof statusCode !== 'number' && queryResult.error.message !== 'Failed to fetch') return;

    // check if redirect is needed
    if (statusCode !== 401) {
      setApiDown(true);
    }
  }, [queryResult, queryResult.error, setApiDown]);

  return null;
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { SavedActionListNumericItem } from 'models';

export const dspAccountsCustomSort =
  (dataItemId?: string) =>
  (a: SavedActionListNumericItem, b: SavedActionListNumericItem): number => {
    // Sort manual items (no dspValue) to the top
    if (!a.dspValue && b.dspValue) return -1;
    if (a.dspValue && !b.dspValue) return 1;
    // Then sort suggested dsp items to the top if a dataItemId is supplied
    if (dataItemId) {
      if (a.suggestForDataItem === dataItemId && b.suggestForDataItem !== dataItemId) return -1;
      if (a.suggestForDataItem !== dataItemId && b.suggestForDataItem === dataItemId) return 1;
      if (a.suggestForDataItem === dataItemId && b.suggestForDataItem === dataItemId) return sortByCodeAndName(a, b);
    }
    // Then sort the remaining dsp items
    return sortByCodeAndName(a, b);
  };

const sortByCodeAndName = (a: SavedActionListNumericItem, b: SavedActionListNumericItem): number => {
  // Sort dsp items with a code by their code
  if (!a.code && b.code) return 1;
  if (a.code && !b.code) return -1;
  if (a.code && b.code) return a.code.localeCompare(b.code, 'en', { numeric: true });
  // Finally sort dsp items with no code by name
  return (a.accountName ?? '').localeCompare(b.accountName ?? '', 'en', { numeric: true });
};

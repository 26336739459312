/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import {
  AlertProps,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  TextField,
  View,
  useTheme,
} from '@aws-amplify/ui-react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';

import { recordRumCustomEventWithPageId, recordRumError } from 'services/awsRum';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { externalLinks } from 'lib/externalLinks';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';
import { ClaimObligation } from 'models';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { REVOKE_BUSINESS } from 'apollo/mutations/revokeBusiness';
import { InlineError } from 'components/InlineError/InlineError';
import { formatAbn } from 'utils/formatAbn';
import { getDsp } from 'utils/dspIndex';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import { checkErrorMessages } from 'utils/errorHandling';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';

import ClaimObligationHero from 'assets/claim-obligation.jpg';
import { ReactComponent as SvgChevronRight } from 'assets/icon-chevron-right.svg';
import { ReactComponent as SvgAbsLogo } from 'assets/logo-ABS.svg';
import { ReactComponent as SavingIconSvg } from 'assets/icon-loading-save.svg';

import './ClaimObligationPage.css';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';

type ClaimObligationForm = {
  businessId: string;
};

type ClaimObligationState = {
  businessName: string;
  businessAbn: string;
  dsp: string;
  organisationId: string;
  role: string;
};

let updateCounter = 0;

const errorBannerProps: AlertProps = {
  testId: 'claim-obligation-error-banner',
  isDismissible: true,
  marginLeft: '-32px',
  padding: '24px 0',
};

const ClaimObligationPage = () => {
  const { tokens } = useTheme();
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ClaimObligationForm>();

  const navigate = useNavigate();
  const businessDetails = (useLocation().state as ClaimObligationState) ?? {};

  const dsp = getDsp(businessDetails.dsp);

  const [matchError, setMatchError] = useState(false);
  const [claimedError, setclaimedError] = useState(false);
  const [inlneError, setInlneError] = useState(false);
  const [shouldClaim, setShouldClaim] = useState(false);
  const [pegaDetails, setPegaDetails] = useState({ abn: '', name: '' });

  const [callClaimObligation, claimObligationResponse] = useLazyQuery<ClaimObligation>(CLAIM_OBLIGATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      updateCounter += 1;
      if (response.claimObligation.success) {
        setPegaDetails({
          abn: response.claimObligation.pegaAbn ?? '',
          name: response.claimObligation.pegaBusinessName ?? '',
        });
        if (shouldClaim) {
          setActiveBusinessAfterClaim(claimObligationResponse.variables?.organisationId);
          navigate('/dashboard');
        } else {
          // show second flow
          return setShouldClaim(true);
        }
      }

      const errorMessages = response.claimObligation.errorMessages ?? [];
      if (checkErrorMessages(errorMessages, ApiErrorMsg.invalidClaim)) {
        setMatchError(true);
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)) {
        setclaimedError(true);
      } else {
        setInlneError(true);
      }
    },
    onError: (error) => {
      recordRumError(error);
      setInlneError(true);
    },
  });

  const [callRevokeBusiness] = useMutation(REVOKE_BUSINESS, {
    variables: { dspProvider: businessDetails.dsp, organisationId: businessDetails.organisationId },
  });

  const claimObligationVariables = {
    organisationId: businessDetails.organisationId,
    role: businessDetails.role,
    dsp: businessDetails.dsp,
    updateCounter,
  };

  const trialClaim = (data: ClaimObligationForm) => {
    setInlneError(false);
    setclaimedError(false);
    setMatchError(false);
    callClaimObligation({
      variables: {
        ...claimObligationVariables,
        noObligation: {
          claim: false,
          pegaProviderId: data.businessId,
        },
      },
    });
  };

  const attemptClaim = () => {
    setInlneError(false);
    callClaimObligation({
      variables: {
        ...claimObligationVariables,
        noObligation: {
          claim: true,
          pegaProviderId: getValues().businessId,
        },
      },
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => callRevokeBusiness());
    return () => window.removeEventListener('beforeunload', () => callRevokeBusiness());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className="claim-obligation-container" templateColumns="repeat(12, 1fr)" gap={tokens.space.medium}>
      <Flex direction="column" columnStart={1} columnEnd={7}>
        {matchError && (
          <AlertComponent
            title="Entered reference number does not match our records"
            textHeaderComponent={
              <>
                Your reference number is two letters (AT or MU) followed by eight numbers such as 'AT12345678'. This can
                be found in the email you received asking you to report.
                <br />
                <br />
                If you are still having trouble please{' '}
                <Link
                  href={externalLinks.absContactUs}
                  isExternal
                  onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                >
                  contact us
                </Link>{' '}
                for assistance.
              </>
            }
            variation="error"
            alertProps={errorBannerProps}
          />
        )}
        {claimedError && (
          <AlertComponent
            title="Reference number already claimed"
            textHeaderComponent={
              <>
                That reference number has already been claimed by another organisation.. Please{` `}
                <Link
                  href={externalLinks.absContactUs}
                  isExternal
                  onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                >
                  contact us
                </Link>{' '}
                for assistance.
              </>
            }
            variation="error"
            alertProps={errorBannerProps}
          />
        )}
        <Flex className="claim-obligation-content" direction="column" marginTop="16px">
          {!shouldClaim && (
            <Breadcrumb
              text="Back to select business"
              onClick={() => {
                callRevokeBusiness();
                navigate('/authorisation', { state: { addingBusiness: true } });
              }}
            />
          )}
          <Heading level={1} className="claim-obligation-header" testId="claim-obligation-header">
            We can't find a report for your selected business
          </Heading>
          {!shouldClaim ? (
            <>
              {/* PAGE 1 --- START */}
              <Text className="claim-obligation-body-text" testId="claim-obligation-body-text">
                The Australian business number (ABN) of the business you are trying to authorise does not need to report
                according to our records.
              </Text>
              <Flex direction="column" gap="8px" marginBottom="24px">
                <Heading level={2} className="claim-obligation-body-heading" testId="claim-obligation-body-heading">
                  ABN
                </Heading>
                <Text className="claim-obligation-body-text">
                  {businessDetails?.businessAbn ? formatAbn(businessDetails.businessAbn) : ''}
                </Text>
                <View className="claim-obligation-seperator" />

                <Heading level={2} className="claim-obligation-body-heading" testId="claim-obligation-body-heading">
                  Business Name
                </Heading>
                <Text className="claim-obligation-body-text">{businessDetails.businessName}</Text>
                <View className="claim-obligation-seperator" />
              </Flex>
              <form onSubmit={handleSubmit(trialClaim)}>
                <Text className="claim-obligation-body-text" margin="16px 0">
                  Please enter your reference number
                </Text>
                <Controller
                  name="businessId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="claim-obligation-input"
                      testId="claim-obligation-input"
                      label={
                        <Flex alignItems="center" gap="4px">
                          <Text>Reference number</Text>
                          <InputLabelTooltip
                            title={
                              <Text id="business-id-tooltip" role="tooltip">
                                Your reference number is two letters (AT or MU) followed by eight numbers such as
                                'AT12345678'. This can be found in the email you received asking you to report.
                              </Text>
                            }
                            helpTextFor="Business ID"
                            placement="top-start"
                            arrow
                          />
                        </Flex>
                      }
                      hasError={!!errors.businessId}
                      errorMessage={errors.businessId?.message}
                      // paddingBottom={errors.businessId ? '13px' : '45px'}
                      paddingBottom="16px"
                    />
                  )}
                  rules={{
                    required: 'Reference number is required',
                    pattern: {
                      value: /^[\da-z]{10}$/i,
                      message: 'Reference number must be two letters followed by eight numbers',
                    },
                  }}
                />
                <Flex direction="column" alignItems="flex-start">
                  <Button
                    className="claim-obligation-submit-btn"
                    testId="claim-obligation-submit-btn"
                    variation="primary"
                    type="submit"
                    name="submit-btn"
                    isLoading={claimObligationResponse.loading}
                    loadingText="Loading"
                  >
                    Submit
                  </Button>
                  {inlneError && <InlineError />}
                </Flex>
              </form>
              {/* PAGE 1 --- END */}
            </>
          ) : (
            <>
              {/* PAGE 2 --- START */}
              <Flex direction="column" gap="40px">
                <Grid templateColumns="1fr 1fr" gap="20px" marginRight="30px">
                  <BusinessDetailComparison
                    heading="Business the ABS is asking you to report for"
                    Logo={SvgAbsLogo}
                    abn={pegaDetails.abn}
                    name={pegaDetails.name}
                  />
                  <BusinessDetailComparison
                    heading="Business you attempted to link to ABS Business Reporting"
                    Logo={dsp.authoriseDspIcon}
                    abn={businessDetails.businessAbn}
                    name={businessDetails.businessName}
                  />
                </Grid>
                <Flex direction="column" gap="8px">
                  <Text className="claim-obligation-body-text">
                    The ABS collects data from a specific ABN, and not from a business.
                  </Text>
                  <Text className="claim-obligation-body-text">
                    We may be asking you to report for an ABN associated with a trust. In this case please select the
                    trust from the business selection screen.
                  </Text>
                  <Flex alignItems="center" gap="10px">
                    <NavLink
                      className="claim-obligation-back-link"
                      data-testid="claim-obligation-try-another-link"
                      to="/authorisation"
                      state={{ addingBusiness: true }}
                      onClick={() => callRevokeBusiness()}
                    >
                      Try another business
                    </NavLink>
                    <SvgChevronRight />
                  </Flex>
                </Flex>
                <Flex direction="column" gap="8px">
                  <Text className="claim-obligation-body-text">
                    If the business associated with the ABN we have asked you to report for is no longer operating, you
                    may not need to report.
                  </Text>
                  <Flex alignItems="center" gap="10px">
                    <Link
                      className="claim-obligation-back-link"
                      data-testid="claim-obligation-let-us-know-link"
                      href={externalLinks.absContactUs}
                      isExternal
                      onClick={() =>
                        recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())
                      }
                    >
                      Contact us to let us know
                    </Link>
                    <SvgChevronRight />
                  </Flex>
                </Flex>
                <Flex direction="column" gap="8px">
                  <Text className="claim-obligation-body-text">
                    If you are sure the business you attempted to link is the same as the business we asked you to
                    report for, you can continue below.
                  </Text>
                  <Flex alignItems="center" gap="10px">
                    <Text
                      className="claim-obligation-back-link"
                      data-testid="claim-obligation-back-link"
                      onClick={attemptClaim}
                    >
                      Continue with the business you attempted to link
                    </Text>
                    {claimObligationResponse.loading ? (
                      <SavingIconSvg className="saving-rotate-icon" />
                    ) : (
                      <SvgChevronRight />
                    )}
                  </Flex>
                  {inlneError && <InlineError errorMessage="Sorry, something went wrong. Please try again later." />}
                </Flex>
                <Text marginTop="auto" marginBottom="5px">
                  Need help?{' '}
                  <Link
                    href={externalLinks.absContactUs}
                    isExternal
                    testId="claim-obligation-help-link"
                    onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                  >
                    Contact us!
                  </Link>
                </Text>
              </Flex>
              {/* PAGE 2 --- END */}
            </>
          )}
        </Flex>
      </Flex>
      <Image alt="" src={ClaimObligationHero} columnStart="7" columnEnd="13" width="680px" height="893px" />
    </Grid>
  );
};

const BusinessDetailComparison = ({
  heading,
  Logo,
  abn,
  name,
}: {
  heading: string;
  Logo: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  abn: string;
  name: string;
}) => (
  <Grid templateRows="auto 1fr" gap="16px">
    <Heading className="claim-obligation-comparison" level={2}>
      {heading}
    </Heading>
    <Flex direction="row" alignItems="center">
      <View width="80px">
        <Logo width="80px" />
      </View>
      <Flex direction="column" alignSelf="flex-start">
        <Flex direction="column" gap="4px">
          <Text className="claim-obligation-body-heading">ABN</Text>
          <Text className="claim-obligation-body-text" testId="comparison-abn">
            {formatAbn(abn)}
          </Text>
        </Flex>
        <Flex direction="column" gap="4px">
          <Text className="claim-obligation-body-heading">Business Name</Text>
          <Text className="claim-obligation-body-text" testId="comparison-name">
            {name}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Grid>
);

export default ClaimObligationPage;

import { ReactiveVar } from '@apollo/client';

/**
 * Reactive variables will trigger on every update, even if the value is identical.
 * Sometimes we want to avoid doing all the work and re-checking when the new value is
 * the same as the previous value.
 *
 * This wrapper will return an update function that will check the current value of
 * the wrapped rv is different (according to ===) before updating it.
 *
 * THIS DOES NOT RETURN A REACTIVE VARIABLE! Just an update function that will update
 * it if the given value is different from the existing one.
 */
export const getReactiveVarIdempotentUpdate =
  <T>(rv: ReactiveVar<T>) =>
  (newVal: T) => {
    if (newVal !== rv()) rv(newVal);
  };

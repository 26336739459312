const actionListAverage = (values: number[]) => {
  if (values.length === 0) {
    return 0;
  }

  const initialValue = 0;
  return values.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue) / values.length;
};

export default actionListAverage;

/**
 * These correlate to error types in the back-end project, currently defined
 * in asp-deploy-repository-backend\lambdas\common\error-messages.ts
 */

const errorTypes = {
  systemError: 'SYSTEM_ERROR',
  invalidClaimError: 'INVALID_CLAIM',
  noABNFoundForClaim: 'NO_ABN_FOR_CLAIM',
  alreadyClaimedError: 'ALREADY_CLAIMED',
  missingData: 'MISSING_DATA',
  validationError: 'VALIDATION_ERROR',
  dspError: 'DSP_ERROR',
  dataBaseError: 'DATABASE_ERROR',
  oktaError: 'OKTA_ERROR',
  unauthorized: 'UNAUTHORIZED',
  myobSsoError: 'MYOB_SSO_ERROR',
  reportLocked: 'REPORT_LOCKED',
};

export const ApiErrorMsg = {
  internalSystemError: errorTypes.systemError,
  invalidClaim: errorTypes.invalidClaimError,
  noAbnFoundForClaim: errorTypes.noABNFoundForClaim,
  alreadyClaimed: errorTypes.alreadyClaimedError,
  requestFailed401: errorTypes.dspError,
  dspApiNoAbnError: errorTypes.missingData,
  dspApiErrorType: errorTypes.validationError,
  dspApiTimeoutType: errorTypes.dspError,
  dspApiLambaTimeout: errorTypes.dspError,
};

export const dspApiErrorTypes = [
  ApiErrorMsg.dspApiErrorType,
  ApiErrorMsg.dspApiTimeoutType,
  ApiErrorMsg.dspApiLambaTimeout,
];

import { makeVar, ReactiveVar } from '@apollo/client';
import { isString } from 'lodash';

// https://github.com/apollographql/apollo-cache-persist/issues/361#issuecomment-912545495

const getCleanValueForStorage = (value: unknown) => (isString(value) ? value : JSON.stringify(value));

const makeVarPersisted = <T>(
  initialValue: T,
  storageName: string,
  loadedDataCallback?: (value: T) => T
): ReactiveVar<T> => {
  let value = initialValue;

  // Try to fetch the value from local storage
  const previousValue = sessionStorage.getItem(storageName);
  if (previousValue !== null) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value = JSON.parse(previousValue);

      if (typeof loadedDataCallback === 'function') {
        value = loadedDataCallback(value);
      }
    } catch {
      // It wasn't JSON, assume a valid value
      value = previousValue as unknown as T;
    }
  }

  // Create a reactive var with stored/initial value
  const rv = makeVar<T>(value);

  const onNextChange = (newValue: T | undefined) => {
    try {
      // Try to add the value to local storage
      if (newValue === undefined) {
        sessionStorage.removeItem(storageName);
      } else {
        sessionStorage.setItem(storageName, getCleanValueForStorage(newValue));
      }
    } catch {
      // ignore
    }

    // Re-register for the next change
    rv.onNextChange(onNextChange);
  };

  // Register for the first change
  rv.onNextChange(onNextChange);

  return rv;
};

export default makeVarPersisted;

import { Text } from '@aws-amplify/ui-react';
import './InlineLabel.scss';

interface Props {
  styles?: string;
  message: string;
  type: 'info' | 'error';
}
export const InlineLabel: React.FC<Props> = ({ styles, message, type }) => (
  <Text testId="inline-label" className={`${styles} inline-label-${type}`} aria-live="assertive">
    {message}
  </Text>
);

/* eslint-disable react/jsx-boolean-value */
import { Button } from '@aws-amplify/ui-react';
import { RefObject, useState } from 'react';
import { InlineError } from 'components/InlineError/InlineError';
import { recordRumCustomEvent } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import handleGeneratePdf from './handleGeneratePDF';
import { getRumAttributes } from './getRumAttributes';

interface PDFstate {
  submitted: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
}

export const PDFButton = ({ buttonRef, submitted = false }: PDFstate) => {
  const [loading, setLoading] = useState(false);
  const [pdfGenerationError, setError] = useState<string | null>(null);

  const handleClick = async () => {
    setLoading(true);
    setError(null);

    recordRumCustomEvent(RumCustomEvent.downloadPdfClicked, {
      pageId: window.location.pathname,
      ...getRumAttributes(),
    });
    try {
      await handleGeneratePdf(submitted);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (pdfGenerationError) {
      setError('PDF Generation Failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        isLoading={loading}
        loadingText="Loading"
        className="button-action-download button-action"
        onClick={handleClick}
        type="button"
        testId="download-pdf"
      >
        Download PDF
      </Button>
      {pdfGenerationError && (
        <InlineError
          styles="inline-error time-out-alert-inline-error"
          errorMessage="Download failed, please try again after submitting"
        />
      )}
    </div>
  );
};

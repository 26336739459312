import { SavedReportDataItems } from 'models';
import makeVarPersisted from 'apollo/MakeVarPersisted';

const initialState: SavedReportDataItems = {
  savedAnswers: [],
};

export const savedReportDataItems = makeVarPersisted<SavedReportDataItems>(
  initialState,
  'aspSavedReportDataItems',
  (value: SavedReportDataItems) => {
    const savedAnswers = value.savedAnswers.map((item) => {
      if (item.action !== 'NUMBER') return item;
      if ('savedNumber' in item.savedReportDataItem) return item;

      return {
        ...item,
        savedReportDataItem: {
          ...item.savedReportDataItem,
          savedNumber: undefined,
        },
      };
    });
    return { savedAnswers };
  }
);

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Heading, Text, Grid, View, Flex, Link } from '@aws-amplify/ui-react';
import { PointOptionsObject } from 'highcharts';
import { externalLinks } from 'lib/externalLinks';
import { GraphData, dataVisibility, graphHoverLineItem } from 'utils/IndustryInsightsUtils';
import Graph from './Graph';
import './InsightMeasureDetails.css';

interface Props {
  title: string;
  tagName: string;
  name: string;
  data: GraphData;
  categories: string[];
  color?: string;
  whatIsIt: string;
  whyItMatters: string;
  displayCount: string | number;
  industry: string;
  loading: boolean;
  error?: boolean;
  link?: string;
  onRetry: () => void;
  isPercentage?: boolean;
  label?: string;
  showBusinessData?: boolean;
}

const InsightMeasureDetails: React.FC<Props> = ({
  title,
  tagName,
  name,
  data,
  categories,
  color = '#2CAFFE',
  whatIsIt,
  whyItMatters,
  displayCount,
  industry,
  loading = true,
  error = false,
  link = externalLinks.absBusinessIndicators,
  onRetry,
  label = 'Percentage change',
  isPercentage = true,
  showBusinessData = false,
}) => {
  const industrySeriesData: number[] | object[] = data.industryData.slice(
    data.industryData.length - Number(displayCount)
  );
  const myBusinessSeriesData: number[] = data.businessData
    .slice(data.industryData.length - Number(displayCount))
    .map((item) => Number(item.value));
  const myBusinessBarColor = '#9263DE';

  const noIndustryData = `The ABS does not currently publish ${title.toLowerCase()} data for ${industry}.`;

  const industryHoverLineItem = (value: number | object) =>
    String(graphHoverLineItem(`${industry}`, color, Number(value), isPercentage));
  const businessHoverLineItem = (value: number | object) =>
    String(graphHoverLineItem('My business', myBusinessBarColor, Number(value), isPercentage));

  const options: Highcharts.Options = {
    title: {
      text: name,
    },
    series: [
      {
        data: industrySeriesData.map((value, index) => {
          const pointValue: PointOptionsObject = {
            y: Number(value),
            custom: {
              pointText: `${industryHoverLineItem(value)}
              ${
                myBusinessSeriesData[index] && Number(myBusinessSeriesData[index]) !== 0 && showBusinessData
                  ? `${businessHoverLineItem(myBusinessSeriesData[index])}`
                  : ''
              }`.trim(),
            },
          };
          return pointValue;
        }), // values
        showInLegend: false,
        type: 'column',
        color,
        name: industry,
        visible: dataVisibility(industrySeriesData),
      },
      {
        data: myBusinessSeriesData.map((value) => {
          const pointValue: PointOptionsObject = {
            y: Number(value),
            custom: {
              pointText: `${businessHoverLineItem(value)}`.trim(),
            },
          };
          return pointValue;
        }), // values
        showInLegend: false,
        type: 'column',
        color: myBusinessBarColor,
        name: 'My Business',
        linkedTo: industry,
        pointPlacement: -0.15,
        visible: dataVisibility(myBusinessSeriesData, showBusinessData),
      },
    ],
    lang: {
      noData: noIndustryData,
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
        textAlign: 'center !important',
        whiteSpace: 'normal !important',
      },
      useHTML: true,
    },
    chart: {
      type: 'column',
      borderRadius: 8,
    },
    tooltip: {
      shared: true,
      format: `
      <span style="font-size: 0.8em">{key}</span><br/>
      {point.custom.pointText}
      `,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories.slice(categories.length - Number(displayCount)), // get from api resp
      reversed: false,
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: label,
      },
      labels: {
        format: isPercentage ? '{value}%' : '{value}',
      },
    },
    accessibility: {
      description: 'This chart displays',
    },
  };

  const getTag = () => {
    const lastMyBusinessValue = isPercentage
      ? Number(myBusinessSeriesData[myBusinessSeriesData.length - 1])
      : Number(
          (
            Number(myBusinessSeriesData[myBusinessSeriesData.length - 1]) -
            Number(myBusinessSeriesData[myBusinessSeriesData.length - 2])
          ).toFixed(2)
        );

    if (dataVisibility(data.industryData)) {
      const lastIndustryValue = isPercentage
        ? Number(data.industryData[data.industryData.length - 1])
        : Number(
            (
              Number(data.industryData[data.industryData.length - 1]) -
              Number(data.industryData[data.industryData.length - 2])
            ).toFixed(2)
          );

      return (
        <Text>
          {`In the past quarter, ${title.toLocaleLowerCase()} for ${industry} ${
            lastIndustryValue < 0 ? 'fell' : 'rose'
          } by `}
          <strong>
            {lastIndustryValue.toString().replace('-', '')}
            {isPercentage ? '%' : ''}
          </strong>
          {showBusinessData &&
          myBusinessSeriesData.length !== 0 &&
          industrySeriesData.length !== 0 &&
          lastMyBusinessValue !== 0 ? (
            <span>
              , and your business {title.toLocaleLowerCase()} {lastMyBusinessValue < 0 ? 'fell' : 'rose'} by{' '}
              <strong>
                {lastMyBusinessValue.toString().replace('-', '')}
                {isPercentage ? '%' : ''}
              </strong>
            </span>
          ) : (
            `${showBusinessData ? `, ${title.toLocaleLowerCase()} data for your business is not available` : ''}`
          )}
          .
        </Text>
      );
    }

    if (dataVisibility(myBusinessSeriesData, showBusinessData)) {
      const graphText = (
        <Text>
          In the past quarter, {title.toLocaleLowerCase()} for your business {lastMyBusinessValue < 0 ? 'fell' : 'rose'}{' '}
          by{' '}
          <strong>
            {lastMyBusinessValue.toString().replace('-', '')}
            {isPercentage ? '%' : ''}
          </strong>
          . {noIndustryData}
        </Text>
      );
      return graphText;
    }
    return false;
  };

  return (
    <Grid templateColumns="2fr 4fr" gap="32px">
      <Flex direction="column">
        <Heading level={3} style={{ margin: '0' }}>
          {' '}
          {title}
        </Heading>
        {loading ? <div className="skeleton" style={{ width: '85%', height: '20px' }} /> : getTag()}
        <Text>
          <b>What it is:</b> {whatIsIt}
        </Text>
        <Text>
          <b>Why it matters:</b> {whyItMatters}
        </Text>
        <Text>
          <Link isExternal href={link}>
            {' '}
            Explore {title.toLocaleLowerCase()} in ABS Data Explorer
          </Link>
        </Text>
      </Flex>
      <View>
        <Graph options={options} onRetry={onRetry} isLoading={loading} error={error} showRetry />
      </View>
    </Grid>
  );
};

export default InsightMeasureDetails;

import dayjs from 'dayjs';
import { SHORT_MONTHNAME_YEAR } from './dayjsFormats';

export interface BusinessData {
  [key: string]: number[] | string[];
  salesToWagesRatio: number[];
  percentageBreakdownForSales: number[];
  percentageBreakdownForWages: number[];
  quarterDates: string[];
}

export interface BusinessGraphData {
  value: number | string;
  quarterDate: string;
}
export interface GraphData {
  industryData: number[] | object[];
  businessData: BusinessGraphData[];
}

export const tagNameToBusinessDataKeys: { [key: string]: string } = {
  'sales to wages ratio': 'salesToWagesRatio',
  'income from sales': 'percentageBreakdownForSales',
  wages: 'percentageBreakdownForWages',
};

/**
 * @param isPercentage
 * @param comparedValue
 * @param hidden
 * @returns The comparative text to appear on hover
 */
export const industryComparisonText = (
  isPercentage: boolean,
  comparedValue: number,
  hidden: boolean,
  industry: string
) => {
  if (hidden || comparedValue === 0) return '';
  return `Your business was ${Math.abs(Number(comparedValue.toFixed(2)))}${isPercentage ? '%' : ''} ${
    comparedValue > 0 ? 'lower' : 'higher'
  } than ${industry}`;
};

/**
 * @param title
 * @param color
 * @param value
 * @param isPercentage
 * @returns text that will appear for a line when hovering over graph data
 */
export const graphHoverLineItem = (title: string, color: string, value: number | object, isPercentage: boolean) =>
  `<span style="color:${color}">\u25CF</span> ${title}: <b>${Number(value)
    .toFixed(2)
    .replace(/[.,]00$/, '')}${isPercentage ? '%' : ''}</b><br/>`;

export const getBusinessInsightValues = (tagName: string, dates: string[], data: BusinessData): BusinessGraphData[] => {
  const businessInsightData = Object.keys(tagNameToBusinessDataKeys).includes(tagName)
    ? data[tagNameToBusinessDataKeys[tagName]]
    : [];

  // Creating businessData and ensuring it is ordered to align with the industry data
  const businessValuesPreIndustry: BusinessGraphData[] = businessInsightData.map((item, i) => ({
    value: Number(item),
    quarterDate: String(dayjs(data.quarterDates[i]).format(SHORT_MONTHNAME_YEAR) ?? ''),
  }));

  const businessData: BusinessGraphData[] =
    businessInsightData.length > 0
      ? dates
          .map((date) => {
            const value: number | string =
              businessValuesPreIndustry.find((businessValue) => businessValue.quarterDate === date)?.value ?? '';
            return { value, quarterDate: date };
          })
          .sort((a, b) => dates.indexOf(a.quarterDate) - dates.indexOf(b.quarterDate))
      : [];
  return businessData;
};

/**
 * Returns the text to be display alongside the toggle button for valueback
 * @param businessData the business information from the backend
 * @param loading the loading state of the API query for collecting
 * @param available additional conditions that would affect the availability of the toggle
 * @returns
 */
export const toggleText = (businessData: BusinessData | undefined, loading: boolean, available: boolean): string => {
  if (loading) return 'Loading';
  if (!businessData) return '';
  if (!available) return 'No data available';
  const sales = businessData.percentageBreakdownForSales.filter((item) => item !== 0).length > 0;
  const wages = businessData.percentageBreakdownForWages.filter((item) => item !== 0).length > 0;
  const availableValues: string[] = [sales ? 'sales' : '', wages ? 'wages' : ''].filter((item) => item.length > 0);
  if (availableValues.length === 0) return 'No data available';
  const returnString =
    availableValues.length > 1
      ? `${availableValues.slice(0, -1).join(', ')} and ${availableValues.pop()} data available`
      : `${availableValues[0]} data available`;
  return `${returnString[0].toLocaleUpperCase()}${returnString.slice(1)}`;
};

/**
 * Check whether the valueBack data exists or contains of relevant data
 */
export const validateValueBack = (valueBackData: BusinessData | undefined): boolean => {
  if (!valueBackData) return false;
  const salesToWages = valueBackData.salesToWagesRatio.filter((item) => item !== 0).length;
  const sales = valueBackData.percentageBreakdownForSales.filter((item) => item !== 0).length;
  const wages = valueBackData.percentageBreakdownForWages.filter((item) => item !== 0).length;
  const sum = salesToWages + sales + wages;
  return sum > 0;
};

/**
 * A function to set the visibility of data in the graph
 * @param data the data set
 * @param visible the switch that determines visibility
 * @returns true when visible is true and data is non-empty, otherwise false
 */
export const dataVisibility = (data: unknown[], visible = true): boolean => visible && data.length !== 0;

import { gql } from '@apollo/client';

export const REVOKE_BUSINESS = gql`
  mutation revokeBusiness($dspProvider: String!, $organisationId: String!) {
    revokeBusiness(dspProvider: $dspProvider, organisationId: $organisationId) {
      success
      errorMessages {
        message
        errorCode
        language
        metaData
        errorType
        stack
        time
      }
    }
  }
`;

import { DataItem, Topic, TopicDetail, TopicKey, TopicFullDetail } from 'models';
import { TopicName, TopicTotalDisplay } from 'models/LocalState/TopicFullDetail';
import { compileRule } from './parseTopicRule';

const extractDataItemTopics = (dataItems: DataItem[]) => new Set(dataItems.map((dataItem) => dataItem.topic));

/**
 * Convert topics from the api representation to the form used locally.
 *
 * Only topics linked to data items are included. Linked to data items means matching the topic of
 * 1 or more data items, or having a topic in the rule that matches the topic of 1 or more data items.
 *
 * @param topics from API (getReport.report.topicAndDataItems.topics)
 * @param dataItems from API (getReport.report.topicAndDataItems.dataItems),
 *                  used to filter out topics with no data items.
 * @param isUsingDsp from ReportSummary.reportData.isUsingDsp
 * @returns local topics that should be included
 */
export const mapGraphQLTopicsToLocal = (
  topics: Topic[],
  dataItems: DataItem[],
  isUsingDsp: boolean | undefined
): TopicFullDetail[] => {
  const allTopicDetails = extractTopicDetails(topics).map(withRealSpacesInTopic);
  const topicNamesFromDataItems = extractDataItemTopics(dataItems);

  const topicsThatHaveDataItems = allTopicDetails.filter(
    (topicDetail) => topicNamesFromDataItems.has(topicDetail.topic)
    // Temporarily disabled checking topics used in rules so that we can have the Profit or Loss section
    // hiding by removing its data item. This should be re-enabled when we have a mechanism to hide rule
    // topics implemented.
    //  ||
    //   (topicDetail.rule &&
    //     compileRule(topicDetail.rule).topicNames.some((topicName) => topicNamesFromDataItems.has(topicName)))
  );

  return topicsThatHaveDataItems.map((topic) => withComputedTopicFields(topic, isUsingDsp));
};

function extractTopicDetails(topics: Topic[]) {
  return topics?.[0]?.topicItems?.map((topic) => topic.topicDetails[0]) ?? [];
}

function withRealSpacesInTopic(topicDetail: TopicDetail): TopicDetail {
  return { ...topicDetail, topic: topicDetail.topic.replaceAll('%20', ' ') };
}

function withComputedTopicFields(topicDetail: TopicDetail, isUsingDsp: boolean | undefined): TopicFullDetail {
  const topic = topicDetail.topic as TopicName;
  const topicTitle = topicDetail.topicTitle ?? topicDetail.topic;
  const topicShortTitle = topicDetail.topicShortTitle ?? topicTitle;
  const topicTotalText = topicDetail.topicTotalText ?? `Total ${topicShortTitle}`;
  const topicTotalInfo = isUsingDsp
    ? topicDetail.topicTotalInfo
    : topicDetail.topicTotalInfoNoDsp ?? topicDetail.topicTotalInfo;
  const totalDisplay = asValidTotalDisplayValue(topicDetail.totalDisplay) ?? 'SHOW';
  const ruleDescription = isUsingDsp
    ? topicDetail.ruleDescription
    : topicDetail.ruleDescriptionNoDsp ?? topicDetail.ruleDescription;
  const key = topicShortTitle.replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase() as TopicKey;
  const path = `/report/${key}`;
  const isFeedback = key === 'aspfeedback';
  const compiledRule = topicDetail.rule ? compileRule(topicDetail.rule) : undefined;

  const item: TopicFullDetail = {
    ...topicDetail,
    topic,
    key,
    topicTitle,
    topicShortTitle,
    topicTotalText,
    topicTotalInfo,
    totalDisplay,
    ruleDescription,
    path,
    isFeedback,
    compiledRule,
  };

  return item;
}

function asValidTotalDisplayValue(input?: string | null): TopicTotalDisplay {
  if (!input || !['SHOW', 'HIDE'].includes(input)) return 'SHOW';

  // Able to assert that this is a TopicTotalDisplay because of the includes in
  // the type guard. TypeScript does not narrow types from .includes
  return input as TopicTotalDisplay;
}

/* Standard formats for the dayjs format function, for readability
 * without reference to docs.
 *
 * Relevant docs are at https://day.js.org/docs/en/display/format
 *
 * Conventions:
 *
 *  - DAYNUM: day number (*without* padding leading zeros)
 *  - DAYNUMPADDED: day number padded to 2 digits with leading zeros
 *  - MONTHNAME: Whole month name without truncation
 *  - YEAR: 4 digit year
 */

export const DAYNUM_MONTHNAME_YEAR = 'D MMMM YYYY' as const;

export const SHORT_MONTHNAME = 'MMM' as const;

export const DAYNUM_SHORT_MONTHNAME = 'D MMM' as const;

export const DAYNUM_SHORT_MONTHNAME_YEAR = 'D MMM YYYY' as const;

export const MONTHNAME = 'MMMM' as const;

export const DAYNUM_MONTHNAME = 'D MMMM' as const;

export const YEAR = 'YYYY' as const;

export const YEAR_TWO_DIGIT = 'YY' as const;

export const MONTHNAME_YEAR = 'MMMM YYYY' as const;

export const SHORT_MONTHNAME_YEAR = 'MMM YYYY' as const;

export const YEAR_MONTH_DAYNUM = 'YYYY-MM-DD';

export const DAYNUM_MONTH_YEAR_BY_SLASH = 'DD/MM/YYYY';

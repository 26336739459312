import { Flex, Grid, Image, Text, View } from '@aws-amplify/ui-react';
import { AuthorisedBusiness } from 'models';

import './BusinessSelectMenuItem.css';
import { getDsp } from 'utils/dspIndex';

interface BusinessSelectMenuItemProps {
  business?: AuthorisedBusiness;
}
const BusinessSelectMenuItem = ({ business }: BusinessSelectMenuItemProps) => {
  const dspInfo = getDsp(business?.dsp);

  return (
    <Flex className="business-select-menu-item-wrapper" testId="business-select-menu-item-wrapper" gap="8px">
      <Grid width="44px" height="20px" alignContent="center">
        <Image alt={dspInfo.iconAltText} src={dspInfo.iconSrc} testId="business-select-menu-logo" />
      </Grid>
      <View className="business-select-menu-item-divider" />
      <Text testId="business-select-menu-item-text" paddingLeft="4px">
        {business?.name}, {business?.suburb}
      </Text>
    </Flex>
  );
};

export default BusinessSelectMenuItem;

import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { chosenReport } from 'apollo/states/ChosenReport';

type CustomRumAttributes = {
  reportId: string | null;
  providerId: string | null;
};

export const getRumAttributes = (overrides?: Partial<CustomRumAttributes>): CustomRumAttributes => {
  const reportId = chosenReport()?.userReportId ?? null;
  const providerId = authorisedBusinesses()?.activeBusiness?.providerId ?? null;
  return {
    reportId,
    providerId,
    ...overrides,
  };
};

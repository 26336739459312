import { useReactiveVar } from '@apollo/client';
import { TopicFullDetail } from 'models';
import { currentReportData } from '../CurrentReportData';

const useCurrentReportTopics = (): TopicFullDetail[] => {
  const reportData = useReactiveVar(currentReportData);
  return reportData?.topics ?? [];
};

export default useCurrentReportTopics;

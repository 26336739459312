import { Alert, AlertProps, AlertVariations, Text } from '@aws-amplify/ui-react';
import './AlertComponent.scss';
import { ReactNode } from 'react';

interface Props {
  variation: 'incompleted' | 'timeOut' | 'error' | 'warning';
  title: string;
  textHeader?: string;
  textHeaderComponent?: ReactNode;
  listItem?: string[];
  alertProps?: AlertProps;
}

let variationCheck: AlertVariations;
let selectClass: string;

export const AlertComponent: React.FC<Props> = ({
  variation,
  title,
  textHeader,
  textHeaderComponent,
  listItem,
  alertProps,
}) => {
  selectClass = 'alert-component-default';
  if (variation === 'incompleted') {
    variationCheck = 'info';
  }
  if (variation === 'timeOut') {
    variationCheck = 'info';
    selectClass = 'alert-component-time-out';
  }
  if (variation === 'error') {
    variationCheck = 'error';
    selectClass = 'alert-component-error';
  }
  if (variation === 'warning') {
    variationCheck = 'warning';
    selectClass = 'alert-component-warning';
  }

  return (
    <Alert
      {...alertProps}
      variation={variationCheck}
      testId="alert-component"
      heading={title}
      className={`${selectClass} alert-component`}
      dismissButtonLabel="close notification"
    >
      <div className="alert-content" data-testid="alert-content">
        {(textHeader || textHeaderComponent) && (
          <Text className="alert-text-header" testId="alert-text-header">
            {textHeader}
            {textHeaderComponent}
          </Text>
        )}
        {listItem && (
          <ul className="alert-texts" data-testid="alert-texts">
            {listItem.map((text: string, i: number) => (
              <li key={i}>{text}</li>
            ))}
          </ul>
        )}
      </div>
    </Alert>
  );
};

import { Flex, Heading } from '@aws-amplify/ui-react';
import { useEffect, useMemo, useRef } from 'react';

import ReportButtonAutoGroup from 'components/ReportComponents/ReportButtonAutoGroup/ReportButtonAutoGroup';
import TopicQuestions from 'components/ReportComponents/TopicQuestions/TopicQuestions';

import 'pages/Report/ReportPage.css';
import { TopicFullDetail } from 'models/LocalState/TopicFullDetail';

// Common display logic for all the "standard" topics that are just a heading and the topic questions
export const ReportTopic = ({ topicFullDetail }: { topicFullDetail: TopicFullDetail }) => {
  const focusRef = useRef<HTMLHeadingElement>(null);

  const title = topicFullDetail.topicTitle;

  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus();
    }
  }, [title]);

  const testId = useMemo(() => `${title.toLowerCase().replace(/\W/g, '')}-header`, [title]);

  return (
    <>
      <Heading
        level={1}
        ref={focusRef}
        tabIndex={-1}
        className="report-step-header report-step-header-buffer"
        testId={testId}
      >
        {title}
      </Heading>
      <TopicQuestions topicType={topicFullDetail.topic} />
      <Flex direction="column">
        <ReportButtonAutoGroup currentStep={topicFullDetail.key} />
      </Flex>
    </>
  );
};

import { externalLinks } from 'lib/externalLinks';

import infoIcon from 'assets/icon-info-primary.svg';
import './UnsupportedBrowser.css';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';

export const UnsupportedBrowser = () => (
  <div className="unsupported-browser-container">
    <div className="info-message-card">
      <img src={infoIcon} alt="" className="info-icon" />
      <h1 className="info-heading">Unsupported browser</h1>
      <p>ABS Business Reporting work with the latest version of:</p>
      <ul className="browser-list">
        <li>Microsoft Edge</li>
        <li>Google Chrome</li>
        <li>Mozilla Firefox</li>
        <li>Safari</li>
      </ul>
      <p>
        Using an unsupported browser may cause technical issues or prevent you from using ABS Business Reporting. Please
        update your browser or use another browser.
      </p>
      <p>
        <a
          href={externalLinks.absContactUs}
          target="_blank"
          rel="noreferrer"
          onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
        >
          Contact us
        </a>{' '}
        for more information.
      </p>
    </div>
  </div>
);

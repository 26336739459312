import { makeVar } from '@apollo/client';
import dayjs from 'dayjs';

interface SaveTimer {
  lastSaved?: number;
  timer: number;
}

export const saveTimer = makeVar<SaveTimer>({ timer: 0 });

export const resetLastSavedTimer = (timer = 0) => {
  saveTimer({ lastSaved: dayjs().valueOf(), timer });
};

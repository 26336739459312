import { Flex, Image, Text, View } from '@aws-amplify/ui-react';
import OverdueIcon from 'assets/icon-report-overdue.svg';
import InProgressIcon from 'assets/icon-report-in-progress.svg';
import NotSubmittedIcon from 'assets/icon-report-not-submitted.svg';
import SubmittedIcon from 'assets/icon-report-submitted.svg';
import NotStartedIcon from 'assets/icon-report-not-started.svg';
import { ReportStatus } from 'enums/ReportStatus';

import './ReportProgressCapsule.css';
import dayjs from 'dayjs';

const getProgressContent = (
  status: ReportStatus,
  overdue: boolean
): { text: string; icon: string | undefined; styling: string | undefined } => {
  switch (status) {
    case ReportStatus.New:
      if (overdue) return { text: 'Overdue', icon: OverdueIcon, styling: 'report-overdue' };
      return { text: 'Not started', icon: NotStartedIcon, styling: 'report-not-started' };
    case ReportStatus.UnderReview:
      return { text: 'In progress', icon: InProgressIcon, styling: 'report-in-progress' };
    case ReportStatus.Edit:
      if (overdue) return { text: 'Overdue', icon: OverdueIcon, styling: 'report-overdue' };
      return { text: 'In progress', icon: InProgressIcon, styling: 'report-in-progress' };
    case ReportStatus.NotSubmitted:
      return { text: 'Not submitted', icon: NotSubmittedIcon, styling: 'report-not-submitted' };
    case ReportStatus.Submitted:
    case ReportStatus.SubmittedFeedback:
      return { text: 'Submitted', icon: SubmittedIcon, styling: 'report-submitted' };
    default:
      return { text: 'Loading...', icon: undefined, styling: undefined };
  }
};

interface ReportProgressCapsuleProps {
  status: ReportStatus;
  dueDate: number;
}
const ReportProgressCapsule = ({ status, dueDate }: ReportProgressCapsuleProps) => {
  const overdue = dayjs().startOf('day').isAfter(dayjs(dueDate).startOf('day'));
  const { text, icon, styling } = getProgressContent(status, overdue);

  return (
    <View className={`report-progress-capsule-wrapper ${styling}`}>
      <Flex alignItems="center" justifyContent="center" direction="row" gap={8} padding={8}>
        <Image alt="" src={icon} testId="report-progress-capsule-icon" width="16px" height="16px" />
        <Text testId="report-progress-capsule-text">{text}</Text>
      </Flex>
    </View>
  );
};
export default ReportProgressCapsule;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import './Breadcrumb.css';
import { Button } from '@aws-amplify/ui-react';
import { MouseEventHandler } from 'react';

interface Props {
  text: string;
  onClick?: MouseEventHandler;
}

/**
 * ## The Breadcrumb Component
 *
 * Renders a breadcrumb that performs an action **on click**.
 *
 * ### To review
 *
 * - Check vertical alignment in app, appears broken in storybook
 * - Check keyboard accessibility
 */
export const Breadcrumb: React.FC<Props> = ({ text, onClick }) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <Button testId="breadcrumb-btn" className="breadcrumb" onClick={onClick}>
    <div data-testid="breadcrumb-text">{text}</div>
  </Button>
);

import { components, ControlProps, DropdownIndicatorProps, StylesConfig } from 'react-select';
import { Image } from '@aws-amplify/ui-react';

import SearchIcon from 'assets/icon-search.svg';
import { ReactComponent as ChevronDownSvg } from 'assets/icon-chevron-down.svg';
import { ReactComponent as ChevronUpSvg } from 'assets/icon-chevron-up.svg';

// Intentionally any option type to make it compatible to spread in other styles config objects with concrete types
export const businessSelectStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    padding: '8px 0',
    borderRadius: '8px',
    border: 'solid 1px #5C6670',
    boxShadow: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: 10,
    borderRadius: '8px',
    border: '1px solid #878889',
    boxShadow: '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    borderRadius: '8px',
  }),
  option: (styles) => ({ ...styles, padding: '11px 16px' }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
};

export const CustomDropdownIndicator = <T,>(props: DropdownIndicatorProps<T>) => (
  <components.DropdownIndicator {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.selectProps.menuIsOpen ? <ChevronUpSvg width={24} height={24} /> : <ChevronDownSvg width={24} height={24} />}
  </components.DropdownIndicator>
);

export const Control = <T,>({ children, ...props }: ControlProps<T>) => (
  <components.Control {...props}>
    <Image alt="" src={SearchIcon} paddingLeft="16px" height="16px" width="32px" />
    {children}
  </components.Control>
);

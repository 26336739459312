export const withTimeout = <T>(promise: Promise<T>, timeoutDuration = 10000) => {
  const timeoutPromise = new Promise((_, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Promise timed out'));
    }, timeoutDuration);

    // Clear the timeout if the promise resolves or rejects before the timeout
    promise.then(() => clearTimeout(timeoutId)).catch(() => clearTimeout(timeoutId));
  });

  return Promise.race([promise, timeoutPromise]) as Promise<T>;
};

import { cache, visitedReportPages } from 'apollo/Cache';
import { chosenReport } from 'apollo/states/ChosenReport';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import { pick } from 'lodash';
import { ChosenReport } from 'models';
import { savingLocalAnswersStatus } from '../operationsInProgress';

export const ClearReportStateData = () => {
  currentReportData(undefined);
  const clearingReport = chosenReport();
  chosenReport(undefined);
  latestSavedReportDataItems(undefined);
  savedReportDataItems(undefined);
  userContactDetails(undefined);
  evictGetReportFromCache(clearingReport);
  visitedReportPages([]);
  savingLocalAnswersStatus('idle');
};

export const evictGetReportFromCache = (reportData: ChosenReport) => {
  const getReportArgs = pick(reportData, 'dspProvider', 'organisationId', 'userReportId');

  // clear cache entry for report that is closing, to ensure stale data is not shown
  cache.evict({
    // clearing out a query response
    id: 'ROOT_QUERY',
    // correllates to query name within graphql (the nested one)
    fieldName: 'getReport',
    // needs to match what was passed to the nested query initially
    args: getReportArgs,
    broadcast: false,
  });
};

export const evictAllGetReportFromCache = () => {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'getReport',
    broadcast: false,
  });
};

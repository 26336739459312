/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Flex, Heading, Text, View, VisuallyHidden } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useNavigate } from 'react-router-dom';
import 'utils/animation.css';

import { chosenReport } from 'apollo/states/ChosenReport';
import ReportProgressCapsule from 'components/ReportProgressCapsule/ReportProgressCapsule';
import ReportProgressText from 'components/ReportProgressText/ReportProgressText';
import { ReportStatus } from 'enums/ReportStatus';
import { ReportPeriodFormat, ReportQuarterFormat } from 'utils/ReportPeriodFormat';
import { ChosenReport, Contact, GetReportResponse, UserContactDetails } from 'models';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { mapGraphQLToLocal } from 'utils/mapGraphQLToLocal';
import { mapGraphQLTopicsToLocal } from 'utils/mapGraphQLTopicsToLocal';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { useUserInfo } from 'lib/userInfoHook';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import { saveTimer } from 'apollo/states/SaveTimer';
import { cleanseDspDataItems } from 'utils/cleanseDspDataItems';
import useSetContactDetails from 'utils/useSetContactDetails';
import handleGeneratePdf from 'utils/handleGeneratePDF';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import './ReportSummary.css';
import { LockState } from 'models/GraphQL/LockState';
import { DAYNUM_MONTHNAME_YEAR } from 'utils/dayjsFormats';
import { buildSubstitutePlaceholders } from 'utils/substitutePlaceholders';
import { useSetReportLock } from 'utils/useSetReportLock';
import {
  dashboardReportTabState,
  syncingDspBusinessNamesState,
  savingLocalAnswersStatus,
} from 'apollo/states/operationsInProgress';
import { OperationVariables, QueryResult, useReactiveVar } from '@apollo/client';
import useGetReport from 'utils/useGetReport';
import { isCurrentStatus, isPreviousStatus } from 'components/Dashboard/Dashboard';
import { withTimeout } from 'utils/withTimeout';
import { InlineError } from 'components/InlineError/InlineError';
import { GetReportTimerLabel } from './GetReportTimerLabel';

dayjs.extend(advancedFormat);
interface ReportSummaryProps {
  name: string;
  dueDate: number;
  lastEditedDate: number;
  status: ReportStatus;
  cycleStartDate: number;
  cycleEndDate: number;
  reportData: ChosenReport;
  preCache?: boolean;
  loadingReportId: string;
  setLoadingReportId: (userReportId: string) => void;
  lockState?: LockState;
}

const ReportSummary = (props: ReportSummaryProps) => {
  const dashboardReportTab = useReactiveVar(dashboardReportTabState);
  const isSyncingDspBusinessNames = useReactiveVar(syncingDspBusinessNamesState);
  const {
    name,
    status,
    dueDate,
    lastEditedDate,
    cycleStartDate,
    cycleEndDate,
    reportData,
    lockState,
    loadingReportId,
    setLoadingReportId,
    preCache,
  } = props;

  const navigate = useNavigate();
  const userInfo = useUserInfo();

  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();

  const dueDateObj = dayjs(dueDate);

  const shouldGeneratePdf = status === ReportStatus.Submitted || status === ReportStatus.SubmittedFeedback;

  const [callGetReport, getReportQuery, setGetReportCaching] = useGetReport(reportData, preCache, !shouldGeneratePdf);
  // Resolved value from callGetReport promise, to process as soon as !isSyncingDspBusinessNames
  const [reportPromiseResponse, setReportPromiseResponse] =
    useState<QueryResult<GetReportResponse, OperationVariables>>();

  const userReportId = reportData.userReportId ?? '';

  // Stored in a ref so that the latest value is available in the promise callbacks
  const loadingThisReport = useRef(loadingReportId.length && loadingReportId === userReportId);

  // Effects for when a report starts launching (may be this report)
  useEffect(() => {
    const thisReportWasLoading = loadingThisReport.current;
    const thisReportIsLoading = loadingReportId.length && loadingReportId === userReportId;
    // Ignore if not changed
    if (thisReportIsLoading === thisReportWasLoading) return;

    // and update the ref for the handleLaunchOrReviewOrPdf callback
    loadingThisReport.current = thisReportIsLoading;

    if (!thisReportIsLoading) {
      // was loading and now is not

      // switch the getReport back to 'pre-cache' mode
      setGetReportCaching();
      // and clear any cached response from getReport so it will not be launched
      setReportPromiseResponse(undefined);
    }
  }, [loadingReportId, userReportId, setGetReportCaching]);

  // tracks thrown getReport errors
  const [inlineError, setInlineError] = useState(false);

  const [timedOut, setTimedOut] = useState(false);

  const [callSetContactDetail] = useSetContactDetails();

  const reportIsLocked = lockState?.lock && !lockState.selfLock;

  const lockReport = useSetReportLock('lock', { ...reportData });

  // If we are still syncing DSP business names when the getReport finishes, we cache the response
  // and show loading until the business names check completes
  const isLoading = getReportQuery.loading || Boolean(isSyncingDspBusinessNames && reportPromiseResponse);

  const processPromise = (response: QueryResult<GetReportResponse, OperationVariables>, retry = false) => {
    // This check happens before saving the response in reportPromiseResponse, but is simpler to include
    // here to avoid type errors below
    const errorMessages = response.data?.getReport.errorMessages ?? [];
    if (!response.data || response.data?.getReport.success === false || errorMessages?.length > 0) {
      if (!showToastOpenIfDSPError(errorMessages)) {
        setInlineError(true);
      }
      return;
    }

    const fetchedReport = response.data.getReport.report;
    const {
      reportStatus,
      dspDataItems: rawDspDataItems,
      topicAndDataItems,
      reportDataItems,
      lastMapState,
    } = fetchedReport;
    const dspDataItems = rawDspDataItems ? cleanseDspDataItems(rawDspDataItems) : [];
    const { dataItems: rawDataItems, topics } = topicAndDataItems[0];

    // Perform substitutions in question text
    const substitutePlaceholders = buildSubstitutePlaceholders(reportData);
    const dataItems = rawDataItems.map((dataItem) => {
      const dataItemText = substitutePlaceholders(dataItem.dataItemText);
      if (dataItemText === dataItem.dataItemText) return dataItem;

      return { ...dataItem, dataItemText };
    });

    const returningUser = reportStatus === ReportStatus.New && lastMapState?.length > 0;
    // TODO: replace with this version after verifying that PDF does not need chosenReport reactive var to generate
    // const dashboardChosenReport = shouldGeneratePdf ? reportData : chosenReport({ ...reportData, returningUser });
    const dashboardChosenReport = chosenReport({ ...reportData, returningUser });

    // On first load of report for returning users, use answers from their previously submitted report (lastMapState)
    // as the basis for this report's answers (savedReportDataItems.).
    const savedDataItems =
      shouldGeneratePdf || !returningUser
        ? reportDataItems
        : fetchedReport.lastMapState
            .map((item) => ({
              dataItemId: item.dataItemId,
              items: item.accountUid.map((uid) => ({ responseUid: uid })),
            }))
            .filter((item) => dataItems.find((dataItem) => dataItem.dataItemId === item.dataItemId));
    // TODO: only set for non-PDF workflow, after verifying that PDF does not need savedReportDataItems reactive var to generate
    const dashboardPdfAnswers = savedReportDataItems(
      mapGraphQLToLocal(savedDataItems, dataItems, dspDataItems, !shouldGeneratePdf)
    );

    // Handle contact details setup for new users
    // Check contact details in response. If null:  update with okta, otherwise: set to local
    let baseContactDetails: Partial<Contact> = fetchedReport.contacts[0];

    if (!baseContactDetails) {
      // Pega only accepts 32 chars for name fields
      const firstName = userInfo?.firstName?.substring(0, 32);
      const lastName = userInfo?.lastName?.substring(0, 32);
      baseContactDetails = { firstName, lastName, email: userInfo?.email, phone: userInfo?.mobile, role: '' };
      if (!shouldGeneratePdf) {
        callSetContactDetail({
          variables: {
            saveContactDataObject: {
              userReportId: reportData.userReportId,
              dspProvider: reportData.dspProvider,
              organisationId: reportData.organisationId,
              contactDataObject: baseContactDetails,
            },
          },
        });
      }
    }
    const dashboardContacts = toUserContactDetails(baseContactDetails, '');

    if (!shouldGeneratePdf) {
      userContactDetails(dashboardContacts);
    }

    // Name needs improving, but consistent with other 'dashboard' variable names
    const dashboardTopics = mapGraphQLTopicsToLocal(topics, dataItems, reportData.isUsingDsp);

    if (shouldGeneratePdf && !retry) {
      recordRumCustomEvent(RumCustomEvent.downloadPdfClicked, {
        pageId: window.location.pathname,
        ...getRumAttributes(),
      });
      // This is almost identical to else block when contact details is defined. Combine?
      handleGeneratePdf(true, {
        dashboardContacts,
        topics: dashboardTopics,
        dashboardPdfAnswers,
        dashboardPdfQuestions: dataItems,
        dashboardChosenReport: {
          ...dashboardChosenReport,
        },
      });
    } else if (!retry) {
      // Non-pdf workflow

      // Not used in PDF workflow:
      // Populate the record of the answers saved on the server
      latestSavedReportDataItems(mapGraphQLToLocal(reportDataItems, dataItems, dspDataItems));
      currentReportData({ dataItems, dspDataItems, topics: dashboardTopics });
      saveTimer({ timer: 0 });
      recordRumCustomEvent(RumCustomEvent.reportOpen, getRumAttributes());
      lockReport();
      if (returningUser || fetchedReport.reportStatus === ReportStatus.Edit) {
        navigate('/report/review');
      } else {
        navigate('/report/instructions');
      }
    }

    // Finished with reportPromiseResponse, clear it to prevent possible bugs
    setReportPromiseResponse(undefined);
  };

  // Effect to process the report response and launch the report or generate PDF,
  // but not until isSyncingDspBusinessNames completes.
  // (this counts on the response being cleared when another report is launching).
  useEffect(() => {
    if (isSyncingDspBusinessNames || !reportPromiseResponse) return;

    processPromise(reportPromiseResponse);

    // Only want this to trigger when the business names check completes or a
    // response comes back. All the other dependencies do not change after initial
    // load so are not included in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncingDspBusinessNames, reportPromiseResponse]);

  const handleLaunchOrReviewOrPdf = () => {
    setLoadingReportId(userReportId);
    savingLocalAnswersStatus('idle');
    setInlineError(false);
    setTimedOut(false);
    clearDspApiToast();
    withTimeout(callGetReport(), 60000)
      .then((response) => {
        if (!loadingThisReport.current) {
          // This should not occur since the promise from callGetReport should
          // not resolve, but is a cheap extra safety net.
          console.log('Skipped report launch because another report is loading');
          return;
        }

        // Error response can be processed immediately, regardless of isSyncingDspBusinessNames
        const errorMessages = response.data?.getReport.errorMessages ?? [];

        if (!response.data || response.data?.getReport.success === false || errorMessages?.length > 0) {
          if (!showToastOpenIfDSPError(errorMessages)) {
            setInlineError(true);
          }
          return;
        }

        // Checking the button state at time of click and adjusting promise processing
        if (buttonText === 'Try again') {
          processPromise(response, true);
        } else setReportPromiseResponse(response);
      })
      .catch((error) => {
        if (!loadingThisReport.current) {
          // The user already switched to loading a different report, so skip showing
          // any error state.
          console.log('Skipped error states from failed getReport because another report is loading');
          return;
        }

        setInlineError(true);
        if (error?.message === 'Promise timed out') {
          setTimedOut(true);

          // On timeout, we could still get a success response back from the getReport call.
          // Sets the getReport back to caching mode so it will save the response and use it
          // when clicking 'Try again'.
          // Also switches to the non-loading default response object, so the error will display.
          setGetReportCaching();
        }
      });
  };

  const getReportError = !!(
    !getReportQuery ||
    getReportQuery.error ||
    getReportQuery.data?.getReport?.success === false ||
    // timeout is treated as a getReportError because we present it to the user that way
    timedOut
  );
  const retryable = !isLoading && (getReportError || inlineError);

  const loadingText = shouldGeneratePdf ? 'Preparing PDF' : 'Loading report';
  const buttonText = (() => {
    if (retryable) return 'Try again';
    if (reportIsLocked) return 'Report in use';
    if (isLoading) return loadingText;
    return shouldGeneratePdf ? 'Download PDF' : 'Launch report';
  })();

  // if the report's tab is not selected, return null
  // FIXME: stop passing all the reports to both tabs, then remove this
  if (
    (isCurrentStatus(status) && dashboardReportTab === 'past') ||
    (isPreviousStatus(status) && dashboardReportTab === 'current')
  ) {
    return null;
  }

  const launchDisabled = reportIsLocked || status === ReportStatus.UnderReview || isLoading;

  return (
    <Card variation="elevated" className="report-card" testId="report-card-container">
      <Flex className="report-top-info" direction="row" wrap="wrap">
        <Flex direction="column" wrap="wrap">
          <Heading level={3} className="report-heading" testId="report-heading">
            {name}
          </Heading>
          <Text className="report-text" testId="report-period">
            {ReportPeriodFormat(cycleStartDate, cycleEndDate)}
            <Text as="span" className="report-date-quarter">{` (${ReportQuarterFormat(
              cycleStartDate,
              cycleEndDate
            )})`}</Text>
          </Text>
        </Flex>
        <Flex direction="column" marginLeft="auto" textAlign="right" wrap="wrap" paddingRight={14}>
          <Heading level={4} className="report-date-heading" testId="report-due-heading">
            Report due
          </Heading>
          <Text className="report-date" testId="report-date">
            {dueDateObj.format(DAYNUM_MONTHNAME_YEAR)}
          </Text>
        </Flex>
      </Flex>
      <Flex className="report-bottom-info" alignItems="start" justifyContent="space-between" wrap="wrap">
        <div className="progress-container">
          <ReportProgressCapsule status={status} dueDate={dueDate} />
          <View className="progress-text-wrapper">
            <View marginLeft="auto" textAlign="left" testId="report-status-details">
              <ReportProgressText status={status} dueDate={dueDate} dateSubmitted={lastEditedDate} />
            </View>
          </View>
        </div>
        {[
          ReportStatus.New,
          ReportStatus.Edit,
          ReportStatus.UnderReview,
          ReportStatus.Submitted,
          ReportStatus.SubmittedFeedback,
        ].includes(status) && (
          <Flex direction="column" justifyContent="end">
            <Button
              className="report-action-btn"
              testId="report-action-btn"
              variation="primary"
              disabled={launchDisabled}
              aria-disabled={launchDisabled}
              onClick={() => {
                if (retryable) {
                  recordRumCustomEvent(
                    RumCustomEvent.reportGetRetry,
                    getRumAttributes({ providerId: reportData.dspProvider, reportId: reportData.userReportId })
                  );
                }
                handleLaunchOrReviewOrPdf();
              }}
              // Using the below instead of 'isLoading={getReportQuery.loading}' because
              //   Amplify Button doesnt apply disabled class if loading prop is present
              {...(isLoading && { isLoading: true })}
              loadingText={loadingText}
            >
              {buttonText}
              <VisuallyHidden> for {name}</VisuallyHidden>
            </Button>
            {isLoading && <GetReportTimerLabel />}
            {getReportError && (!isLoading || timedOut) && (
              <div className="long-text fade-in">
                <InlineError errorMessage="We couldn't load this report. Please try again later." />
              </div>
            )}
          </Flex>
        )}
      </Flex>
      {toastToShow}
    </Card>
  );
};

function toUserContactDetails(
  contact: Partial<Contact> | undefined,
  defaultEmail = 'user@email.com'
): UserContactDetails {
  return {
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    email: contact?.email ?? defaultEmail,
    mobile: contact?.phone ?? '',
    role: contact?.role ?? '',
  };
}

export default ReportSummary;

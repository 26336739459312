/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Flex, Grid, Heading, TabItem, Tabs, Text, View, VisuallyHidden } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import BusinessSelect from 'components/BusinessSelect/BusinessSelect';
import DashboardInfo from 'components/DashboardInfo/DashboardInfo';
import ReportSummary from 'components/ReportSummary/ReportSummary';
import { ReportStatus } from 'enums/ReportStatus';
import { AccessDetail, ChosenReport, ReportOverview } from 'models';
import { getDsp } from 'utils/dspIndex';
import {
  ReportEndFormat,
  ReportExtendedQuarterFormat,
  ReportPeriodFormat,
  ReportTitleQuarterFormat,
  ReportQuarterFormat,
} from 'utils/ReportPeriodFormat';
import { formatAbn } from 'utils/formatAbn';
import { useUserInfo } from 'lib/userInfoHook';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';

import './Dashboard.css';
import { dashboardReportTabState } from 'apollo/states/operationsInProgress';
import InsightsWidget from 'components/InsightsWidget/InsightsWidget';
import { useEffect, useState } from 'react';

const reportDataForFactory =
  (selectedOrganisation: AccessDetail | undefined) =>
  (report: ReportOverview): ChosenReport => ({
    dspProvider: selectedOrganisation?.dsp,
    organisationId: selectedOrganisation?.organisationDetails?.organisationId,
    singleUserAccess: report.singleUserAccess,
    userReportId: report.userReportId,
    dspAbn: selectedOrganisation?.organisationDetails?.providerDetails.currentPEGAABN,
    reportName: report.name,
    reportPeriod: ReportPeriodFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportEnd: ReportEndFormat(report.spRefPeriodEndDate),
    reportStart: ReportEndFormat(report.spRefPeriodStartDate),
    reportDateRangeText: ReportQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportDateRangeTitleText: ReportTitleQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportExtendedDateRangeText: ReportExtendedQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    organisationName: selectedOrganisation?.organisationDetails?.providerDetails.name,
    reportDueDate: report?.dueDate,
    reportLastEditedDate: report.lastEditedDate,
    dspDisplayName: getDsp(selectedOrganisation?.dsp).displayName,
    isUsingDsp: getDsp(selectedOrganisation?.dsp).isUsingDspProvider,
  });

const ReportSummaries = ({
  reports,
  selectedOrganisation,
  preCacheReports,
  loadingReportId,
  setLoadingReportId,
}: {
  selectedOrganisation: AccessDetail | undefined;
  reports: ReportOverview[] | undefined;
  preCacheReports?: string[];
  loadingReportId: string;
  setLoadingReportId: (id: string) => void;
}) => {
  const reportDataFor = reportDataForFactory(selectedOrganisation);

  return (
    <Flex gap={31} direction="column" className="tab-current-reports-container fade-in">
      {reports?.map((report) => (
        <ReportSummary
          name={report.name}
          lastEditedDate={report.lastEditedDate}
          cycleStartDate={report.spRefPeriodStartDate}
          cycleEndDate={report.spRefPeriodEndDate}
          dueDate={report.dueDate}
          status={report.status as ReportStatus}
          reportData={reportDataFor(report)}
          key={report.userReportId}
          preCache={preCacheReports?.includes(report.userReportId)}
          loadingReportId={loadingReportId}
          setLoadingReportId={setLoadingReportId}
          lockState={report.singleUserAccess}
        />
      ))}
    </Flex>
  );
};

export const isCurrentStatus = (status: string) =>
  [ReportStatus.New.toLowerCase(), ReportStatus.Edit.toLowerCase(), ReportStatus.UnderReview.toLowerCase()].includes(
    status.toLowerCase()
  );
export const isPreviousStatus = (status: string) =>
  [
    ReportStatus.NotSubmitted.toLowerCase(),
    ReportStatus.Submitted.toLowerCase(),
    ReportStatus.SubmittedFeedback.toLowerCase(),
  ].includes(status.toLowerCase());

interface DashboardProps {
  data?: AccessDetail[];
}

const Dashboard = ({ data }: DashboardProps) => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const userInfo = useUserInfo();
  // reportId of the report that is launching, if any (getReport in progress)
  const [loadingReportId, setLoadingReportId] = useState('');

  const selectedOrganisation = data?.find(
    (business) => business.organisationDetails?.organisationId === currentBusinesses.activeBusiness?.id
  );

  // sort before we pass into report summaries
  selectedOrganisation?.providerReports?.sort((a, b) => b.dueDate - a.dueDate);

  const currentReports = selectedOrganisation?.providerReports?.filter((report) => isCurrentStatus(report.status));
  const pastReports = selectedOrganisation?.providerReports?.filter((report) => isPreviousStatus(report.status));

  // Pre-fetching of the top reports so they are in apollo cache
  const preCacheReports = currentReports?.slice(0, 3).map((report) => report.userReportId);

  // Setting the tab state to ensure it is the default value of 'current'
  useEffect(() => {
    dashboardReportTabState('current');
  }, []);

  return (
    <div>
      <Grid className="dashboard-wrapper" templateColumns="1fr">
        <Grid templateColumns="1fr 1fr" className="dashboard-header-wrapper">
          <View as="section">
            <Heading fontWeight="600" fontSize="39px" testId="dashboard-header-heading" level={1}>
              <VisuallyHidden>Dashboard for </VisuallyHidden>
              {selectedOrganisation?.organisationDetails?.providerDetails.name || 'Dashboard'}
            </Heading>
            {getDsp(selectedOrganisation?.dsp).showDspBusinessName &&
              selectedOrganisation?.organisationDetails?.dspBusinessName !== '' && (
                <Heading className="dashboard-dsp-name-heading" testId="dsp-name-heading" level={2}>
                  {selectedOrganisation?.organisationDetails?.dspBusinessName}
                </Heading>
              )}
          </View>
          <View testId="dashboard-header-business-select-wrapper">
            <BusinessSelect />
          </View>
          <View className="dashboard-abn-wrapper">
            <Heading color="#326297" fontWeight="600" fontSize="23px" testId="dashboard-header-heading" level={2}>
              Welcome, {userInfo?.firstName}
            </Heading>
            <Text color="#000000" marginTop="24px" fontWeight="400" fontSize="16px" testId="dashboard-abn-text">
              Welcome to your dashboard. Here you can access your reports and change the business you are reporting for.
            </Text>
          </View>
        </Grid>
      </Grid>
      <Grid className="business-info" templateColumns="1fr">
        <Heading margin="46px 0" level={2} fontWeight="600" fontSize="28px">
          Business information
        </Heading>
        <Grid templateColumns="3fr 3fr 3fr 3fr" className="dashboard-data-wrapper">
          <DashboardInfo
            heading="Business Name"
            uniqueId="trading-name"
            data={selectedOrganisation?.organisationDetails?.providerDetails.name}
          />
          <DashboardInfo
            heading="ABN"
            uniqueId="business-abn"
            data={formatAbn(selectedOrganisation?.organisationDetails?.providerDetails.currentPEGAABN)}
          />
          <DashboardInfo
            heading="State"
            uniqueId="state"
            data={selectedOrganisation?.organisationDetails?.providerDetails?.address?.state}
          />
          <DashboardInfo
            heading="Postcode"
            uniqueId="postcode"
            data={selectedOrganisation?.organisationDetails?.providerDetails?.address?.postcode}
          />
        </Grid>
      </Grid>
      <div className="tab-container">
        <Heading marginBottom="25px" level={2} fontSize="28px" fontWeight="600">
          ABS Business Report
        </Heading>

        <Tabs testId="report-tabs">
          <TabItem
            title="Current reports"
            testId="tab-current-reports"
            onClick={() => dashboardReportTabState('current')}
          >
            {!currentReports?.length ? (
              <Text className="empty-report-list">You have no reports to complete</Text>
            ) : (
              <ReportSummaries
                reports={[...currentReports, ...(pastReports ?? [])]}
                selectedOrganisation={selectedOrganisation}
                preCacheReports={preCacheReports}
                loadingReportId={loadingReportId}
                setLoadingReportId={setLoadingReportId}
              />
            )}
          </TabItem>
          <TabItem title="Past reports" testId="tab-submitted-reports" onClick={() => dashboardReportTabState('past')}>
            {!pastReports?.length ? (
              <Text className="empty-report-list">You have no past reports to view</Text>
            ) : (
              <ReportSummaries
                reports={[...pastReports, ...(currentReports ?? [])]}
                selectedOrganisation={selectedOrganisation}
                preCacheReports={preCacheReports}
                loadingReportId={loadingReportId}
                setLoadingReportId={setLoadingReportId}
              />
            )}
          </TabItem>
        </Tabs>
      </div>
      <View paddingLeft="24px" paddingTop="16px" paddingBottom="168px">
        <InsightsWidget />
      </View>
    </div>
  );
};

export default Dashboard;

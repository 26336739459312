import { externalLinks } from 'lib/externalLinks';

export const content = {
  heading: 'Welcome to ABS Business Reporting',
  summary:
    'Every year we engage businesses and organisations to help produce statistics on the current state of the Australian economy.',
  list: [
    'ABS Business Reporting is designed to make it easier for small and medium businesses to report to the ABS.',
    'We will collect your information under the Census and Statistics Act 1905, meaning we are legally required to protect your data and maintain its confidentiality',
    'Your report helps provide a snapshot of how small and medium businesses across the country are doing',
  ],
  linkPrivacy: externalLinks.absDataPrivacySecurity,
  linkDataUsage: externalLinks.absBusinessReportingDataUsage,
  linkContact: externalLinks.absContactUs,
  timeOut: {
    title: 'Your session timed out',
    variation: 'timeOut',
    listItem: [
      'We noticed you were inactive and logged you out for your security.',
      'Please log in to continue your report.',
    ],
  },
  oktaUnverified: {
    title: 'Log in failed',
    variation: 'error',
    listItem: [
      'You were unable to log in with your accounting software due to an earlier attempt at creating an ABS account.',
      'Please click the activation link you received in an email, or press ‘Log in’ below and enter your email to continue.',
    ],
  },
};

import { Heading, Text, View } from '@aws-amplify/ui-react';
import './DashboardInfo.css';

interface DashboardInfoProps {
  heading: string;
  data?: string;
  uniqueId: string;
}

const DashboardInfo = ({ heading, data, uniqueId }: DashboardInfoProps) => (
  <View className="dashboard-info-wrapper">
    <Heading className="dashboard-info-heading" testId={`dashboard-info-heading-${uniqueId}`} level={3}>
      {heading}
    </Heading>
    <Text className="dashboard-info-data" testId={`dashboard-info-data-${uniqueId}`}>
      {data}
    </Text>
  </View>
);

export default DashboardInfo;

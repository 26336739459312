import { InMemoryCache, makeVar } from '@apollo/client';
import { ReportAnswer } from 'models';
import mockDataItemsJson from 'pages/Report/dataItems.json';
import mockDspDataItemsJson from 'pages/Report/dspDataItems.json';
import makeVarPersisted from 'apollo/MakeVarPersisted';
import { ErrorState } from 'apollo/states/ErrorState';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';

export const cache = new InMemoryCache({
  // docs: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicies
  // keys correlate to __typename property of the object
  // values are TypePolicy objects
  typePolicies: {
    Query: {
      // TODO: most of these are mock data from early versions of the app and should be removed
      fields: {
        savedReportDataItems: {
          read() {
            return savedReportDataItems();
          },
        },
        dataItems() {
          return dataItems();
        },
        dspDataItems() {
          return dspDataItems();
        },
        savedEmployment() {
          return savedEmployment();
        },
        errorState: {
          read() {
            return ErrorState();
          },
        },
      },
    },

    // This is intentionally kept as an example of how we have successfully patched strings into the
    // API response in the past.
    // TopicDetail: {
    //   // These only have .topic as their unique ID, which is only unique within a single report, so this prevents
    //   // normalising in cache (stores them under getReport response instead).
    //   keyFields: false,

    //   // docs for fields https://www.apollographql.com/docs/react/caching/cache-field-behavior/
    //   // key is field name, value is a FieldPolicy or FieldReadFunction
    //   // (as shortcut for a FieldPolicy that contains only a FieldReadFunction)
    //   // docs: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#handling-field-arguments
    //   fields: {
    //     topicTotalText: {
    //       read(existingValue: string | undefined, { readField }) {
    //         const topicId = readField<string>('topic');
    //         const defaults = topicId ? defaultTopicDetailFieldsByTopic[topicId] : undefined;
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    //         return existingValue ?? defaults?.topicTotalText ?? null;
    //       },
    //     },
    //   },
    // },
  },
});

const dataItems = makeVarPersisted(mockDataItemsJson, 'aspDataItems');
const dspDataItems = makeVarPersisted(mockDspDataItemsJson, 'aspDspDataItems');

const employmentAnswer: ReportAnswer = {
  type: 'integer',
  dataItemId: '1',
};

export const savedEmployment = makeVarPersisted<ReportAnswer | undefined>(employmentAnswer, 'aspSavedEmployment');

const DEFAULT_VISITED_PAGES: string[] = [];

/**
 * Reactive var to keep track of visited pages.
 */
export const visitedReportPages = makeVar(DEFAULT_VISITED_PAGES);

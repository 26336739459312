import PageNotFoundImage from 'assets/PageNotFound404.png';

export const ErrorContent = [
  {
    title: '404',
    summary: "Oops! We can't find that page",
    content: 'Looks like that page is missing.',
    img: PageNotFoundImage,
  },
  {
    title: '403',
    summary: 'Your business has been disconnected',
    content: 'Please grant this business access in your accounting software and try again.',
  },
  {
    title: '500',
    summary: 'Internal Server Error',
    content: 'There has been an error with our servers. Please try again later.',
  },
  {
    title: '401',
    summary: 'Unauthorised Access',
    content: 'Access is denied due to invalid credentials.',
  },
  {
    title: '404',
    summary: 'Web page unavailable',
    content: 'Sorry, ABS Business Reporting is not available. Please try again later or contact us.',
  },
];

type EnvType = {
  REACT_APP_OKTA_CLIENT_ID: string;
  REACT_APP_OKTA_DOMAIN: string;
  REACT_APP_OKTA_ACC: string;
  REACT_APP_LOGIN_CALLBACK: string;
  REACT_APP_APPSYNC_API_ENDPOINT: string;
  REACT_APP_ENV: string;
  REACT_APP_TIMEOUT?: 'false';
  REACT_APP_SAVE_PAYLOAD_REPORT_DATA_ITEM_LIMIT: string;
  REACT_APP_AUTOMATIC_SAVE_INTERVAL_SECONDS: string;
  REACT_APP_GUESTROLEARN: string;
  REACT_APP_IDENTITYPOOLID: string;
  REACT_APP_APPLICATION_ID: string;
  REACT_APP_SHOW_NO_DSP: string;
};

declare global {
  interface Window {
    env: EnvType;
  }
}

export const env: EnvType = { ...process.env, ...window.env };

import { getUpdatedReportDataItems } from 'utils/getUpdatedReportDataItems';
import { SavedReportDataItem, SavedReportDataItems } from 'models';

/*
  Utility functions to interact with SavedReportDataItems values without manually unwrapping and wrapping,
  and to allow replacing direct array manipulations with more declarative and readable code.

  Note: this module uses some functional programming techniques that are a bit verbose in JS and TS (compared
  to something like Haskell). The general idea is to reduce repetition and human error by pulling out commonly
  repeated patterns (like unwrapping and re-wrapping the array within a SavedReportDataItems) and allowing
  functions to be concisely defined with function composition and partial application.

  It isn't too important for all the utility code to be highly readable, as long as it is easy to understand
  where it is used. e.g. the definition for onlyNonStarFeedback is filterAnswersBy(isNonStarFeedbackItem), and
  it takes and returns a SavedReportDataItems, which I am hopeful is fairly readable.

  Concepts to read into if you want to know more about this approach:
  - functional programming (broad topic)
  - currying and partial application
      - instead of (a, b) => c, the curried version is (a) => (b) => c which allows 'partially applying' with
        a to get (b) => c. A concrete example here is mapSavedAnswers which takes function that updates a
        SavedReportDataItem[] and returns another function that will apply that transormation on the savedAnswers
        of any SavedReportDataItems it is given. Without the currying, you would have to pass in the transformation
        function every time you wanted to call it.
  - monads, functors and applicatives
      - these usually take several tries before they make sense, because they
        more abstract than we naturally think when coding. We use them all the time but usually without knowing
        what they are called, or taking full advantage of them (due to historical language support and it just
        not being part of the more basic programming terminology).
      - don't let the fancy names confuse you, they are fairly simple concepts but awkward to explain. Start with
        functor since it is simplest:
          - functor: a context that holds values that can be mapped over. e.g. Array is a functor because you can
            map a function over its elements.
              - If there is a function (F<A>, A => B) => F<B> then F is a functor. Array.prototype.map fits that
                signature (just with the F<A> being the array instance you call it on).
              - All about being able to transform items within a container (immutably by returning a new container)
          - applicatives are functors, but the functions you map over them are also wrapped in a context
          - monads are applicatives, but they can also apply functions that return values wrapped in a context
      - The first 3 pictures in the Monads section of this link, and the text that goes with them, sums it up concisely:
        https://www.adit.io/posts/2013-04-17-functors,_applicatives,_and_monads_in_pictures.html#monads
*/

/**
 * Map a function across saved answers, returning a new copy of the SavedReportDataItems if saved answers
 * has changed (according to object equality). Curried.
 *
 * @param f callback function mapping from the existing savedAnswers to new savedAnswers. Should not mutate
 *          the provided array, always return a copy unless nothing changed.
 * @returns function that takes items and returns a new instance if saved answers changed, otherwise the existing instance
 */
const mapSavedAnswers =
  (f: (savedAnswers: SavedReportDataItem[]) => SavedReportDataItem[]) => (items: SavedReportDataItems) => {
    const newSavedAnswers = f(items.savedAnswers);
    // keep object equality if possible
    if (newSavedAnswers === items.savedAnswers) return items;
    return { savedAnswers: newSavedAnswers };
  };

/**
 * Apply a function to savedAnswers and return the result, unwrapped (curried).
 *
 * @param f callback function to run on savedAnswers
 * @returns function that takes items and returns the result of calling f with its savedAnswers
 */
const applySavedAnswers =
  <T>(f: (savedAnswers: SavedReportDataItem[]) => T) =>
  (items: SavedReportDataItems) =>
    f(items.savedAnswers);

/**
 * Predicate (curried) that matches an item with given dataItemId
 *
 * @param dataItemId id to match on
 * @returns function that take an item and returns true if its dataItemId matches
 */
const withDataItemId = (dataItemId: string) => (item: SavedReportDataItem) => item.dataItemId === dataItemId;

/**
 * Predicate to find star rating items
 *
 * @param item item to check
 * @returns true if the item is a star rating
 */
export const isStarRatingItem = (item: SavedReportDataItem) => item.topic === 'ASPFeedback' && item.action === 'STAR';

/**
 * Predicate to find feedback items
 *
 * @param item item to check
 * @returns true if the item is a feedback item
 */
export const isFeedbackItem = (item: SavedReportDataItem) =>
  item.topic === 'ASPFeedback' && item.action === 'TEXT_S=250_X';

/**
 * Predicate to find non-star feedback items
 *
 * @param item item to check
 * @returns true if the item is a feedback item but not a star rating
 */
export const isNonStarFeedbackItem = (item: SavedReportDataItem) =>
  item.topic === 'ASPFeedback' && !item.action?.match(/STAR/);

/**
 * Filter savedAnswers by a given predicate.
 *
 * @param predicate predicate to filter on, must return true for items that will be included
 * @returns function that takes items and returns a copy with only savedAnswers that match the predicate
 */
const filterAnswersBy = (predicate: (item: SavedReportDataItem) => boolean) =>
  mapSavedAnswers((answers) => answers.filter(predicate));

/**
 * Filter saved answers down to only non-star feedback items
 */
const onlyNonStarFeedback = filterAnswersBy(isNonStarFeedbackItem);

/**
 * Find a data item by its id.
 *
 * @param dataItemId to search for
 * @returns function that takes items and returns the one matching dataItemId, if present
 */
const findByDataItemId = (dataItemId: string) =>
  applySavedAnswers((answers) => answers.find(withDataItemId(dataItemId)));

/**
 * Generate an updated copy of localSaved that has all feedback except star rating reverted
 * to the server saved values.
 *
 * @param localSaved local saved answers (i.e. from savedReportDataItems RV)
 * @param serverSaved server saved answers (i.e. from latestSavedReportDataItems RV)
 * @returns a copy of localSaved that has no differences in feedback from serverSaved, except star rating
 */
export const withNonStarFeedbackReverted = (localSaved: SavedReportDataItems, serverSaved: SavedReportDataItems) => {
  const localNonStarFeedback = onlyNonStarFeedback(localSaved);
  const serverNonStarFeedback = onlyNonStarFeedback(serverSaved);

  const updatedItems = getUpdatedReportDataItems(
    // using a copy since getUpdatedReportDataItems mutates its first argument (probably a bug)
    mapSavedAnswers((savedAnswers) => savedAnswers.map((item: SavedReportDataItem) => ({ ...item })))(
      localNonStarFeedback
    ),
    serverNonStarFeedback
  );

  if (!updatedItems.savedAnswers.length) return localSaved;

  const newLocalSavedAnswers = [...localSaved.savedAnswers];

  updatedItems.savedAnswers.forEach((item) => {
    const index = newLocalSavedAnswers.findIndex((i) => i.dataItemId === item.dataItemId);
    if (item.overwrite) {
      // value existed, replace with previous value
      const serverItem = findByDataItemId(item.dataItemId)(serverNonStarFeedback);
      if (serverItem) newLocalSavedAnswers[index] = serverItem;
    } else {
      // value did not exist, remove it
      newLocalSavedAnswers.splice(index);
    }
  });

  return { savedAnswers: newLocalSavedAnswers };
};

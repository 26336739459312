import { useState } from 'react';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { useIdleTimer } from 'react-idle-timer';

import { InactivityModal } from './InactivityModal';

export const UserInactivity = ({ timeouts }: { timeouts: [number, number] }) => {
  const [timerState, setTimerState] = useState<'Active' | 'Idle' | 'Prompted'>('Active');
  const [inactivityTimeout, promptTimeout] = timeouts;

  const onPrompt = () => {
    setTimerState('Prompted');
    recordRumCustomEvent(RumCustomEvent.timeoutModalAppear, getRumAttributes());
  };

  const onIdle = () => {
    setTimerState('Idle');
  };

  const onActive = () => {
    setTimerState('Active');
  };

  const timer = useIdleTimer({
    timeout: inactivityTimeout,
    promptBeforeIdle: promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    crossTab: true,
    syncTimers: 200,
  });

  return <InactivityModal timer={timer} timerState={timerState} promptTimeout={promptTimeout} />;
};

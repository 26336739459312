import { ChosenReport } from 'models';
import makeVarPersisted from 'apollo/MakeVarPersisted';

export const ChosenReportInitialState: ChosenReport = {
  dspProvider: '',
  organisationId: '',
  userReportId: '',
  singleUserAccess: {},
};

export const chosenReport = makeVarPersisted<ChosenReport>(ChosenReportInitialState, 'aspChosenReport');

/* istanbul ignore file */
import ReportInstructions from './Instructions/Instructions';
import ReportReview from './Review/Review';
import ReportConfirmation from './Confirmation/Confirmation';

const ReportPages = {
  Instructions: ReportInstructions,
  Review: ReportReview,
  Confirmation: ReportConfirmation,
};

export default ReportPages;

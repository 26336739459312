import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  DAYNUM_MONTHNAME,
  DAYNUM_MONTHNAME_YEAR,
  DAYNUM_SHORT_MONTHNAME_YEAR,
  MONTHNAME,
  MONTHNAME_YEAR,
  YEAR,
  YEAR_TWO_DIGIT,
} from './dayjsFormats';

dayjs.extend(utc);

export const ReportPeriodFormat = (startTime: number, endTime: number): string => {
  let periodText = '';

  // Dayjs types are not properly inferred
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const start = dayjs.utc(startTime);
  const end = dayjs.utc(endTime);
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  if (start.isValid() && end.isValid()) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    switch (Math.round(end.diff(start, 'month', true))) {
      case 3:
        periodText = `${end.format(MONTHNAME)} quarter ${end.format(YEAR)}`;
        break;
      case 12:
        periodText =
          start.format(YEAR) === end.format(YEAR)
            ? `${start.format(DAYNUM_MONTHNAME)} - ${end.format(DAYNUM_MONTHNAME)} ${start.format(YEAR)} financial year`
            : `${start.format(YEAR)}-${end.format(YEAR_TWO_DIGIT)} financial year`;
        break;
      default:
        periodText = `Reporting period (${start.format(DAYNUM_MONTHNAME)} - ${end.format(
          DAYNUM_MONTHNAME
        )}) ${end.format(YEAR)}`;
    }
    return `${periodText}`;
  }

  return periodText;
};

const QuarterFormat = (startTime: number, endTime: number, isExtendedFormat: boolean): string => {
  let text = '';

  // Dayjs types are not properly inferred
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const start = dayjs.utc(startTime);
  const end = dayjs.utc(endTime);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const monthDiff = Math.round(end.diff(start, 'month', true));
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  if (start.isValid() && end.isValid()) {
    switch (monthDiff) {
      case 3: {
        const quarterText = `${start.format(DAYNUM_MONTHNAME)} - ${end.format(DAYNUM_MONTHNAME)}`;
        text = isExtendedFormat
          ? `${end.format(MONTHNAME)} quarter (${quarterText}) ${end.format(YEAR)}`
          : `${quarterText} ${end.format(YEAR)}`;
        break;
      }
      default:
        text = `${start.format(DAYNUM_MONTHNAME_YEAR)} - ${end.format(DAYNUM_MONTHNAME_YEAR)}`;
    }
  }
  return text;
};

const QuarterTitleFormat = (startTime: number, endTime: number): string => {
  let text = '';

  // Dayjs types are not properly inferred
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const start = dayjs.utc(startTime);
  const end = dayjs.utc(endTime);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const monthDiff = Math.round(end.diff(start, 'month', true));
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  if (start.isValid() && end.isValid()) {
    switch (monthDiff) {
      case 3: {
        text = `${start.format(DAYNUM_SHORT_MONTHNAME_YEAR)} - ${end.format(DAYNUM_SHORT_MONTHNAME_YEAR)}`;
        break;
      }
      default:
        text = `${start.format(DAYNUM_SHORT_MONTHNAME_YEAR)} - ${end.format(DAYNUM_SHORT_MONTHNAME_YEAR)}`;
    }
  }
  return text;
};

export const ReportQuarterFormat = (startTime: number, endTime: number) => QuarterFormat(startTime, endTime, false);

export const ReportExtendedQuarterFormat = (startTime: number, endTime: number) =>
  QuarterFormat(startTime, endTime, true);

export const ReportTitleQuarterFormat = (startTime: number, endTime: number) => QuarterTitleFormat(startTime, endTime);

export const ReportEndFormat = (endTime: number): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const end = dayjs.utc(endTime);
  if (end.isValid()) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return end.format(MONTHNAME_YEAR);
  }

  return '';
};

import { ReactNode } from 'react';
import { Grid, View, useTheme } from '@aws-amplify/ui-react';

import DashboardSideNav from 'components/DashboardSideNav/DashboardSideNav';

import './DashboardLayout.css';

interface DashboardLayoutProps {
  children?: ReactNode;
}
const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { tokens } = useTheme();
  return (
    <Grid className="container-grid" gap={tokens.space.medium} templateColumns="repeat(12, 1fr)">
      <View columnStart="1" columnEnd="3">
        <DashboardSideNav />
      </View>

      <View className="dasboard-container-right" columnStart="3" columnEnd="-1">
        {children}
      </View>
    </Grid>
  );
};

export default DashboardLayout;

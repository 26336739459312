import dayjs from 'dayjs';
import { DspDataItemObject } from 'models';

export const cleanseDspDataItems = (dspDataItems: DspDataItemObject[]): DspDataItemObject[] => {
  const dateNow = dayjs().valueOf();
  let dirty = false;

  const updatedDataItems = dspDataItems.map((item) => {
    const updates: Partial<DspDataItemObject> = {};
    if (item.dspValue === '') {
      updates.dspValue = '0';
    }
    // Use DateSave to hold the date this dsp item was read (dspDateRead ReportDataItemObject)

    // This was clobbering the server value for when this was queried from the DSP.
    // Changed to only replace if not present, but we should investigate why it is sometimes null.
    if (!item.dspDateSave) {
      updates.dspDateSave = dateNow;
    }

    if (Object.keys(updates).length) {
      dirty = true;
      return { ...item, ...updates };
    }

    return item;
  });

  return dirty ? updatedDataItems : dspDataItems;
};

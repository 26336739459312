import './Menu.css';
import { useEffect, useState } from 'react';
import { Flex, Grid, Menu, MenuButton, MenuItem, Text, useTheme, View, Link, Loader } from '@aws-amplify/ui-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useSaveReport } from 'utils/useSaveReport';
import { externalLinks } from 'lib/externalLinks';
import { doLogout } from 'utils/doLogout';
import { recordRumCustomEvent, recordRumCustomEventWithPageId } from 'services/awsRum';
import { isCurrentReportFullySaved, isCurrentReportSaveable } from 'services/SaveReportManager/currentReportStatus';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useUserInfo } from 'lib/userInfoHook';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import { ReactComponent as ABSLogo } from 'assets/logo-ABS.svg';
import { ReactComponent as UserIcon } from 'assets/icon-user.svg';

/**
 * Various amplify default behaviours cause these items to still be focusable
 * (despite aria-hidden being set to true) when the menu is opened.
 * This is a serious ARIA defect, and we must manually fix them here.
 */
const updateAriaFocus = (open: boolean) => {
  const skipLink = document.querySelector('.skip-link');
  const spans = document.querySelectorAll('span[data-aria-hidden="true"]');
  const main = document.querySelector('main.main-wrapper');
  if (skipLink) {
    if (open) {
      skipLink.setAttribute('disabled', 'true');
      skipLink.setAttribute('aria-hidden', 'true');
    } else {
      skipLink.setAttribute('disabled', 'false');
      skipLink.setAttribute('aria-hidden', 'false');
    }
  }
  if (spans.length) {
    if (open) {
      spans.forEach((span) => {
        span.setAttribute('aria-disabled', 'true');
        span.setAttribute('aria-hidden', 'true');
        span.setAttribute('tabindex', '-1');
      });
    } else {
      spans.forEach((span) => {
        span.setAttribute('aria-disabled', 'false');
        span.setAttribute('aria-hidden', 'false');
        span.setAttribute('tabindex', '0');
      });
    }
  }
  if (main) {
    if (open) {
      main.setAttribute('aria-disabled', 'true');
      main.setAttribute('aria-hidden', 'true');
      main.setAttribute('tabindex', '-1');
    } else {
      main.setAttribute('aria-disabled', 'false');
      main.setAttribute('aria-hidden', 'false');
      main.setAttribute('tabindex', '0');
    }
  }
};

const loggedInMenuList: string[] = [
  'dashboard',
  'report',
  'select-business',
  'manage-connections',
  'authorisation',
  'claim-obligation',
  'manual-authorisation',
  'industry-insights',
];
const loggedInNavList: string[] = ['report', 'dashboard'];

const HeaderMenu = () => {
  const location = useLocation();
  const { tokens } = useTheme();
  const showUserNav: boolean = loggedInNavList.indexOf(location.pathname.replace(/^\/+/, '').split('/')[0]) >= 0;
  const showUserMenu: boolean = loggedInMenuList.indexOf(location.pathname.replace(/^\/+/, '').split('/')[0]) >= 0;
  const onDashboard: boolean = location.pathname.replace(/^\/+/, '').split('/')[0] === 'dashboard';
  const userInfo = useUserInfo();
  const { oktaAuth } = useOktaAuth();
  const [saveReport, saveReportResponse] = useSaveReport('HeaderMenu');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [dashboardClicked, setDashboardClicked] = useState(false);
  const [logoutClicked, setLogoutClicked] = useState(false);
  const navigate = useNavigate();

  const handleDashboardClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!onDashboard) {
      recordRumCustomEvent(RumCustomEvent.reportClose, getRumAttributes());

      // Save before wiping out the report state if needed.
      if (!isCurrentReportSaveable() || isCurrentReportFullySaved()) {
        // Can't save or nothing to save, proceed immediately
        setDashboardClicked(false);
        navigate('/dashboard');
      } else {
        // TODO: replace with background saving of a snapshot of the state when implemented
        // Something needs saving, start a save and wait to proceed
        saveReport('handleDashboardClick', 'user clicked Dashboard', false);

        // Prevent navigation and set up to navigate when saved
        e.preventDefault();
        setDashboardClicked(true);
      }
    }
  };

  // Failsafe to make sure dashboard link spinner does not get stuck always-on if save does not complete
  useEffect(() => {
    if (onDashboard) {
      setDashboardClicked(false);
    }
  }, [onDashboard]);

  // Continue operations initiated by save
  useEffect(() => {
    // Only proceed if saveReport is complete
    // User is already leaving, so treat errors as unrecoverable
    const { success, error } = saveReportResponse;
    if (!(success || error)) return;

    // Note: could add a RUM log for error state here, since it represents failing to save
    //       all data before closing the report.

    if (dashboardClicked) {
      setDashboardClicked(false);
      navigate('/dashboard');
    }

    if (logoutClicked) {
      setLogoutClicked(false);
      doLogout(oktaAuth);
    }

    // Intentionally only running on completed save, not when links become clicked
    // (results from a prior save would trigger behaviour when not desired)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportResponse.success, saveReportResponse.error]);

  const handleMenuOpenChange = (open: boolean) => {
    setMenuIsOpen(open);
    updateAriaFocus(open);
  };

  const HandleLogout = () => {
    // if user in report and it has unsaved changes that can be saved, delay logout to save it
    if (showUserNav && !onDashboard && isCurrentReportSaveable() && !isCurrentReportFullySaved()) {
      saveReport('HandleLogout', 'user clicked user menu > Log out', false);
      // Delay actual logout until save has completed, or save will fail
      setLogoutClicked(true);
      return;
    }

    doLogout(oktaAuth);
  };

  const handleContactUs = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.open(`${externalLinks.absContactUs}`, '_blank');
    setMenuIsOpen(false);
    recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes());
  };

  const handleUserName = () => {
    const lengthToCut = 46;
    const text = `${userInfo?.firstName} ${userInfo?.lastName}`;
    let username = text;
    if (text.length > lengthToCut) {
      username = text?.substr(0, lengthToCut);
      username += '...';
    }
    return username;
  };

  // Hold menu open if report is saving before doing logout
  const menuOpen = menuIsOpen || logoutClicked;

  return (
    <header className="menu-outer-wrapper" data-testid="menu-outer-wrapper">
      <Grid
        className="container-grid menu-wrapper"
        gap={tokens.space.medium}
        templateColumns="repeat(12, 1fr)"
        alignItems="center"
      >
        <View columnStart="2" columnEnd="7">
          <Flex justifyContent="flex-start" alignItems="center">
            <View
              ariaLabel="Australian Bureau of Statistics Logo"
              width="62px"
              height="55px"
              testId="nav-menu-logo"
              className="absLogo"
            >
              <ABSLogo />
            </View>
            <Text as="span" fontSize="20px" fontWeight="700" testId="nav-menu-heading">
              ABS Business Reporting
            </Text>
            {showUserNav && (
              <NavLink
                aria-label="Dashboard"
                to="/dashboard"
                data-testid="menu-home"
                className={onDashboard ? 'menu-header-home on-dashboard' : 'menu-header-home'}
                onClick={handleDashboardClick}
              >
                Dashboard
                {dashboardClicked && <LayoutPreservingLoader />}
              </NavLink>
            )}
          </Flex>
        </View>

        {showUserMenu && (
          <View className="menu-right" position="relative" columnStart="7" columnEnd="12">
            {userInfo && (
              <Menu
                isOpen={menuOpen}
                onOpenChange={handleMenuOpenChange}
                menuAlign="end"
                trigger={
                  <MenuButton
                    borderRadius="small"
                    className={menuOpen ? 'name-menu-open' : 'name-menu'}
                    tabIndex={menuOpen ? -1 : undefined}
                  >
                    <Text testId="menu-user-name" className="username">
                      {handleUserName()}
                    </Text>
                    <View testId="user-icon" ariaLabel="user-icon" className="user-icon">
                      <UserIcon />
                    </View>
                  </MenuButton>
                }
              >
                <MenuItem onClick={handleContactUs} borderRadius="small" className="contact-us-card">
                  <Link
                    isExternal
                    href={externalLinks.absContactUs}
                    data-testid="dashboard-contact-us"
                    className="contact-us"
                  >
                    Contact us
                  </Link>
                </MenuItem>
                <MenuItem onClick={HandleLogout} borderRadius="small" className="log-out-card-alert">
                  <Text data-testid="dashboard-log-out" className="log-out-alert">
                    Log out
                    {logoutClicked && <LayoutPreservingLoader />}
                  </Text>
                </MenuItem>
              </Menu>
            )}
          </View>
        )}
      </Grid>
    </header>
  );
};
export default HeaderMenu;

/**
 * Quick inline loader that does not affect layout (takes up no space)
 * @returns
 */
function LayoutPreservingLoader() {
  return (
    <span style={{ display: 'inline-block', position: 'relative', width: 0, height: 0 }}>
      <Loader position="absolute" left="0.5em" bottom="0" aria-label="Saving changes" />
    </span>
  );
}

import { Text, Flex, SwitchField, Label, Loader } from '@aws-amplify/ui-react';
import './InsightsToggle.css';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';

interface Props {
  title: string;
  minWidth?: string;
  inputId: string;
  ariaLabel: string;
  label: string;
  info?: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  handleChange: (value: boolean) => void;
}

const InsightsToggle: React.FC<Props> = ({
  title,
  minWidth = '150px',
  ariaLabel,
  label,
  info,
  disabled,
  handleChange,
  loading,
}) => (
  <Flex direction="column" gap="4px" testId={`select-insights-input-${title}`} minWidth={minWidth}>
    <Text
      className="select-business-label"
      data-testid="toggle-value-back-label"
      style={{ marginBottom: '0px', fontWeight: '400' }}
    >
      {title}

      {info && (
        <InputLabelTooltip
          title={info}
          helpTextFor={ariaLabel}
          placement="top"
          arrow
          style={{ marginLeft: '0.2rem' }}
          data-testid="toggle-value-back-tooltip"
        />
      )}
    </Text>
    <Flex direction="row" gap="8px" alignItems="center" paddingTop="3px">
      <SwitchField
        label=""
        aria-label={ariaLabel}
        trackCheckedColor="#326297"
        isDisabled={disabled}
        onChange={(e) => handleChange(Boolean(e.currentTarget.checked))}
        id="business-data-switch-field"
        data-testid="business-data-switch-field"
        size="large"
        lineHeight="0.5"
      />
      {label && (
        <>
          {loading && <Loader aria-label="Loading..." height="16px" width="16px" />}
          <Label
            {...(loading && { className: 'loading-ellipsis' })}
            htmlFor="business-data-switch-field"
            color="grey"
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            fontWeight="400"
          >
            {label}
          </Label>
        </>
      )}
    </Flex>
  </Flex>
);

export default InsightsToggle;

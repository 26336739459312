import { ChosenReport } from 'models';

interface PlaceholderMappings {
  reportEndMonthYear: string;
}

/**
 * Substitutes values into text at {{placeholder}} placeholders, based on the given report.
 *
 * @param report from which to derive placeholder values
 * @returns a callback that has all current substitution values baked in.
 */
export const buildSubstitutePlaceholders = (report: ChosenReport) => {
  const { reportEnd } = report;

  const placeholderValueMap: PlaceholderMappings = {
    reportEndMonthYear: reportEnd || '',
    // Other substitutions can be added here.
  };

  return buildPlaceholderSubstitutionCallback(placeholderValueMap);
};

/**
 * Builds callback to substitute placeholders with given mappings
 */
function buildPlaceholderSubstitutionCallback(placeholderValueMap: PlaceholderMappings) {
  // Convert type to general object to allow arbitrary string lookup
  const placeholderLookup = placeholderValueMap as unknown as Record<string, string | undefined>;

  return (text: string) => {
    const placeholders = findPlaceholders(text);
    if (!placeholders.size) return text;

    let textWithSubstitutions = text;

    // Iterate the placeholders and insert the substitution
    placeholders.forEach((placeholder) => {
      textWithSubstitutions = textWithSubstitutions.replaceAll(
        `{{${placeholder}}}`,
        placeholderLookup[placeholder] || ''
      );
    });

    return textWithSubstitutions;
  };
}

// index 0 is the full placeholder including wrapping {{ }}, index 1 is unwrapped placeholder
const PLACEHOLDER_REGEX = /{{([^{}]+)}}/g;

/**
 * Find all placeholders in the form {{placeholder}} in a string
 *
 * @param text in which to search for placeholders
 * @returns Set of placeholder strings without wrapping brackets
 */
function findPlaceholders(text: string) {
  let matchResult;
  const placeholders = new Set<string>();
  // Purposely assigning in the while condition.
  // eslint-disable-next-line no-cond-assign
  while ((matchResult = PLACEHOLDER_REGEX.exec(text)) !== null) {
    placeholders.add(matchResult[1]);
  }
  return placeholders;
}

import { Button } from '@aws-amplify/ui-react';

import { ReactComponent as TrashIconSvg } from 'assets/icon-trash.svg';
import './ReportList.scss';

interface RemoveButtonProps {
  removeCallback: () => void;
}

const RemoveButton = (props: RemoveButtonProps) => {
  const { removeCallback } = props;
  return (
    <Button
      className="report-list-item-action-btn"
      title="remove"
      ariaLabel="Remove Item"
      onClick={removeCallback}
      testId="report-list-item-action-btn"
    >
      <TrashIconSvg />
    </Button>
  );
};

export default RemoveButton;

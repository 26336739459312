import { ApolloError, useApolloClient, useReactiveVar } from '@apollo/client';
import { chosenReport } from 'apollo/states/ChosenReport';
import { useCallback, useMemo, useState } from 'react';
import { GET_DSP_DATA_ITEMS, GetDspDataItemsResponse } from 'apollo/queries/getDspDataItems';
import { ChosenReport } from 'models';
import { syncFetchedDspDataItems } from 'utils/syncFetchedDspDataItems';
import { syncingDspItems } from 'apollo/states/operationsInProgress';

/**
 * Asynchronously synchronise DSP items (from GetReport)
 * and update any values already mapped from changed DSP items.
 */
const useSyncDspItems = () => {
  const isMergingSyncData = useReactiveVar(syncingDspItems);
  const [isFetchingSyncData, setIsFetchingSyncData] = useState(false);
  const isSyncing = isFetchingSyncData || isMergingSyncData;

  const dashboardChosenReport = useReactiveVar(chosenReport);
  const queryVariables = useMemo(() => ({ ...dashboardChosenReport }), [dashboardChosenReport]);
  const apolloClient = useApolloClient();
  const [syncPromise, setSyncPromise] = useState<Promise<void>>(Promise.resolve());

  const startSync = useCallback(() => {
    if (isSyncing) return syncPromise;

    setIsFetchingSyncData(true);

    const newSyncPromise = new Promise<void>((resolve, reject) => {
      const processQueryResponse = (result: GetDspDataItemsResponse) => {
        if (result.getReport?.success) {
          syncFetchedDspDataItems(result.getReport.report.dspDataItems)
            .then((success) => {
              if (success) {
                resolve();
              } else {
                reject(new Error('syncFetchedDspDataItems promise resolved with success false'));
              }
            })
            .catch(() => {
              reject(new Error('merging local sync values failed'));
            });
        } else {
          const errorMessages = result.getReport.errorMessages ?? [];
          reject(errorMessages);
        }

        setIsFetchingSyncData(false);
      };

      const handleQueryError = (apolloError: ApolloError) => {
        reject(new Error(apolloError.message ?? 'error on fetching dsp data items for sync'));
        setIsFetchingSyncData(false);
      };

      const handleRequestError = (error?: Error) => {
        reject(error?.message ?? 'error on fetching dsp data items for sync');
      };

      apolloClient
        .query<GetDspDataItemsResponse, ChosenReport>({
          query: GET_DSP_DATA_ITEMS,
          fetchPolicy: 'no-cache',
          variables: queryVariables,
          notifyOnNetworkStatusChange: true,
        })
        .then((result) => {
          if (result.error) {
            handleQueryError(result.error);
          } else {
            processQueryResponse(result.data);
          }
        })
        .catch((error) => {
          handleRequestError(error as Error);
        })
        .finally(() => {
          // ensure syncing flag is cleared
          setIsFetchingSyncData(false);
        });
    });

    setSyncPromise(newSyncPromise);

    return newSyncPromise;
  }, [isSyncing, syncPromise, apolloClient, queryVariables]);

  return [isSyncing, startSync] as const;
};

export default useSyncDspItems;

import { Button, ButtonGroup } from '@aws-amplify/ui-react';
import { RefObject, memo } from 'react';
import { TooltipComponent } from 'components/TooltipComponent/TooltipComponent';
import { ReactComponent as PlusSquareIconSvg } from 'assets/icon-plus-square.svg';
import { getDsp } from 'utils/dspIndex';
import { ReportListProps } from './ReportList';

type ReportListHeaderProps = Pick<ReportListProps, 'minified' | 'helpTexts' | 'dspId'> & {
  title: string;
  dataItemId: string;
  onAddFromDsp: () => void;
  onAddManualEntry: () => void;
  editingOrAdding: boolean;
  manualEntryButtonRef?: RefObject<HTMLButtonElement>;
};
export const ReportListHeader: React.FC<ReportListHeaderProps> = ({
  dspId,
  minified,
  title,
  dataItemId,
  helpTexts,
  onAddFromDsp,
  onAddManualEntry,
  editingOrAdding,
  manualEntryButtonRef,
}) => {
  if (minified) return <span data-testid="report-list-title">{title}</span>;

  return (
    <>
      <ReportListHeaderTitle title={title} helpTexts={helpTexts} dataItemId={dataItemId} />
      <ButtonGroup className="report-list-btn-row">
        <Button className="report-list-add-btn" testId="report-list-add-dsp-btn" onClick={onAddFromDsp}>
          <AddFromDspIconMemoized dspId={dspId} />
          Add from {getDsp(dspId).displayName}
        </Button>
        <Button
          className="report-list-add-btn"
          testId="report-list-add-manual-btn"
          onClick={onAddManualEntry}
          disabled={editingOrAdding}
          ref={manualEntryButtonRef}
        >
          <PlusSquareIconSvg />
          Manual entry
        </Button>
      </ButtonGroup>
    </>
  );
};

export const ReportListHeaderTitle = ({
  title,
  dataItemId,
  helpTexts,
  alwaysDisplayHelpTexts,
}: {
  title: string;
  dataItemId: string;
  helpTexts: string[] | undefined;
  alwaysDisplayHelpTexts?: boolean;
}) => (
  <TooltipComponent
    text={title}
    headingLevel={2}
    helpTexts={helpTexts}
    alwaysDisplay={alwaysDisplayHelpTexts}
    dataItemId={dataItemId}
  />
);

const AddFromDspIcon: React.FC<Pick<ReportListProps, 'dspId'>> = ({ dspId }) => getDsp(dspId).addFromIcon;

const AddFromDspIconMemoized = memo(AddFromDspIcon);

import { useNavigate } from 'react-router-dom';
import { Button, Heading } from '@aws-amplify/ui-react';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import ManageConnections from 'components/ManageConnections/ManageConnections';

import './ManageConnectionsPage.css';

const ManageConnectionsPage = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div id="skipTarget" />
      <div className="manage-connections-page-wrapper">
        <Heading className="manage-connections-page-heading" testId="manage-connections-page-heading" level={1}>
          Manage connections
        </Heading>
        <Button
          className="add-business-button"
          testId="add-business-button"
          variation="primary"
          onClick={() => navigate('/authorisation', { state: { addingBusiness: true } })}
        >
          Add business
        </Button>
        <ManageConnections />
      </div>
    </DashboardLayout>
  );
};

export default ManageConnectionsPage;

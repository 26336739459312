/**
 * Single place to coordinate all report saving.
 *
 * This may be broken up to separate files, but still represents one place that all the report
 * saving code should live in the system. This is to reduce bugs and make them easy to debug.
 *
 * Key parts of this system:
 *
 * - SaveReportManager component: nest in ASPApolloClient where it will render for all pages
 *     (not just report pages, since we need to ensure in-progress saves complete after a
 *      report is closed)
 * - hooks that can be used in any other component to subscribe to changes in save-related statuses
 *     - current report unchanged, changed and never saved, saving, saved and not changed since,
 *       changed after save, or "unknown" when no report is loaded
 *     - specific report ID same statuses as current report, but "unknown" when the report ID has
 *       not been loaded at all (in the current session).
 *       (e.g. to show reports on the dashboard that are still saving)
 *     - current report saved all data items or not (e.g. to check if good to submit or close)
 *     - any report data items pending save (e.g. to delay close until everything saved)
 * - sync callbacks to give a snapshot of any of the above hook states
 * - async callbacks to await specific hook states (e.g. to await all data items saved before logout)
 */

import { useCallback, useEffect, useRef } from 'react';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { useSaveReport } from 'utils/useSaveReport';
// import { useLocation } from 'react-router-dom';
import { recordRumCustomEvent } from '../awsRum';

import './SaveReportManager.css';
import { SaveReportDebugUI } from './SaveReportDebugUI';
import {
  isCurrentReportFullySaved,
  isCurrentReportSaveable,
  isFullySavedStatus,
  useCurrentReportSaveable,
  useCurrentReportSaveStatus,
  useTrackCurrentReportSaveStatus,
} from './currentReportStatus';

export const SaveReportManager = () => {
  // DO NOT REMOVE: this ensures the currentReportStatus var is kept up to date
  useTrackCurrentReportSaveStatus();

  const currentReportStatus = useCurrentReportSaveStatus();
  const reportFullySaved = isFullySavedStatus(currentReportStatus);
  const currentReportSaveable = useCurrentReportSaveable();
  const [saveReport] = useSaveReport('SaveReportManager');

  const hasReport = currentReportStatus !== 'unknown';

  const handleCloseBrowser = useCallback(
    (event: BeforeUnloadEvent) => {
      recordRumCustomEvent(RumCustomEvent.windowClose, getRumAttributes());

      if (isCurrentReportSaveable() && !isCurrentReportFullySaved()) {
        saveReport('handleCloseBrowser', 'user closed browser/tab', false);
        // preventing default on this triggers a dialog to buy us some time to save,
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        event.preventDefault();
      }
    },
    [saveReport]
  );

  const registeredHandleCloseBrowser = useRef<typeof handleCloseBrowser | undefined>(handleCloseBrowser);

  // const { pathname } = useLocation();
  // const isReportPage = pathname.startsWith('/report');
  useEffect(() => {
    // Handler is detached whenever there is no pending data to save, to prevent issues,
    // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event )

    // Always remove the old listener, whether it is to un-register the callback or replace it with a new one
    if (registeredHandleCloseBrowser.current) {
      window.removeEventListener('beforeunload', registeredHandleCloseBrowser.current);
      registeredHandleCloseBrowser.current = undefined;
    }

    // TODO: when background save is implemented, make sure background saves are included in
    //       the check for unsaved data, regardless whether a current report is loaded.
    // if (isReportPage && hasReport && !reportFullySaved) {
    if (hasReport && currentReportSaveable && !reportFullySaved) {
      window.addEventListener('beforeunload', handleCloseBrowser);
      // store reference to registered handler instance so it can be un-registered properly
      registeredHandleCloseBrowser.current = handleCloseBrowser;
      return () => window.removeEventListener('beforeunload', handleCloseBrowser);
    }
    // }, [isReportPage, hasReport, reportFullySaved, handleCloseBrowser]);
  }, [hasReport, reportFullySaved, currentReportSaveable, handleCloseBrowser]);

  // Don't render a visible element unless debug is on
  // TODO: split debug UI rendering to separate component so any work related to that can be avoided
  if (!window.showSaveReportDebugUI) return null;

  return <SaveReportDebugUI />;
};

declare global {
  interface Window {
    showSaveReportDebugUI: boolean;
  }
}

import { Button, Flex, Heading, Image, Text } from '@aws-amplify/ui-react';
import { InlineError } from 'components/InlineError/InlineError';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { useLocation } from 'react-router-dom';
import { useSaveReport } from 'utils/useSaveReport';
import { doLogout } from 'utils/doLogout';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSetReportLock } from 'utils/useSetReportLock';
import { useOktaAuth } from '@okta/okta-react';

import { RumCustomEvent } from 'enums/RumCustomEvent';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import ClockIcon from 'assets/icon-clock.svg';
import { IIdleTimer } from 'react-idle-timer';
import CountdownClock from './CountdownClock';
import './InactivityModal.scss';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(75, 79, 84, 0.17)',
    backdropFilter: ' blur(3px)',
    zIndex: '10001',
  },
};

export const TIMEOUT_PARAM = 'timeout';

type InactivityProps = {
  timer: IIdleTimer;
  timerState: 'Active' | 'Idle' | 'Prompted';
  promptTimeout: number;
};

export const InactivityModal = ({ timer, timerState, promptTimeout }: InactivityProps) => {
  const { oktaAuth } = useOktaAuth();
  const unlockReport = useSetReportLock('unlock');
  const lockReport = useSetReportLock('lock');

  const [inlineError] = useState(false);
  const [saveReport, saveReportResult] = useSaveReport('InactivityModal');
  const isInReport = !!useLocation().pathname.includes('report');

  const HandleSaveAndLogout = () => {
    if (isInReport) {
      saveReport('HandleSaveAndLogout', 'clicked Save and log out or Log out', false);
      // wait for save to complete before doing the actual logout
      return;
    }

    savedReportDataItems({
      savedAnswers: [],
    });
    latestSavedReportDataItems({
      savedAnswers: [],
    });
    doLogout(oktaAuth);
  };

  // proceed to logout after save & logout
  useEffect(() => {
    // Whether save succeeded or not, user wants to log out so don't try to recover
    if (saveReportResult.success || saveReportResult.error) {
      savedReportDataItems({
        savedAnswers: [],
      });
      latestSavedReportDataItems({
        savedAnswers: [],
      });
      doLogout(oktaAuth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportResult.success, saveReportResult.error]);

  const HandleCloseModal = () => {
    timer.activate();
    lockReport();
    recordRumCustomEvent(RumCustomEvent.timeoutModalDismiss, getRumAttributes());
  };

  const HandleLogout = (state: string) => {
    unlockReport();
    recordRumCustomEvent(RumCustomEvent.timeout, getRumAttributes());
    doLogout(oktaAuth, state);
  };

  if (timerState === 'Idle') HandleLogout(TIMEOUT_PARAM);

  return (
    <Modal
      isOpen={timerState === 'Prompted'}
      style={customStyles}
      className="inactivity"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      testId="time-out"
      aria={{ labelledby: 'time-out-alert-button-group', modal: true }}
    >
      <CustomAmplifyTheme aria-modal="true" isModal>
        <div>
          <Image testId="clock-icon" className="clock-icon" src={ClockIcon} alt="Clock Icon" />
        </div>
        <Heading testId="inactivity-heading" level={1} className="heading">
          Are you still working?
        </Heading>
        <Text testId="inactivity-content" className="content">
          We've noticed you've been inactive and your session will expire soon for your security
        </Text>
        <div data-testid="clock" className="clock">
          <CountdownClock timer={timer} promptTimeout={promptTimeout / 1000} />
        </div>
        <Flex id="time-out-alert-button-group" justifyContent="center">
          <Button
            testId="save-and-log-out-btn"
            data-fullwidth="true"
            variation="link"
            className="save-and-log-out-btn"
            onClick={() => HandleSaveAndLogout()}
          >
            {isInReport ? 'Save and log out' : 'Log out'}
          </Button>
          <Button data-fullwidth="true" testId="keep-working-btn" variation="primary" onClick={HandleCloseModal}>
            Keep working
          </Button>
        </Flex>
        {inlineError && <InlineError styles="time-out-alert-inline-error" />}
      </CustomAmplifyTheme>
    </Modal>
  );
};

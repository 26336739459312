import { gql } from '@apollo/client';

export const GET_USER_PROVIDER_REPORTS = gql`
  query GetUserProviderReports {
    getUserProfile {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
      user {
        userId
        accessDetails {
          dsp
          organisationDetails {
            dspBusinessName
            organisationId
            role
            providerDetails {
              pegaProviderId
              name
              address {
                suburb
                state
                postcode
              }
              currentPEGAABN
              division
            }
          }
          providerReports {
            userReportId
            name
            spRefPeriodStartDate
            spRefPeriodEndDate
            dueDate
            accessEndDate
            status
            singleUserAccess {
              lock
              selfLock
            }
            version
            lastEditedDate
          }
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_DSP_ACCESS_TOKEN = gql`
  query GetDSPAccessToken($dsp: String, $authCode: String) {
    getDSPAccessToken(dsp: $dsp, authCode: $authCode) {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
      newOrganisations {
        businessName
        organisationId
      }
    }
  }
`;

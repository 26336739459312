/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { Rating, Dialog } from '@mui/material';
import { Flex, Heading } from '@aws-amplify/ui-react';
import { useMutation, useReactiveVar } from '@apollo/client';

import ReportButtonGroup from 'components/ReportComponents/ReportButtonGroup/ReportButtonGroup';
import { InlineError } from 'components/InlineError/InlineError';
import TopicQuestions from 'components/ReportComponents/TopicQuestions/TopicQuestions';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { SUBMIT_REPORT } from 'apollo/mutations/submitReport';
import {
  DataItem,
  SavedActionNumber,
  SavedReportDataItem,
  SubmitReportResponse,
  ReportDataItem,
  SaveReportArgs,
} from 'models';
import { getSaveReportOptions } from 'utils/getSaveReportOptions';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import { recordRumError } from 'services/awsRum';

import { ReactComponent as SvgGoldStar } from 'assets/icon-star-gold.svg';
import { ReactComponent as SvgGreyStar } from 'assets/icon-star-grey.svg';

import './Feedback.css';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { withNonStarFeedbackReverted } from 'utils/savedReportDataItemsUtils';
import { TopicName } from 'models/LocalState/TopicFullDetail';
import { isSubmittingReport } from 'apollo/states/operationsInProgress';

const labels: { [index: string]: string } = {
  1: 'Bad',
  2: 'Poor',
  3: 'Okay',
  4: 'Good',
  5: 'Excellent',
};

const starWidth = 66;

/* istanbul ignore next */
const getOffset = (selection: number): number => {
  let offset = 0;
  switch (selection) {
    case 1: // 'Bad'
      offset = -4;
      break;
    case 2: // 'Poor'
      offset = -5;
      break;
    case 3: // 'Okay'
      offset = -6;
      break;
    case 4: // 'Good'
      offset = -5;
      break;
    case 5: // 'Excellent'
      offset = -14;
      break;
    default:
      offset = 0;
      break;
  }
  return offset;
};

interface FeedbackProps {
  feedbackDataItem: DataItem;
  pdfButtonRef?: React.RefObject<HTMLButtonElement>;
}
const Feedback = (props: FeedbackProps) => {
  const { feedbackDataItem, pdfButtonRef } = props;
  const [inlineError, setInlineError] = useState(false);

  const savedValues = useReactiveVar(savedReportDataItems);

  const [ratingValue, setRatingValue] = useState<number | undefined>(undefined);
  const [ratingHover, setRatingHover] = useState(-1);
  const [ratingLocked, setRatingLocked] = useState(false);

  const currentSelection = ratingHover !== -1 ? ratingHover : ratingValue!;

  const [notificationOpen, setNotificationOpen] = useState(false);

  // Add or update the local feedback item on change of selection value (hover or click/press)
  useEffect(() => {
    const savedItem = savedValues.savedAnswers.find((answer) => answer.dataItemId === feedbackDataItem.dataItemId);
    if (savedItem) {
      const updatedSavedAnswers = savedValues.savedAnswers.map((answer) =>
        answer.dataItemId === feedbackDataItem.dataItemId
          ? {
              ...savedItem,
              savedReportDataItem: { ...savedItem.savedReportDataItem, savedNumber: currentSelection },
            }
          : answer
      );
      savedReportDataItems({ savedAnswers: updatedSavedAnswers });
    } else {
      const savedActionNumber: SavedActionNumber = {
        savedNumber: currentSelection,
        responseUid: `ASP-${crypto.randomUUID()}`,
      };
      const newReportDataItem: SavedReportDataItem = {
        dataItemId: feedbackDataItem.dataItemId,
        isCalculated: false,
        savedReportDataItem: savedActionNumber,
        topic: 'Feedback',
        accountClass: '',
        overwrite: false,
      };
      savedReportDataItems({
        savedAnswers: [...savedValues.savedAnswers, newReportDataItem],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection]);

  const [modalOpen, setModalOpen] = useState(false);

  const [callSubmit, submitReportResult] = useMutation<SubmitReportResponse>(SUBMIT_REPORT, {
    onCompleted: () => isSubmittingReport(false),
    onError: (error) => {
      recordRumError(error);
      isSubmittingReport(false);
    },
  });

  const getRollupDataItems = (saveReportArgs: SaveReportArgs) => {
    // Get the feedback rollup data items for submission
    const feedbackRollup = {
      topics: {
        ASPFeedback: {
          userInputs: saveReportArgs.reportDataObject?.reportDataItems?.map((item) => item.dataItemId),
        },
      },
    };

    return {
      // hard-coded, for use by SADE
      dataItemId: '-2',
      responseUid: `ASP-${crypto.randomUUID()}`,
      userValue: JSON.stringify(feedbackRollup),
    };
  };

  const getOptionsForSubmit = (componentName: string) => {
    // Add feedback in format for onsending
    const saveReportOptions = getSaveReportOptions(componentName);
    const rollupDataItems: ReportDataItem = getRollupDataItems(saveReportOptions.variables);
    // Need to add rollups to start of array due to BE logic check for '-1' or '-2' at [0]
    saveReportOptions.variables.reportDataObject?.reportDataItems?.unshift(rollupDataItems);
    return saveReportOptions;
  };

  const handleSubmit = async () => {
    setInlineError(false);
    try {
      isSubmittingReport(true);
      await callSubmit(getOptionsForSubmit('Submit Button'));
    } catch (error) {
      setInlineError(true);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (cancelClicked) return;
    if (
      submitReportResult?.error ||
      !!submitReportResult.data?.submitReport?.errorMessages?.length ||
      (submitReportResult?.data && !submitReportResult?.data?.submitReport?.success)
    ) {
      setInlineError(true);
    }
    if (submitReportResult?.data && submitReportResult?.data.submitReport?.success) {
      setModalOpen(false);
      setRatingLocked(true);
      setNotificationOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitReportResult?.error, submitReportResult?.data]);

  const [cancelClicked, setCancelClicked] = useState(false);

  const handleCancel = () => {
    const localSaved = savedReportDataItems();
    const serverSaved = latestSavedReportDataItems();
    const newLocalSaved = withNonStarFeedbackReverted(localSaved, serverSaved);
    if (newLocalSaved !== localSaved) savedReportDataItems(newLocalSaved);

    setCancelClicked(true);
    callSubmit(getOptionsForSubmit('handle Cancel'));
    setModalOpen(false);
    setRatingLocked(true);
  };

  useEffect(() => {
    if (!modalOpen && pdfButtonRef) {
      pdfButtonRef.current?.focus();
    }
  }, [pdfButtonRef, modalOpen]);

  return (
    <>
      <Heading level={2} className="feedback-header" testId="feedback-header">
        {feedbackDataItem.dataItemText}
      </Heading>
      <Rating
        name="rating-feedback"
        data-testid="feedback-star-component"
        onChange={(_, newValue) => setRatingValue(newValue ?? undefined)}
        onChangeActive={(_, newHover) => setRatingHover(newHover)}
        onMouseUp={() => setModalOpen(true)}
        onKeyUp={(event) => {
          if (event.code === 'Enter' && !!ratingValue) setModalOpen(true);
        }}
        icon={<SvgGoldStar />}
        emptyIcon={<SvgGreyStar />}
        sx={{ width: 'fit-content' }}
        readOnly={ratingLocked}
        getLabelText={(value) => `${labels[value]}, ${value} Star${value === 1 ? '' : 's'}`}
      />
      <p
        data-testid="feedback-label"
        className="feedback-label"
        style={{
          marginLeft: (currentSelection - 1) * starWidth + getOffset(currentSelection) || 0,
          backgroundColor: currentSelection > 0 ? '' : '#FFF',
        }}
      >
        {labels[currentSelection]}
      </p>
      <Dialog
        open={modalOpen}
        disableEscapeKeyDown
        maxWidth="md"
        fullWidth
        className="modal-container asp-feedback-modal"
        onClose={handleCancel}
        PaperProps={{
          sx: {
            padding: '78px 97px 51px 57px',
            borderRadius: '16px',
            boxShadow: '0px 20px 24px -4px rgba(0, 0, 0, 0.1), 0px 8px 8px -4px rgba(0, 0, 0, 0.04)',
          },
          'aria-labelledby': 'modal-heading',
        }}
        BackdropProps={{ sx: { backgroundColor: 'rgba(75, 79, 84, 0.17)' } }}
      >
        <CustomAmplifyTheme isModal>
          <Heading level={3} id="modal-heading" className="modal-heading" testId="modal-heading" marginBottom="30px">
            Share your thoughts
          </Heading>
          {/* <Text className=" ">Tell us what you liked or where we can improve</Text> */}
          <TopicQuestions topicType={'ASPFeedback' as TopicName} />
          <Flex direction="column">
            <ReportButtonGroup
              nextButton={{
                link: '',
                text: 'Share feedback',
                Callback: handleSubmit,
                isLoading: !cancelClicked && submitReportResult.loading,
                loadingText: 'Loading...',
              }}
              backButton={{ link: '', text: 'Cancel', Callback: handleCancel }}
            />
            {inlineError && <InlineError styles="inline-error-feedback" />}
          </Flex>
        </CustomAmplifyTheme>
      </Dialog>
      <ToastNotification
        success
        open={notificationOpen}
        title="Thank you"
        text="Your feedback helps us improve the report"
      />
    </>
  );
};

export default Feedback;

import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { AbsUserClaims } from './authConfig';

export const useUserInfo = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<AbsUserClaims | null>(null);

  useEffect(() => {
    if (authState && authState.isAuthenticated && authState.idToken) {
      setUserInfo(authState.idToken.claims);
    } else {
      setUserInfo(null);
    }
  }, [authState, oktaAuth]);

  return userInfo;
};

import { useState } from 'react';
import { Button, VisuallyHidden } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import { TooltipProps, Tooltip, tooltipClasses } from '@mui/material';

import './InputLabelTooltip.css';

export const InputLabelTooltip = styled(
  ({ className, helpTextFor, ...tooltipProps }: Omit<TooltipProps, 'children'> & { helpTextFor: string }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
      <Tooltip
        {...tooltipProps}
        open={showTooltip}
        onClick={() => setShowTooltip((prev) => !prev)}
        disableHoverListener
        classes={{ popper: className }}
        className="custom-tooltip"
      >
        <Button className={`${showTooltip ? 'selected' : ''} input-label-tooltip-button`} testId="tooltip-icon">
          <VisuallyHidden>help text for {helpTextFor}</VisuallyHidden>
        </Button>
      </Tooltip>
    );
  }
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    fontSize: 16,
    fontFamily: 'Open Sans',
    textAlign: 'center',
    maxWidth: 400,
    borderRadius: 8,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '15px 33px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      color: '#fff',
      filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))',
    },
  },
}));

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, VisuallyHidden } from '@aws-amplify/ui-react';

import { resetLastSavedTimer } from 'apollo/states/SaveTimer';
import { savingLocalAnswersStatus } from 'apollo/states/operationsInProgress';
import { InlineError } from 'components/InlineError/InlineError';
import { getSaveReportOptions } from 'utils/getSaveReportOptions';
import { isMac } from 'utils/detectOS';
import { useSaveReport } from 'utils/useSaveReport';

import './ReportButtonGroup.css';

interface ButtonProps {
  link: string;
  text: string;
  useSave?: boolean;
  Callback?: () => void;
  isLoading?: boolean;
  loadingText?: string;
}

interface ReportButtonGroupProps {
  nextButton: ButtonProps;
  backButton?: ButtonProps;
}

const ReportButtonGroup = ({ nextButton, backButton }: ReportButtonGroupProps) => {
  const navigate = useNavigate();
  const [inlineError, setInlineError] = useState(false);
  const [buttonLoader, setButtonLoader] = useState('');
  const [navigateLink, setNavigateLink] = useState('');
  // For screen reader loading announcement, until button component handles it properly
  const [announceLoading, setAnnounceLoading] = useState(false);

  // FIXME: clicking back then continue rapidly can cause being stuck forever?
  const [callSaveReport, saveReportResponse] = useSaveReport('ReportButtonGroup');

  const handleOnClick = (clickedButton: string, link: string, useSave?: boolean) => {
    if (useSave) {
      let uiContext = 'unknown button';
      if (clickedButton === 'back-button') {
        setButtonLoader('back');
        uiContext = backButton?.text ?? 'Back';
      } else if (clickedButton === 'next-button') {
        setButtonLoader('next');
        uiContext = nextButton?.text ?? 'Save and Continue';
      }
      setInlineError(false);
      setNavigateLink(link);

      // Short-cut the save if there is nothing to save. useSaveReport does this too, but this is a quicker refactor.
      const saveReportOptions = getSaveReportOptions(uiContext, true);
      if (saveReportOptions.variables.reportDataObject.reportDataItems?.length) {
        savingLocalAnswersStatus('saving');
        // we want to lock the report as we are making changes.
        callSaveReport('handleOnClick', uiContext, true);
      } else {
        if (clickedButton === 'next-button') resetLastSavedTimer();
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (saveReportResponse.error) {
      setInlineError(true);
      savingLocalAnswersStatus('error');
    }
  }, [saveReportResponse.error]);

  useEffect(() => {
    if (saveReportResponse.success) navigate(navigateLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportResponse.success]);

  const isBackLoading = backButton
    ? backButton.isLoading || (saveReportResponse.loading && buttonLoader === 'back')
    : false;
  const isNextLoading = nextButton.isLoading || (saveReportResponse.loading && buttonLoader === 'next');

  const isLoading = isBackLoading || isNextLoading;
  useEffect(() => {
    // Add add small delay to announcing loading state, as recommended in accessibility report
    const timeoutHandle = setTimeout(() => setAnnounceLoading(isLoading), 200);
    return () => clearTimeout(timeoutHandle);
  }, [isLoading]);

  const loadingText =
    (isNextLoading && (nextButton.loadingText || 'Loading...')) ||
    (isBackLoading && (backButton?.loadingText || 'Loading...'));

  // Callbacks are un-bound while loading since only aria-disabled is used (for accessibility) and not disabled
  // not loading.
  const onBackClick =
    !backButton || isBackLoading
      ? undefined
      : backButton.Callback ?? (() => handleOnClick('back-button', backButton.link, backButton.useSave));
  const onNextClick =
    !nextButton || isNextLoading
      ? undefined
      : nextButton.Callback ?? (() => handleOnClick('next-button', nextButton.link, nextButton.useSave));

  return (
    <Flex direction="column" alignItems="flex-end">
      <Flex className="report-button-group-wrapper">
        {backButton && (
          <Button
            className="report-button-group-button"
            testId="report-button-back"
            onClick={onBackClick}
            isLoading={isBackLoading}
            loadingText={backButton.loadingText || 'Loading...'}
            isDisabled={false}
            aria-disabled={isBackLoading}
          >
            {backButton.text}
          </Button>
        )}
        <Button
          variation="primary"
          className="report-button-group-button"
          testId="report-button-next"
          onClick={onNextClick}
          isLoading={isNextLoading}
          loadingText={nextButton.loadingText || 'Loading...'}
          isDisabled={false}
          aria-disabled={isNextLoading}
        >
          {nextButton.text}
        </Button>
      </Flex>
      {inlineError && <InlineError />}
      {!isMac && <VisuallyHidden role="status">{announceLoading && <span>{loadingText}</span>}</VisuallyHidden>}
    </Flex>
  );
};
export default ReportButtonGroup;

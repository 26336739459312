/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMutation } from '@apollo/client';
import { LOCK_USER_REPORT } from 'apollo/mutations/lockUserReport';
import { chosenReport } from 'apollo/states/ChosenReport';

interface ReportDataProps {
  dspProvider?: string;
  organisationId?: string;
  userReportId?: string;
}

export const useSetReportLock = (action: 'lock' | 'unlock', reportData?: ReportDataProps) => {
  const reportDetails = chosenReport();
  const variables = {
    dspProvider: reportData?.dspProvider ?? reportDetails?.dspProvider,
    organisationId: reportData?.organisationId ?? reportDetails?.organisationId,
    userReportId: reportData?.userReportId ?? reportDetails?.userReportId,
    lock: action === 'lock',
  };

  const [callTrigger] = useMutation(LOCK_USER_REPORT, { variables });
  return callTrigger;
};

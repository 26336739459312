/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { Card, Flex } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import { savingLocalAnswersStatus } from 'apollo/states/operationsInProgress';

import { ReactComponent as TickIconSvg } from 'assets/icon-tick-save.svg';
import { ReactComponent as SavingIconSvg } from 'assets/icon-loading-save.svg';
import { ReactComponent as CrossIconSvg } from 'assets/icon-x-save.svg';

import './ReportSaveStatus.css';

interface SaveStatus {
  text: string;
  color: string;
  background: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

// Default status is blank to reserve height of card to avoid layout shift when rendering other statuses
const defaultStatus: SaveStatus = { text: '', color: '', background: '' };
const savingStatus: SaveStatus = { text: 'Saving', color: '#326297', background: '#F3F7FB', icon: SavingIconSvg };
const savedStatus: SaveStatus = { text: 'Saved', color: '#326297', background: '#F3F7FB', icon: TickIconSvg };
const notSavedStatus: SaveStatus = { text: 'Not saved', color: '#DC2626', background: '#FEF2F2', icon: CrossIconSvg };

const ReportSaveStatus = () => {
  const [status, setStatus] = useState<SaveStatus>(defaultStatus);

  const saveStatus = useReactiveVar(savingLocalAnswersStatus);

  useEffect(() => {
    switch (saveStatus) {
      case 'idle':
        setStatus(defaultStatus);
        break;
      case 'saving':
        setStatus(savingStatus);
        break;
      case 'saved':
        setStatus(savedStatus);
        break;
      case 'error':
        setStatus(notSavedStatus);
        break;
      default:
        setStatus(defaultStatus);
    }
  }, [saveStatus]);

  return (
    <Card
      className="report-save-status-container"
      testId="report-save-status-container"
      color={status.color}
      backgroundColor={status.background}
    >
      <Flex
        testId="report-save-status-text"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        height="100%"
        aria-live="polite"
      >
        {status.icon && (
          <status.icon
            className={status === savingStatus ? 'saving-rotate-icon' : ''}
            data-testid="report-save-status-icon"
          />
        )}
        {status.text}
      </Flex>
    </Card>
  );
};

export default ReportSaveStatus;

import { UserContactDetails } from 'models';
import makeVarPersisted from 'apollo/MakeVarPersisted';

export const UserContactInitialState: UserContactDetails = {
  firstName: '',
  lastName: '',
  email: 'user@email.com', // TODO: remove once we get this info from BE
  mobile: '',
  role: '',
};

export const userContactDetails = makeVarPersisted<UserContactDetails>(
  UserContactInitialState,
  'aspUserContactDetails'
);

import { gql } from '@apollo/client';

export const SET_CONTACT_DETAILS = gql`
  mutation setContactDetails($saveContactDataObject: SaveContactDataObject) {
    setContactDetails(saveContactDataObject: $saveContactDataObject) {
      success
      errorMessages {
        language
        message
        errorCode
        metaData
        errorType
        stack
        time
      }
    }
  }
`;

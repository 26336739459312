import { Text } from '@aws-amplify/ui-react';
import './InlineError.scss';

interface Props {
  styles?: string;
  errorMessage?: string;
}
export const InlineError: React.FC<Props> = ({ styles, errorMessage }) => (
  <Text testId="inline-error" className={`${styles} inline-error`} aria-live="assertive">
    {!errorMessage ? (
      <>
        <span>Sorry, that button isn't working.</span> <span>Please try again later.</span>
      </>
    ) : (
      errorMessage
    )}
  </Text>
);

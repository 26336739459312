import { Button, Card, Flex, Grid, Image, Link, Text, useTheme, View } from '@aws-amplify/ui-react';
import { useOktaAuth } from '@okta/okta-react';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { TextComponent } from 'components/TextComponent/Text';
import { TIMEOUT_PARAM } from 'components/UserInactivity/InactivityModal';
import { useSearchParams, useLocation } from 'react-router-dom';
import HeroImage from 'assets/homepage-hero.jpg';
import { ReactComponent as ABSLogo } from 'assets/logo-ABS.svg';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { content } from './content';

import './Homepage.scss';

interface ErrorRedirect {
  OKTAVERIFIED_PARAM?: boolean;
}

const Homepage = () => {
  const { oktaAuth } = useOktaAuth();
  const { tokens } = useTheme();
  const [searchParams] = useSearchParams();
  const timeout = searchParams.get('state') === TIMEOUT_PARAM;
  const location = useLocation();
  const oktaUnverifiedRedirect: ErrorRedirect = location.state as ErrorRedirect;

  return (
    <Card testId="homepage" className="homepage-wrapper">
      <Grid className="container-grid" gap={tokens.space.medium} templateColumns="repeat(12, 1fr)">
        <View columnStart="1" columnEnd="7">
          {timeout && (
            <div className="time-out-alert">
              <AlertComponent title={content.timeOut.title} variation="timeOut" listItem={content.timeOut.listItem} />
            </div>
          )}
          {oktaUnverifiedRedirect?.OKTAVERIFIED_PARAM && (
            <div className="oktaUnverified">
              <AlertComponent
                title={content.oktaUnverified.title}
                variation="error"
                textHeaderComponent={
                  <>
                    You were unable to log in with your accounting software due to an earlier attempt at creating an ABS
                    account.
                    <br />
                    <br />
                    Please click the activation link you received in an email, or press ‘Log in’ below and enter your
                    email to continue.
                  </>
                }
                alertProps={{ isDismissible: true }}
              />
            </div>
          )}

          <Card className="homepage-content">
            <View
              className={timeout ? 'abs-logo-with-alert ' : 'abs-logo'}
              ariaLabel="Australian Bureau of Statistics Logo"
              testId="homepage-logo"
              height="135px"
              width="152px"
            >
              <ABSLogo width="152px" height="135px" />
            </View>
            <TextComponent
              heading={content.heading}
              summary={content.summary}
              list={content.list}
              uniqueId="homepage-content"
            />
            <Flex className={timeout ? 'homepage-btn-group-with-alert ' : 'homepage-btn-group'} justifyContent="center">
              <Button
                variation="link"
                testId="button-signup"
                onClick={() => {
                  oktaAuth.signInWithRedirect({
                    originalUri: '/authorisation',
                    extraParams: {
                      flow: 'signup',
                    },
                  });
                }}
              >
                Sign up
              </Button>
              <Button
                className="homepage-login-btn"
                variation="primary"
                testId="button-login"
                onClick={() => oktaAuth.signInWithRedirect({ originalUri: '/authorisation' })}
              >
                Log in
              </Button>
            </Flex>
            <Text testId="text-data" className={timeout ? 'homepage-footer-with-alert ' : 'homepage-footer'}>
              If you want to know more about how we use your data visit our{' '}
              <Link
                data-testid="link-privacy"
                isExternal
                href={content.linkPrivacy}
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.privacyAtAbsClicked, getRumAttributes())}
              >
                Privacy at the ABS
              </Link>{' '}
              and{' '}
              <Link
                data-testid="link-data"
                isExternal
                href={content.linkDataUsage}
                onClick={() =>
                  recordRumCustomEventWithPageId(RumCustomEvent.dataReportingUsageClicked, getRumAttributes())
                }
              >
                ABS Business Reporting Data Usage
              </Link>{' '}
              pages
            </Text>
            <Text testId="text-contact">
              Need help?{' '}
              <Link
                data-testid="link-contact"
                isExternal
                href={content.linkContact}
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
              >
                Contact us!
              </Link>
            </Text>
          </Card>
        </View>
        <View columnStart="7" columnEnd="13">
          <Image
            testId="hero-image"
            className="hero-image"
            src={HeroImage}
            alt="A man in an apron standing and typing on a laptop next to an open notebook in a workshop"
          />
        </View>
      </Grid>
    </Card>
  );
};

export default Homepage;

import { ChosenReport, ReportDataItem, ReportDataObject, SavedReportDataItems, SavedReportDataItem } from 'models';

// Check the type of the SavedAction by checking the properties, then creating a ReportDataItem to add to the saveReport Payload
const getSavedActionAnswer = (savedAnswer: SavedReportDataItem): ReportDataItem[] => {
  const reportDataItems: ReportDataItem[] = [];
  if ('savedNumber' in savedAnswer.savedReportDataItem) {
    const userValue =
      savedAnswer.savedReportDataItem.savedNumber !== undefined
        ? savedAnswer.savedReportDataItem.savedNumber.toString()
        : '';

    const reportDataItem: ReportDataItem = {
      dataItemId: savedAnswer.dataItemId,
      delete: savedAnswer.delete,
      mapState: false,
      overwrite: savedAnswer.overwrite,
      userValue,
      responseUid: savedAnswer.savedReportDataItem.responseUid,
      type: savedAnswer.action,
    };

    reportDataItems.push(reportDataItem);
  } else if ('savedText' in savedAnswer.savedReportDataItem) {
    const reportDataItem: ReportDataItem = {
      dataItemId: savedAnswer.dataItemId,
      delete: savedAnswer.delete,
      mapState: false,
      overwrite: savedAnswer.overwrite,
      userValue: savedAnswer.savedReportDataItem.savedText,
      responseUid: savedAnswer.savedReportDataItem.responseUid,
      type: savedAnswer.action,
    };

    reportDataItems.push(reportDataItem);
  } else if ('selectedReportDataItems' in savedAnswer.savedReportDataItem) {
    savedAnswer.savedReportDataItem.selectedReportDataItems.forEach((selectedReportDataItem) => {
      const mappedAnswer: ReportDataItem = {
        accountName: selectedReportDataItem.accountName,
        class: selectedReportDataItem.class,
        dataItemId: savedAnswer.dataItemId,
        dspDateRead: selectedReportDataItem.dspDateRead,
        dspValue: selectedReportDataItem.dspValue?.toString(),
        mapState: !!selectedReportDataItem.dspValue,
        responseUid: selectedReportDataItem.responseUid,
        isActive: selectedReportDataItem.isActive,
        type: selectedReportDataItem.type,
        userValue: selectedReportDataItem.userValue?.toString(),
        delete: selectedReportDataItem.delete,
        overwrite: selectedReportDataItem.overwrite,
        accountCode: selectedReportDataItem.code,
      };
      reportDataItems.push(mappedAnswer);
    });
  }

  return reportDataItems;
};

export const mapLocalToGraphQL = (
  localSavedData: SavedReportDataItems,
  chosenReportData: ChosenReport,
  generatePdfUrl: boolean
): ReportDataObject => {
  const graphQLPayload: ReportDataObject = {
    dspABN: chosenReportData.dspAbn || '',
    generatePDFUrl: generatePdfUrl,
    reportDataItems: [],
    reviewStatus: false,
    userReportId: chosenReportData.userReportId || '',
  };

  localSavedData.savedAnswers.forEach((savedAnswer) => {
    const savedAnswers = getSavedActionAnswer(savedAnswer);

    savedAnswers.forEach((item) => {
      graphQLPayload.reportDataItems?.push(item);
    });
  });

  return graphQLPayload;
};

import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { GET_REPORT_DATA_INITIAL_LOAD } from 'apollo/queries/getReportDataInitialLoad';
import { GET_REPORT_PDF } from 'apollo/queries/getReportPDF';
import { evictGetReportFromCache } from 'apollo/states/utils/ClearReportStateData';
import { ChosenReport, GetReportResponse } from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { recordRumError } from 'services/awsRum';

/**
 *
 * @param reportData
 * @param preCache true to automatically start a silent getReport in the background
 * @returns
 */
const useGetReport = (
  reportData: ChosenReport,
  preCache = false,
  includeDspDataItems = true
): readonly [
  () => Promise<QueryResult<GetReportResponse, OperationVariables>>,
  QueryResult<GetReportResponse, OperationVariables>,
  () => void
] => {
  const getReportQuery = includeDspDataItems ? GET_REPORT_DATA_INITIAL_LOAD : GET_REPORT_PDF;
  const [callGetReport, getReportResult] = useLazyQuery<GetReportResponse>(getReportQuery, {
    // cache-first is default, included to make it obvious this will use the cached value if available
    fetchPolicy: 'cache-first',
    variables: reportData,
    onError: (error) => recordRumError(error),
  });

  /**
   * Whether caching mode is active. Active by default for preCache reports, turns off
   * when the consumer executes callGetReport, can be turned back on with setGetReportCaching
   */
  const [cachingMode, setCachingMode] = useState(preCache);

  /**
   * Unique number for wrapper promise. Only the current wrapper promise
   * should pass on results from its internal promise, and this is used
   * for that check.
   */
  const currentWrapperPromiseId = useRef(0);

  // Async return value of callGetReport, to forward when the consuming component has called the query
  const [queryPromise, setQueryPromise] = useState<Promise<QueryResult<GetReportResponse, OperationVariables>>>();

  // Initial state to return until the consumer tries to call
  // Exhaustive deps ignored because we specifically don't want to pick up a new value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultQueryObject: QueryResult<GetReportResponse, OperationVariables> = useMemo(() => getReportResult, []);

  // Run query early if pre-cache is needed
  useEffect(() => {
    if (preCache) {
      setQueryPromise(callGetReport());
    }
  }, [preCache, callGetReport]);

  const loaded = getReportResult.called && !getReportResult.loading;
  const errored = loaded && (getReportResult.error || !getReportResult.data?.getReport.success);

  // Ensure failed responses will not be used when re-query is attempted
  useEffect(() => {
    if (errored) {
      evictGetReportFromCache(reportData);
      setQueryPromise(undefined);
    }
  }, [reportData, loaded, errored]);

  // Makes a promise that will only resolve if caching mode is off
  // (this is to prevent report launch being triggered when another report has more recently been launched)
  const nonCachingModePromise = (promise: Promise<QueryResult<GetReportResponse, OperationVariables>>) => {
    const wrapperPromise = new Promise<QueryResult<GetReportResponse, OperationVariables>>((resolve, reject) => {
      // Set up a unique ID for this wrapper, to only execute if it matches
      const prevPromiseId = currentWrapperPromiseId.current;
      const promiseId = prevPromiseId + 1;
      currentWrapperPromiseId.current = promiseId;

      promise
        .then((...args) => {
          // don't resolve expired promise wrappers
          if (promiseId !== currentWrapperPromiseId.current) return;
          resolve(...args);
        })
        .catch((...args) => {
          // don't reject expired promise wrappers
          if (promiseId !== currentWrapperPromiseId.current) return;
          // Passing through a reject value, so no way to force a type.
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(...args);
        });
    });

    return wrapperPromise;
  };

  /**
   * Wrapped version of callGetReport that handles switching caching mode off
   * and logic to trigger a new call only if caching mode was not on when called.
   */
  const wrappedCallGetReport = useCallback(() => {
    if (cachingMode) {
      // Switch out of caching mode to start giving real responses instead of default
      setCachingMode(false);

      // When switching out of caching mode, if pre-caching is in-progress or successful,
      // return the promise for the pre-cache getReport call
      if (typeof queryPromise !== 'undefined' && !errored) {
        return nonCachingModePromise(queryPromise);
      }
    }

    // Otherwise we need a new getReport call
    const newQueryPromise = callGetReport();
    setQueryPromise(newQueryPromise);
    return nonCachingModePromise(newQueryPromise);
  }, [cachingMode, callGetReport, queryPromise, errored]);

  /**
   * Sets this getReport back to 'pre-cache' mode if it wasn't already
   * pre-caching. Any active get will behave as though it is a pre-cache
   * get except for any use of the promise that was already returned.
   *
   * I may be able to fix that promise thing by always returning a wrapped
   * promise so I can control the response from the actual promise.
   */
  const setGetReportCaching = () => {
    setCachingMode(true);

    // Any in-progress operations should be ignored, so promise ID is advanced
    // so it won't match the current promise if any
    currentWrapperPromiseId.current += 1;
  };

  const queryObject = cachingMode ? defaultQueryObject : getReportResult;

  return [wrappedCallGetReport, queryObject, setGetReportCaching] as const;
};

export default useGetReport;

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState, useEffect } from 'react';
import { Grid, TextField } from '@aws-amplify/ui-react';

import { allAllowedCharsRegex } from 'utils/CharacterValidation';
import { SavedActionListNumericItem } from 'models';
import { formatValueString, stripCommas } from '../TopicQuestions/TopicQuestions';

const amountRegex = /^(-?\d*)$/;

const errors = {
  nameMissing: 'Item name is required',
  amountMissing: 'Amount is required',
  invalidName: 'Only alphanumeric and standard special characters allowed',
  invalidAmount: 'Whole numbers only',
};

interface RemainingInputNoDspProps {
  nameLabel: string;
  savedAnswer: SavedActionListNumericItem | undefined;
  uidSuffix: string;
  updateAnswerCallback: (newAccount: SavedActionListNumericItem) => void;
  addAnswerCallback: (newAccount: SavedActionListNumericItem) => void;
}

const RemainingInputNoDsp = ({
  nameLabel,
  savedAnswer,
  uidSuffix,
  updateAnswerCallback,
  addAnswerCallback,
}: RemainingInputNoDspProps) => {
  const [nameInput, setNameInput] = useState<string | undefined>(savedAnswer?.accountName ?? '');
  const [amountInput, setAmountInput] = useState<string | undefined>(savedAnswer?.userValue ?? '');

  const [isActive, setIsActive] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [amountError, setAmountError] = useState<boolean>(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState<string>('');

  const [isNegative, setIsNegative] = useState(false);

  useEffect(() => {
    if (parseInt(amountInput ?? '0') < 0 || amountInput === '-') setIsNegative(true);
    else setIsNegative(false);
  }, [amountInput]);

  const handleSetNameError = (message: string) => {
    setNameErrorMessage(message);
    setNameError(true);
  };

  const handleSetAmountError = (message: string) => {
    setAmountErrorMessage(message);
    setAmountError(true);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameError(false);
    const { value } = event.target;

    if (!value.match(allAllowedCharsRegex)) return handleSetNameError(errors.invalidName);
    if (value === '' && amountInput !== '') handleSetNameError(errors.nameMissing);
    if (value !== '' && amountInput === '') handleSetAmountError(errors.amountMissing);

    setNameInput(value);

    if ((value === '' && amountInput === '') || (value !== '' && amountInput !== ''))
      updateSavedAnswer({ accountName: value });
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmountError(false);
    let userValue = event.target.value;

    if (userValue.charAt(userValue.length - 1) === ',') {
      handleSetAmountError(errors.invalidAmount);
    }

    userValue = stripCommas(userValue).substring(0, 12);

    if (!userValue.match(amountRegex)) return handleSetAmountError(errors.invalidAmount);
    if (userValue === '-') handleSetAmountError(errors.invalidAmount);
    if (userValue === '' && nameInput !== '') handleSetAmountError(errors.amountMissing);
    if (userValue !== '' && nameInput === '') handleSetNameError(errors.nameMissing);

    setAmountInput(userValue);

    if ((userValue === '' && nameInput === '') || (userValue !== '' && nameInput !== '')) {
      updateSavedAnswer(userValue === '-' ? { userValue: '' } : { userValue });
    }
  };

  const handleBlur = () => {
    setIsActive(false);

    if (nameInput === '' && amountInput === '') {
      setNameError(false);
      setAmountError(false);
      // Remove inline error triggered by invalid input
    } else if (amountInput !== '' && amountInput?.match(amountRegex) && amountInput !== '-') {
      setAmountError(false);
    }
  };

  const updateSavedAnswer = (currentValue: Pick<SavedActionListNumericItem, 'accountName' | 'userValue'>) => {
    const otherValue = currentValue.accountName ? amountInput : nameInput;
    // If we already have this saved item, then update it's values for name and account
    if (savedAnswer) {
      const updatedAnswer: SavedActionListNumericItem = {
        ...savedAnswer,
        accountName: nameInput,
        userValue: amountInput,
        ...currentValue,
      };
      updateAnswerCallback(updatedAnswer);
    } else if ((currentValue.accountName ?? currentValue.userValue) !== '' && otherValue !== '') {
      // Create a new answer
      const newAnswer: SavedActionListNumericItem = {
        responseUid: `ASP-${crypto.randomUUID()}-${uidSuffix}`,
        accountName: nameInput,
        userValue: amountInput,
        ...currentValue,
      };
      addAnswerCallback(newAnswer);
    }
  };

  const getPrefix = () => {
    if (isActive) return '$';
    return isNegative ? '-$' : '$';
  };

  const renderValue = (value: string | undefined) => {
    if (!value) return '';

    return formatValueString(isActive ? value : value.replace('-', ' '));
  };

  return (
    <Grid maxWidth="680px" gap="10px" templateColumns="1fr 1fr">
      <TextField
        className="report-input-field report-input-field"
        label={nameLabel}
        minWidth="450px"
        gap="8px"
        value={nameInput}
        onChange={handleNameChange}
        errorMessage={nameErrorMessage}
        hasError={nameError}
        onBlur={handleBlur}
        maxLength={50}
      />
      <TextField
        label="Amount"
        gap="8px"
        innerStartComponent={getPrefix()}
        value={renderValue(amountInput)}
        onChange={handleAmountChange}
        errorMessage={amountErrorMessage}
        hasError={amountError}
        onBlur={handleBlur}
        onFocus={() => {
          setIsActive(true);
        }}
        maxLength={15}
      />
    </Grid>
  );
};

export default RemainingInputNoDsp;

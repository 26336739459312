/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

const tokenStoreName = 'authSecret';

const getSecret = () => localStorage.getItem(tokenStoreName);

/**
 *
 * @returns Promise containing the token as a string
 */
export const getCsrfToken = async (): Promise<string> => {
  let newToken = '';
  const secret = getRandomString(16);
  localStorage.setItem(tokenStoreName, secret);
  newToken = await createToken(secret);
  return newToken;
};

/**
 *
 * @param token The token recieved from auth flow
 * @returns Promise containing the result of verification as a boolean
 */
export const verifyCsrfToken = async (token: string): Promise<boolean> => {
  const secret = getSecret();
  if (secret) return verifyToken(secret, token);
  return false;
};

const getRandomString = (length: number) => crypto.randomUUID().replaceAll('-', '').substring(0, length);

const createToken = async (secret: string): Promise<string> => tokenise(secret, getRandomString(10));

const verifyToken = async (secret: string, token: string): Promise<boolean> => {
  const index = token.indexOf('-');
  const salt = token.substring(0, index);
  const expected = await tokenise(secret, salt);
  return expected === token;
};

const tokenise = async (secret: string, salt: string): Promise<string> => {
  let hash = '';
  const result = await getHash(`${salt}-${secret}`);
  const hashArray = Array.from(new Uint8Array(result));
  hash = hashArray.map((x) => x.toString(16)).join('');
  return `${salt}-${hash}`;
};

const getHash = async (str: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hashed = await crypto.subtle.digest('SHA-256', data);
  return hashed;
};

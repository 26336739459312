import { PageRoutes } from 'enums/PageRoutes';
import { useCurrentReportTopic } from 'hooks/useCurrentReportTopic';
import { useMemo } from 'react';

export const usePageTitle = (pathname: string): string => {
  const topic = useCurrentReportTopic();
  const pageTitle = useMemo<string>(() => {
    const match = Object.values(PageRoutes).find((routeObj) => routeObj.pathname === pathname);

    if (match) return match.title;
    if (topic) return topic.topicShortTitle;
    return 'Error';
  }, [pathname, topic]);

  return pageTitle;
};

export const externalLinks = {
  absContactUs: 'https://www.abs.gov.au/participate-survey/business-reporting/troubleshooting',
  absDataPrivacySecurity: 'https://www.abs.gov.au/about/legislation-and-policy/privacy/privacy-abs',
  absBusinessReportingDataUsage: 'https://www.abs.gov.au/br/DataUsage',
  myobSsoInstructions: 'https://help.myob.com/wiki/display/ar/Open+a+company+file',
  absBusinessIndicators:
    'https://www.abs.gov.au/statistics/economy/business-indicators/business-indicators-australia/latest-release',
  absBusinessIndicatorsSales:
    'https://explore.data.abs.gov.au/vis?tm=Business%20indicators&pg=0&df[ds]=ECONOMY_TOPICS&df[id]=QBIS&df[ag]=ABS&df[vs]=1.0.0&pd=2022-Q1%2C&dq=M2.CUR..TOT.10.AUS.Q&hc[Economy]=Business%20indicators&lb=nm',
  absBusinessIndicatorsWages:
    'https://explore.data.abs.gov.au/vis?tm=Business%20indicators&pg=0&df[ds]=ECONOMY_TOPICS&df[id]=QBIS&df[ag]=ABS&df[vs]=1.0.0&pd=2022-Q1%2C&dq=M6.CUR..TOT.10.AUS.Q&ly[cl]=TIME_PERIOD&hc[Economy]=Business%20indicators&lb=nm',
  absBusinessIndicatorsProfit:
    'https://explore.data.abs.gov.au/vis?tm=Business%20indicators&pg=0&df[ds]=ECONOMY_TOPICS&df[id]=QBIS&df[ag]=ABS&df[vs]=1.0.0&pd=2022-Q1%2C&dq=M8.CUR..TOT.10.AUS.Q&ly[cl]=TIME_PERIOD&hc[Economy]=Business%20indicators&lb=nm',
  absBusinessIndicatorsSalesToWages:
    'https://explore.data.abs.gov.au/vis?tm=Business%20indicators&pg=0&df[ds]=ECONOMY_TOPICS&df[id]=QBIS&df[ag]=ABS&df[vs]=1.0.0&pd=2022-Q1%2C&dq=M12.CUR..TOT.10.AUS.Q&hc[Economy]=Business%20indicators&lb=nm',
  absBussinessIndicatorsMethodology:
    'https://www.abs.gov.au/statistics/economy/business-indicators/business-indicators-australia/latest-release#methodology',
  absDspConnectSrc: 'https://www.youtube-nocookie.com/embed/kCY3JDrJkZ8?rel=0',
};

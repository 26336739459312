import { SavedActionListNumeric, SavedReportDataItem } from 'models';
import { compareTopicStrings } from 'utils/CompareTopicStrings';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { TopicName, TopicFullDetail, getTopicByName } from 'models/LocalState/TopicFullDetail';
import { computeRuleTotal } from 'utils/parseTopicRule';
import { useReactiveVar } from '@apollo/client';
import { currentReportData } from '../CurrentReportData';

/**
 * Hook version of GetTotalByTopic that will update as total changes
 */
export const useTotalByTopic = (topic: TopicName, display = true): number => {
  const { topics } = useReactiveVar(currentReportData);
  const savedDataItems = useReactiveVar(savedReportDataItems);
  const total = getTotalFromSavedItemsByTopic(topic, topics, savedDataItems.savedAnswers);
  return display ? Math.round(total) : total;
};

/**
 * Add up the total for a given topic
 *
 * @param topic
 * @param display whether to round to a whole number for display
 * @returns
 */
export const GetTotalByTopic = (topic: TopicName, display = true): number => {
  const total = getTotalFromSavedItemsByTopic(topic, currentReportData().topics, savedReportDataItems().savedAnswers);
  return display ? Math.round(total) : total;
};

export const getTotalByTopicWithoutReactives = (
  topic: TopicName,
  topics: TopicFullDetail[],
  savedItems: SavedReportDataItem[],
  display = true
) => {
  const total = getTotalFromSavedItemsByTopic(topic, topics, savedItems);
  return display ? Math.round(total) : total;
};

function getTotalFromSavedItemsByTopic(
  topic: TopicName,
  topics: TopicFullDetail[],
  savedAnswers: SavedReportDataItem[],
  // totals that have been computed already, to avoid double computation for rules etc.
  cachedTotals: Record<TopicName, number> = {}
): number {
  // If this total was already computed, use it
  const cachedTotal = cachedTotals[topic];
  if (cachedTotal) return cachedTotal;

  const topicFullDetail = getTopicByName(topics, topic);
  if (topicFullDetail) {
    if (topicFullDetail.compiledRule) {
      const topicTotalEntries = topicFullDetail.compiledRule.topicNames.map(
        (topicName) =>
          [topicName, getTotalFromSavedItemsByTopic(topicName, topics, savedAnswers, cachedTotals)] as const
      );
      const topicTotals = Object.fromEntries(topicTotalEntries);
      const ruleTotal = computeRuleTotal(topicFullDetail.compiledRule, topicTotals);
      cachedTotals[topic] = ruleTotal;
    } else {
      const savedTopicAnswers = savedAnswers
        .filter((i) => compareTopicStrings(i.topic!, topic) && i.action?.match(/listsum/i))
        .flatMap((i) => (i.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems);
      const savedAnswersTotal = savedTopicAnswers.reduce(
        (prev, item) => Number(prev) + Number(item.dspValue || item.userValue || 0),
        0
      );
      cachedTotals[topic] = savedAnswersTotal;
    }
  } else {
    // non-crash case for if something went wrong, thisis not expected to occur
    cachedTotals[topic] = 0;
  }

  return cachedTotals[topic];
}

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactElement;
  allowedFrom: string;
}
const ProtectedRoute = ({ children, allowedFrom }: ProtectedRouteProps) => {
  const location = useLocation();
  const isAllowed = location.state?.from === allowedFrom;

  if (!isAllowed) {
    return <Navigate to={allowedFrom} />;
  }

  return children;
};

export default ProtectedRoute;

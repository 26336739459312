export const PageRoutes = {
  Homepage: { routePath: '/', pathname: '/', title: 'Home' },
  Dashboard: { routePath: 'dashboard', pathname: '/dashboard', title: 'Dashboard' },
  Authorisation: { routePath: 'authorisation', pathname: '/authorisation', title: 'Authorisation' },
  ManualAuthorise: {
    routePath: 'manual-authorisation',
    pathname: '/manual-authorisation',
    title: 'Manual authorisation',
  },
  SelectBusiness: { routePath: 'select-business', pathname: '/select-business', title: 'Select Business' },
  ManageConnections: { routePath: 'manage-connections', pathname: '/manage-connections', title: 'Manage Connections' },
  IndustryInsights: {
    routePath: 'industry-insights',
    pathname: '/industry-insights',
    title: 'Industry Insights',
  },
  ClaimObligation: { routePath: 'claim-obligation', pathname: '/claim-obligation', title: 'Claim Obligation' },
  Report: { routePath: 'report', pathname: '/report', title: 'Report' },
  ReportInstructions: { routePath: 'instructions', pathname: '/report/instructions', title: 'Instructions' },
  ReportReview: { routePath: 'review', pathname: '/report/review', title: 'Review report' },
  ReportConfirmation: { routePath: 'confirmation', pathname: '/report/confirmation', title: 'Confirmation' },
  Error: { routePath: 'error', pathname: '/error', title: 'Error' },
} as const;

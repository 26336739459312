/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import { useReactiveVar } from '@apollo/client';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SecureRoute from 'components/SecureRoute/SecureRoute';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Homepage from 'pages/Homepage/Homepage';
import { ErrorState } from 'apollo/states/ErrorState';
import { oktaConfig } from 'lib/authConfig';
import { HandleError } from 'models';
import { env } from 'lib/env';
import ASPApolloClient from 'apollo/client/ASPApolloClient';

import AuthorisationPage from 'pages/Authorisation/AuthorisationPage';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import Layout from 'pages/Layout/Layout';
import ReportPage from 'pages/Report/ReportPage';
import ReportPages from 'pages/Report/ReportPages';
import SelectBusinessPage from 'pages/SelectBusiness/SelectBusinessPage';
import ManageConnectionsPage from 'pages/ManageConnections/ManageConnectionsPage';
import IndustryInsightsPage from 'pages/IndustryInsights/IndustryInsightsPage';
import ClaimObligationPage from 'pages/ClaimObligation/ClaimObligationPage';
import ManualAuthorisation from 'pages/ManualAuthorise/ManualAuthorisation';
import { ServiceDown } from 'pages/ServiceDown/ServiceDown';
import { ErrorPageStatus } from 'enums/ErrorPageStatus';
import { PageRoutes } from 'enums/PageRoutes';
import ErrorRedirect from 'components/Error/ErrorRedirect';
import { usePageTitle } from 'utils/usePageTitle';
import { SaveReportManager } from 'services/SaveReportManager/SaveReportManager';

import 'services/awsRum';
import { GeneralReportTopic } from 'pages/Report/GeneralReportTopic';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';

const { REACT_APP_LOGIN_CALLBACK }: { REACT_APP_LOGIN_CALLBACK: string } = env;

const oktaAuth = new OktaAuth(oktaConfig);

const siteTitle = 'Australian Bureau of Statistics';

export default function App() {
  const navigate = useNavigate();
  const errorState: HandleError = useReactiveVar(ErrorState);
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(originalUri || '/authorisation', { replace: true });
  };

  const { pathname } = useLocation();
  const curPageTitle = usePageTitle(pathname);

  const fullTitle = curPageTitle === '' ? siteTitle : `${curPageTitle} | ${siteTitle}`;

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Helmet>
        <title>{fullTitle}</title>
      </Helmet>
      <ASPApolloClient>
        <SaveReportManager />
        <Routes>
          <Route path={PageRoutes.Homepage.routePath} element={<Layout pathname={pathname} />}>
            <Route path={REACT_APP_LOGIN_CALLBACK} element={<LoginCallback errorComponent={ErrorRedirect} />} />
            <Route index element={<Homepage />} />
            <Route
              path={PageRoutes.Dashboard.routePath}
              element={
                <SecureRoute>
                  <DashboardPage />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.Authorisation.routePath}
              element={
                <SecureRoute>
                  <AuthorisationPage />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.ManualAuthorise.routePath}
              element={
                <SecureRoute>
                  <ManualAuthorisation />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.SelectBusiness.routePath}
              element={
                <SecureRoute>
                  <SelectBusinessPage />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.ManageConnections.routePath}
              element={
                <SecureRoute>
                  <ManageConnectionsPage />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.IndustryInsights.routePath}
              element={
                <SecureRoute>
                  <IndustryInsightsPage />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.ClaimObligation.routePath}
              element={
                <SecureRoute>
                  <ClaimObligationPage />
                </SecureRoute>
              }
            />
            <Route
              path={PageRoutes.Report.routePath}
              element={
                <SecureRoute>
                  <ReportPage />
                </SecureRoute>
              }
            >
              <Route path={PageRoutes.ReportInstructions.routePath} element={<ReportPages.Instructions />} />
              <Route path={PageRoutes.ReportReview.routePath} element={<ReportPages.Review />} />
              <Route
                path={PageRoutes.ReportConfirmation.routePath}
                element={
                  <ProtectedRoute allowedFrom={PageRoutes.ReportReview.pathname}>
                    <ReportPages.Confirmation />
                  </ProtectedRoute>
                }
              />
              <Route path=":topic" element={<GeneralReportTopic />} />
            </Route>
            <Route path={PageRoutes.Error.routePath} element={<ErrorPage selectedError={errorState.errorState} />} />
            <Route path="*" element={<ErrorPage selectedError={ErrorPageStatus.NotFound404} />} />
          </Route>
          <Route path="/service-down" element={<ServiceDown />} />
        </Routes>
      </ASPApolloClient>
    </Security>
  );
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { chosenReport } from 'apollo/states/ChosenReport';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { GetTotalByTopic } from 'apollo/states/utils/GetTotalByTopic';
import { ReportListActions } from 'components/ReportComponents/ReportList/ReportList';
import { getTopTwoRemainingItems } from 'components/ReportComponents/TopRemaining/TopRemaining';
import { DataItem, SavedActionListNumeric, SavedReportDataItem, SavedReportDataItems } from 'models';
import { TopicName, getTopicByName } from 'models/LocalState/TopicFullDetail';
import { compareTopicStrings } from 'utils/CompareTopicStrings';
import { getActionResultAndLabel } from 'utils/GetActionResultAndLabel';

interface QuestionAndAnswer {
  question: DataItem;
  answer: SavedReportDataItem | undefined;
}
interface DataRollups {
  [key: string]: RollupDataItems;
}
interface RollupDataItems {
  value?: string;
  dataItems?: DataItems;
  userInputs?: string[];
  [key: string]: string | DataItems | string[] | undefined;
}
interface DataItems {
  [key: string]: string;
}

interface RollupTopRemaining {
  [key: string]: RollupTopRemainingItem[];
}
interface RollupTopRemainingItem {
  name: string;
  value: string;
}

type ResultType = {
  topics: DataRollups[];
  sortDisplays?: RollupTopRemaining;
};

const RollupData = () => {
  // TODO: Move into a utilty function
  // Get the saved data
  const reportAnswers: SavedReportDataItems = savedReportDataItems();
  // Get the current report data
  const { dataItems: reportQuestions, topics: topicDetails } = currentReportData();
  // Create current report data by matching the saved data to the current report data
  const getReportData: QuestionAndAnswer[] = reportQuestions.map((question) => {
    const answer = reportAnswers.savedAnswers.find((currentAnswer) => currentAnswer.dataItemId === question.dataItemId);
    return { question, answer };
  });

  // Get all topics and create array of unqiue values excluding ASPFeedback which is handled in the ASPFeedback component
  const uniqueTopics = Array.from(
    new Set(reportQuestions.map((item) => item.topic as TopicName).filter((topic) => topic !== 'ASPFeedback'))
  );

  const isNoDsp = chosenReport().isUsingDsp === false;

  // Create the dataItems object with dataItemIds and totals
  const topicDataListTotals = (topicData: QuestionAndAnswer[]) => {
    const filteredItems = topicData
      .filter((item) => item.question.action.match(/LIST/))
      .filter(
        (item) => (item.answer?.savedReportDataItem as SavedActionListNumeric)?.selectedReportDataItems !== undefined
      );
    if (filteredItems.length === 0) return;
    const dataItems = Object.fromEntries(
      filteredItems.map((item) => {
        const { actionResult } = getActionResultAndLabel(
          ReportListActions.SUM,
          (item.answer?.savedReportDataItem as SavedActionListNumeric)?.selectedReportDataItems,
          false
        );
        const roundedActionResult = Math.round(Number(actionResult));
        return [item.question.dataItemId, `${roundedActionResult}`];
      })
    );
    return dataItems;
  };

  // Create user inputs variable containing an array of datItemIds for user inputs
  const topicDataListUserInputs = (topicData: QuestionAndAnswer[]) => {
    const filteredItems = topicData
      .filter((item) => item.question.action.match(/TEXT|NUMBER/))
      .filter((item) => item.answer?.savedReportDataItem !== undefined);
    if (filteredItems.length === 0) return;
    const userInputs = filteredItems.map(
      (item) =>
        // Add item.dataItemId to array and return array
        item?.question.dataItemId
    );
    return userInputs;
  };

  // Function to get data rollups for all topics
  function getDataRollups(topics: TopicName[]) {
    const fullDataRollup = topics.map((topic: TopicName) => {
      // Get all items for current topic in loop
      const topicData = getReportData.filter((i) => compareTopicStrings(i.question.topic, topic));
      // Get dataItems and userInputs for current topic in loop
      const dataItemsTotals = topicDataListTotals(topicData);
      const dataUserInputs = topicDataListUserInputs(topicData);

      // Rollup expects spaces as %20
      const topicName = topic.replaceAll(' ', '%20');

      // Create data rollup structure to hold dataItems, userInputs and topic total value
      const dataRollupStructure: DataRollups = {
        // Update topic string to match the topic string in the report data
        [topicName]: {},
      };

      const rollupDataItems: RollupDataItems = dataRollupStructure[topicName];

      // Add topic total to topics the require it
      const topicDetail = getTopicByName(topicDetails, topic);

      if (topicDetail?.action) {
        rollupDataItems.value = `${GetTotalByTopic(topic)}`;
      }
      if (dataItemsTotals !== undefined && Object.entries(dataItemsTotals).length !== 0) {
        rollupDataItems.dataItems = dataItemsTotals;
      }
      if (dataUserInputs !== undefined && Object.entries(dataUserInputs).length !== 0) {
        rollupDataItems.userInputs = dataUserInputs;
      }

      if (topicDetail?.rule) {
        rollupDataItems.value = `${GetTotalByTopic(topicDetail.topic)}`;
      }

      // Add dataRollupStructure to fullDataRollup
      return dataRollupStructure;
    });

    return fullDataRollup;
  }

  const topRemaining: RollupTopRemaining = {};

  for (const question of reportQuestions) {
    if (question.action.match(/SORTDISPLAY/)) {
      if (isNoDsp) {
        const dataItemAnswers = reportAnswers.savedAnswers.find((i) => i.dataItemId === question.dataItemId)
          ?.savedReportDataItem as SavedActionListNumeric;
        // eslint-disable-next-line no-continue
        if (dataItemAnswers === undefined) continue;
        for (const answer of dataItemAnswers.selectedReportDataItems) {
          const value = Math.round(Number(answer.dspValue || answer.userValue));
          if (topRemaining[question.dataItemId] === undefined) {
            topRemaining[question.dataItemId] = [];
          }
          topRemaining[question.dataItemId].push({ name: answer.accountName!, value: `${value}` });
        }
      } else {
        const topTwo = getTopTwoRemainingItems(reportAnswers, question.topic);
        if (topTwo.length > 0) {
          // Add to the topRemaining Object
          topRemaining[question.dataItemId] = topTwo.map((top): RollupTopRemainingItem => {
            const value = Math.round(Number(top.dspValue || top.userValue));
            return { name: top.accountName!, value: `${value}` };
          });
        }
      }
    }
  }

  // Return topics and sortDisplays objects to be used in rollup data
  const result: ResultType = {
    topics: getDataRollups(uniqueTopics),
  };
  if (Object.keys(topRemaining).length !== 0) {
    result.sortDisplays = topRemaining;
  }
  return result;
};

export default RollupData;

import { gql } from '@apollo/client';

export const SAVE_REPORT = gql`
  mutation SaveReport(
    $dspProvider: String
    $reportDataObject: ReportDataObject
    $organisationId: String
    $lockReport: Boolean
    $saveTrigger: String
  ) {
    saveReport(
      dspProvider: $dspProvider
      reportDataObject: $reportDataObject
      organisationId: $organisationId
      lockReport: $lockReport
      saveTrigger: $saveTrigger
    ) {
      success
      pdfUrl
      errorMessages {
        language
        message
        errorCode
        metaData
        errorType
        stack
        time
      }
    }
  }
`;

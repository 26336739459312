/* istanbul ignore file */
export const currencyFormat = Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const numberWithCommas = (x: string) => {
  const newVal = x.replace(/[$,]/g, '');
  return newVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useReactiveVar } from '@apollo/client';
import { Flex, Text } from '@aws-amplify/ui-react';

import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { DataItem, SavedActionListNumeric, SavedActionListNumericItem, SavedReportDataItem } from 'models';
import { ReportListHeaderTitle } from 'components/ReportComponents/ReportList/ReportListHeader';
import RemainingInputNoDsp from 'components/ReportComponents/TopRemaining/RemainingInputNoDsp';
import { currencyFormat } from 'utils/CurrencyFormat';

import './TopRemainingNoDsp.css';

interface TopRemainingNoDspProps {
  dataItem: DataItem;
  minified?: boolean;
  helpTexts?: string[];
}

const TopRemainingNoDsp = ({ dataItem, minified, helpTexts }: TopRemainingNoDspProps) => {
  const savedItems = useReactiveVar(savedReportDataItems);
  const serverItems = useReactiveVar(latestSavedReportDataItems);
  const savedDataItem = savedItems.savedAnswers.find((item) => item.dataItemId === dataItem.dataItemId);
  const savedInServer = serverItems.savedAnswers.find((item) => item.dataItemId === dataItem.dataItemId);
  const savedAnswers = (savedDataItem?.savedReportDataItem as SavedActionListNumeric)?.selectedReportDataItems;

  const largestResponseUidSuffix = 'NODSPLRI';
  const secondLargestResponseUidSuffix = 'NODSPSRI';

  const savedLargestAnswer = savedAnswers?.find((answer) => answer.responseUid.endsWith(largestResponseUidSuffix));
  const savedSecondLargestAnswer = savedAnswers?.find((answer) =>
    answer.responseUid.endsWith(secondLargestResponseUidSuffix)
  );

  const { topic } = dataItem;
  const largestRemainingString = `Largest remaining ${topic.toLowerCase()} item`;
  const secondLargestRemainingString = `Second largest remaining ${topic.toLowerCase()} item`;

  const updateMappedAccounts = (newAccount: SavedActionListNumericItem) => {
    const inputsEmpty = newAccount.accountName === '' && newAccount.userValue === '';
    // If both inputs are empty and the dataitem is not saved in the server, remove the dataitem from savedItems
    if (inputsEmpty && !savedInServer) {
      const updatedSavedReportDataItems = savedItems.savedAnswers.filter(
        (item) => item.dataItemId !== dataItem.dataItemId
      );
      savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
      return;
    }
    const updatedSelectedReportDataItems = [
      ...savedAnswers.filter((answer) => !newAccount.responseUid.includes(answer.responseUid)),
    ];
    // Only adding newAccount if both inputs are not empty to allow deleting of a previously saved account
    if (!inputsEmpty) updatedSelectedReportDataItems.push(newAccount);
    const updatedMappedItem: SavedActionListNumeric = {
      dataItemId: dataItem.dataItemId,
      action: dataItem.action,
      dataItemText: dataItem.dataItemText,
      selectedReportDataItems: updatedSelectedReportDataItems,
    };
    const updatedSavedReportDataItems = savedItems.savedAnswers.map((item) =>
      item.dataItemId === dataItem.dataItemId ? { ...item, savedReportDataItem: updatedMappedItem } : item
    );
    savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
  };

  const addMappedAccount = (newAccount: SavedActionListNumericItem) => {
    // Create a new answer
    const newAnswer: SavedActionListNumeric = {
      selectedReportDataItems: [newAccount],
      dataItemId: dataItem.dataItemId,
      action: dataItem.action,
      dataItemText: dataItem.dataItemText,
    };
    // If there are no answers for this dataitem, create a new savedReportDataItem
    if (!savedDataItem) {
      const newSavedReportDataItem: SavedReportDataItem = {
        savedReportDataItem: newAnswer,
        dataItemId: dataItem.dataItemId,
        accountClass: dataItem.accountClass ?? '',
        isCalculated: false,
        topic: dataItem.topic,
        action: dataItem.action,
        dataItemText: dataItem.dataItemText,
        overwrite: false,
      };
      savedReportDataItems({ savedAnswers: [...savedItems.savedAnswers, newSavedReportDataItem] });
    } else {
      // Otherwise, add the new answer to the existing savedReportDataItem
      const updatedMappedItem: SavedActionListNumeric = {
        dataItemId: dataItem.dataItemId,
        action: dataItem.action,
        dataItemText: dataItem.dataItemText,
        selectedReportDataItems: [
          ...newAnswer.selectedReportDataItems,
          ...savedAnswers.filter(
            (answer) => !newAnswer.selectedReportDataItems.map((item) => item.responseUid).includes(answer.responseUid)
          ),
        ],
      };
      // Creating a new answers object merging old + new
      const updatedSavedReportDataItems = savedItems.savedAnswers.map((item) =>
        item.dataItemId === dataItem.dataItemId ? { ...item, savedReportDataItem: updatedMappedItem } : item
      );
      savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
    }
  };

  return minified ? (
    <Flex direction="column" gap="8px" marginLeft="32px">
      <Text fontWeight="600" marginTop="8px">
        {dataItem.dataItemText}
      </Text>
      {savedLargestAnswer || savedSecondLargestAnswer ? (
        <Flex direction="column" gap="0">
          {minifiedTopRemainingDisplay(savedLargestAnswer)}
          {minifiedTopRemainingDisplay(savedSecondLargestAnswer)}
        </Flex>
      ) : (
        <Text color="#4B4F54">No response provided</Text>
      )}
    </Flex>
  ) : (
    <Flex className="top-remaining-no-dsp-inputs" direction="column" marginBottom="66px">
      <ReportListHeaderTitle
        title={dataItem.dataItemText}
        helpTexts={helpTexts}
        alwaysDisplayHelpTexts
        dataItemId={dataItem.dataItemId}
      />
      <Flex direction="column" gap="36px">
        <RemainingInputNoDsp
          nameLabel={largestRemainingString}
          savedAnswer={savedLargestAnswer}
          uidSuffix={largestResponseUidSuffix}
          updateAnswerCallback={updateMappedAccounts}
          addAnswerCallback={addMappedAccount}
        />
        <RemainingInputNoDsp
          nameLabel={secondLargestRemainingString}
          savedAnswer={savedSecondLargestAnswer}
          uidSuffix={secondLargestResponseUidSuffix}
          updateAnswerCallback={updateMappedAccounts}
          addAnswerCallback={addMappedAccount}
        />
      </Flex>
    </Flex>
  );
};

const minifiedTopRemainingDisplay = (answer: SavedActionListNumericItem | undefined) =>
  answer ? (
    <Flex justifyContent="space-between" width="fit-content" minWidth="416px">
      <Text>{answer.accountName}</Text>
      <Text color="#4B4F54">{currencyFormat.format(Number(answer.userValue))}</Text>
    </Flex>
  ) : null;

export default TopRemainingNoDsp;

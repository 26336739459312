/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import { ApolloError, ServerError } from '@apollo/client';

import { ErrorState } from 'apollo/states/ErrorState';
import { ErrorPageStatus } from 'enums/ErrorPageStatus';

export const setErrorState = (error: ApolloError) => {
  if (error?.networkError) {
    if ((error?.networkError as ServerError).statusCode === 403) {
      ErrorState({ errorState: ErrorPageStatus.RevokeAccess403 });
      return;
    }
    if ((error?.networkError as ServerError).statusCode === 401) {
      ErrorState({ errorState: ErrorPageStatus.Error401 });
      return;
    }
    if (
      (error?.networkError as ServerError).statusCode === undefined &&
      (error?.networkError as ServerError).message === 'Failed to fetch' &&
      (error?.networkError as ServerError).name === 'TypeError'
    ) {
      // check 500 for other way
      ErrorState({ errorState: ErrorPageStatus.Error500 });
      return;
    }
    ErrorState({ errorState: ErrorPageStatus.Generic404 });
  }
};

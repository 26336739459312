import { gql } from '@apollo/client';

export const GET_DSP_BUSINESS_NAME = gql`
  query GetDspBusinessName($dsp: String!, $organisationId: ID!) {
    getDspBusinessName(dsp: $dsp, organisationId: $organisationId) {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
      dspBusinessName
    }
  }
`;

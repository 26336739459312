import { wrap } from 'comlink';
import { saveAs } from 'file-saver';
import { PdfWorker } from 'webworkers/PdfWorker';
import { ClearReportStateData } from 'apollo/states/utils/ClearReportStateData';
import reportPdfData, { ReportPDFInputData } from './getPdfData';

const handleGeneratePdf = async (submitted: boolean, data?: ReportPDFInputData) => {
  const reportPDFData = reportPdfData(data);

  const worker = new Worker('/bundle.min.js');
  const isDashboard = data !== undefined;
  const pdfWorker = wrap<PdfWorker>(worker);

  let timeout;
  const timeoutPromise = new Promise((_, reject) => {
    timeout = setTimeout(() => {
      reject(new Error('PDF Generation Failed'));
    }, 30000); // 30 seconds timeout
  });

  try {
    const pdfBlobPromise = pdfWorker.generateSinglePDF(submitted, reportPDFData);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pdfBlob = await Promise.race([pdfBlobPromise, timeoutPromise]);
    clearTimeout(timeout);
    saveAs((await pdfBlobPromise).pdfBlob, (await pdfBlobPromise).fileName);
  } catch (error) {
    throw new Error('PDF Generation Failed');
  } finally {
    worker.terminate();
  }

  // TODO: when ReportSummary is updated not to set report-related reactive vars for PDF, remove this
  if (submitted && isDashboard) ClearReportStateData();
};

export default handleGeneratePdf;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  Pagination,
  SearchField,
  View,
  VisuallyHidden,
} from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Dialog } from '@mui/material';

import { AuthorisedBusiness, RevokeBusinessResponse } from 'models';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { REVOKE_BUSINESS } from 'apollo/mutations/revokeBusiness';
import { SET_LAST_ACTIVE_BUSINESS } from 'apollo/mutations/setLastActiveBusiness';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import { recordRumCustomEvent, recordRumError } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getDsp } from 'utils/dspIndex';

import { ReactComponent as GreenTickIconSvg } from 'assets/icon-green-tick.svg';
import { ReactComponent as DisconnectIconSvg } from 'assets/icon-disconnect.svg';
import { ReactComponent as MoreIconSvg } from 'assets/icon-more-horizontal.svg';
import { ReactComponent as SwitchIconSvg } from 'assets/icon-switch.svg';
import { ReactComponent as CloseIconSvg } from 'assets/icon-x.svg';

import './ManageConnections.css';
import { getRumAttributes } from 'utils/getRumAttributes';
import { allAllowedCharsRegex } from 'utils/CharacterValidation';
import { AuthorisationRouterState } from 'pages/Authorisation/AuthorisationPage';

const modalPaperProps = { sx: { padding: '80px 105px', borderRadius: '16px' }, 'aria-labelledby': 'dialog-heading' };
const modalBackdropProps = { sx: { backgroundColor: 'rgba(75, 79, 84, 0.17)' } };

const ManageConnections = () => {
  const navigate = useNavigate();

  const currentBusinesses = useReactiveVar(authorisedBusinesses);

  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showDisconnectSuccessModal, setShowDisconnectSuccessModal] = useState(false);
  const [businessToRemove, setBusinessToRemove] = useState<AuthorisedBusiness | undefined>(undefined);
  const [revokeBusinessCall, revokeBusinessResponse] = useMutation<RevokeBusinessResponse>(REVOKE_BUSINESS);

  const [callSetActiveBusiness] = useMutation(SET_LAST_ACTIVE_BUSINESS);

  const [successNotification, setSuccessNotification] = useState(false);
  const [failNotification, setFailNotification] = useState(false);
  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();

  const remainingBusinesses = currentBusinesses.authorisedBusinesses
    .filter((business) => business.id !== currentBusinesses.activeBusiness?.id)
    .sort((a, b) => Number(a.name.localeCompare(b.name)));

  const handleSwitch = (business: AuthorisedBusiness) => {
    authorisedBusinesses({ activeBusiness: business, authorisedBusinesses: currentBusinesses.authorisedBusinesses });
    recordRumCustomEvent(RumCustomEvent.businessLoad, getRumAttributes({ providerId: business.providerId }));
    callSetActiveBusiness({
      variables: { dspProvider: business.dsp, organisationId: business.id },
    });
    navigate('/dashboard');
  };

  const handleRemove = (business: AuthorisedBusiness) => {
    revokeBusinessResponse.reset();
    setBusinessToRemove(business);
    setSuccessNotification(false);
    setFailNotification(false);
    clearDspApiToast();
    setShowDisconnectModal(true);
  };

  const businessDisconnectMessaging = useMemo(() => {
    const DspInfo = businessToRemove?.dsp ? getDsp(businessToRemove.dsp) : undefined;
    return DspInfo?.showBusinessDisconnectMessaging && DspInfo?.businessDisconnectMessagingContent;
  }, [businessToRemove?.dsp]);

  const handleRevoke = () => {
    if (businessToRemove) {
      revokeBusinessCall({
        variables: { dspProvider: businessToRemove.dsp, organisationId: businessToRemove.id },
        notifyOnNetworkStatusChange: true,
        onCompleted: (response) => {
          setShowDisconnectModal(false);
          const errorMessages = response.revokeBusiness.errorMessages ?? [];

          if (response.revokeBusiness.success) {
            if (businessDisconnectMessaging) {
              setShowDisconnectSuccessModal(true);
            } else {
              setSuccessNotification(true);
            }
            // Start new authorisedBusinesses object
            const tempList = { ...currentBusinesses };
            // If we are removing an active business, set the next available business as active
            if (businessToRemove === currentBusinesses.activeBusiness) {
              if (currentBusinesses.authorisedBusinesses.length > 1) {
                // eslint-disable-next-line prefer-destructuring
                tempList.activeBusiness = remainingBusinesses[0];
                callSetActiveBusiness({
                  variables: { dspProvider: tempList.activeBusiness.dsp, organisationId: tempList.activeBusiness.id },
                });
              } else {
                return setTimeout(() => authorisedBusinesses({ activeBusiness: undefined, authorisedBusinesses: [] }));
              }
            }
            // Remove the business from the authorised list
            tempList.authorisedBusinesses = [
              ...currentBusinesses.authorisedBusinesses.filter((business) => business.id !== businessToRemove.id),
            ];
            // Set new state (without removed, and new active if needed)
            authorisedBusinesses({ ...tempList });
          } else if (!showToastOpenIfDSPError(errorMessages)) {
            setFailNotification(true);
          }
        },
        onError: (error) => recordRumError(error),
      });
    }
  };

  useEffect(() => {
    if (revokeBusinessResponse.error) {
      setShowDisconnectModal(false);
      setFailNotification(true);
    }
  }, [revokeBusinessResponse]);

  useEffect(() => {
    if (currentBusinesses.authorisedBusinesses.length === 0 && !showDisconnectSuccessModal) {
      navigate('/authorisation', {
        replace: true,
        state: { addingBusiness: false, noAuthorisedBusinesses: true } as AuthorisationRouterState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusinesses, showDisconnectSuccessModal]);

  return (
    <div className="manage-connections-wrapper">
      <div className="active-business-table">
        <table className="manage-connections-table" data-testid="manage-connections-table-active">
          <caption
            className="manage-business-table-caption manage-connections-table-heading"
            data-testid="manage-business-table-caption-active"
          >
            Active business
          </caption>
          <thead>
            <tr>
              <th className="manage-connections-table-business-header">Business</th>
              <th>
                <VisuallyHidden>disconnect</VisuallyHidden>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="manage-connections-authorised-table-row">
              <td>
                <BusinessBasicInfo business={currentBusinesses.activeBusiness} />
              </td>
              <td className="manage-connections-table-options-row">
                <Button
                  className="manage-connections-disconnect-btn"
                  testId="manage-connections-disconnect-btn"
                  variation="warning"
                  onClick={() => handleRemove(currentBusinesses.activeBusiness!)}
                >
                  Disconnect business
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AuthorisedBusinessesTable
        remainingBusinesses={remainingBusinesses}
        handleSwitch={handleSwitch}
        handleRemove={handleRemove}
      />
      <Dialog
        open={showDisconnectModal}
        onClose={() => setShowDisconnectModal(false)}
        PaperProps={modalPaperProps}
        BackdropProps={modalBackdropProps}
      >
        <CustomAmplifyTheme isModal>
          <Flex alignItems="center" direction="column">
            <DisconnectIconSvg />
            <Heading id="dialog-heading" className="dialog-heading" testId="dialog-heading" level={2}>
              Disconnect business
            </Heading>
            <Heading className="dialog-business-name" testId="dialog-business-name" level={3}>
              {businessToRemove?.name}, {businessToRemove?.suburb}
            </Heading>
            <p className="dialog-text">
              {currentBusinesses.authorisedBusinesses.length === 1
                ? 'You will be unable to access ABS Business Reporting unless this or another business is reauthorised.'
                : 'You will be unable to access current and past reports for this business, unless it is reauthorised.'}{' '}
              {businessToRemove?.dsp !== 'NODSP' &&
                'Other users will still have access to this business unless they disconnect it.'}
            </p>
            {businessDisconnectMessaging}
            <Flex className="dialog-button-wrapper">
              <Button testId="dialog-cancel-btn" onClick={() => setShowDisconnectModal(false)}>
                Cancel
              </Button>
              <Button
                variation="primary"
                loadingText="Loading"
                isLoading={revokeBusinessResponse.loading}
                testId="dialog-disconnect-btn"
                onClick={handleRevoke}
              >
                Disconnect
              </Button>
            </Flex>
          </Flex>
        </CustomAmplifyTheme>
      </Dialog>
      <Dialog
        open={showDisconnectSuccessModal}
        onClose={() => setShowDisconnectSuccessModal(false)}
        PaperProps={modalPaperProps}
        BackdropProps={modalBackdropProps}
      >
        <CustomAmplifyTheme isModal>
          <Flex alignItems="center" direction="column">
            <GreenTickIconSvg />
            <Heading id="dialog-heading" className="dialog-heading" testId="dialog-heading" level={2}>
              Successfully disconnected
            </Heading>
            <Heading className="dialog-business-name" testId="dialog-business-name" level={3}>
              {businessToRemove?.name}, {businessToRemove?.suburb}
            </Heading>
            {businessDisconnectMessaging}
            <Flex className="dialog-button-wrapper">
              <Button
                testId="dialog-cancel-btn"
                onClick={() => setShowDisconnectSuccessModal(false)}
                variation="primary"
              >
                Close
              </Button>
            </Flex>
          </Flex>
        </CustomAmplifyTheme>
      </Dialog>
      <ToastNotification
        success
        title="Business disconnected"
        text={`${businessToRemove?.name}, ${businessToRemove?.suburb} has been successfully disconnected`}
        open={successNotification}
      />
      {toastToShow}
      <ToastNotification
        success={false}
        title="Error disconnecting"
        text="Something went wrong when disconnecting. Please try again."
        open={failNotification}
      />
    </div>
  );
};

// paginated authorised businesses list
interface BusinessTableProps {
  remainingBusinesses: AuthorisedBusiness[];
  handleSwitch: (Business: AuthorisedBusiness) => void;
  handleRemove: (Business: AuthorisedBusiness) => void;
}
const AuthorisedBusinessesTable = ({ remainingBusinesses, handleSwitch, handleRemove }: BusinessTableProps) => {
  const [validateError, setValidateError] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const validateInput = (value: string): boolean => {
    if (!value.match(allAllowedCharsRegex)) {
      setValidateError(true);
      return false;
    }
    setValidateError(false);
    return true;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (validateInput(value)) setSearchValue(value);
  };

  const handleSearchClear = () => setSearchValue('');

  const filteredBusinesses = searchValue.length
    ? remainingBusinesses.filter((business) => {
        const businessString = `${business.name}, ${business.suburb}`;
        return businessString.toLowerCase().includes(searchValue.toLowerCase());
      })
    : remainingBusinesses;

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const entriesPerPage = 10;
  const totalPages = Math.ceil(filteredBusinesses.length / entriesPerPage);

  const [listBounds, setListBounds] = useState([
    0,
    filteredBusinesses.length < entriesPerPage ? filteredBusinesses.length : entriesPerPage,
  ]);

  const handleNextPage = () => handlePageChange(currentPageIndex + 1, currentPageIndex);
  const handlePreviousPage = () => handlePageChange(currentPageIndex - 1, currentPageIndex);
  const handlePageChange = (newPageIndex: number | undefined, prevPageIndex: number | undefined) => {
    if (!newPageIndex || !prevPageIndex) return;

    const lowerBound = (newPageIndex - 1) * entriesPerPage;
    const upperBound =
      lowerBound + entriesPerPage > remainingBusinesses.length
        ? remainingBusinesses.length
        : lowerBound + entriesPerPage;

    setListBounds([lowerBound, upperBound]);
    setCurrentPageIndex(newPageIndex);
  };

  if (totalPages === 1 && currentPageIndex !== 1) handlePageChange(1, currentPageIndex);

  return (
    <div className="authorised-businesses-table">
      <table className="manage-connections-table" data-testid="manage-connections-table-authorised">
        <thead>
          <tr className="manage-connections-table-heading">
            <th>
              <h2 className="manage-business-table-caption" data-testid="manage-business-table-caption-authorised">
                Authorised businesses
              </h2>
            </th>
            <th>
              <View className="search-container">
                <SearchField
                  label="Search businesses"
                  hasSearchButton={false}
                  hasSearchIcon
                  labelHidden={false}
                  maxLength={250}
                  value={searchValue}
                  onChange={(e) => handleSearchChange(e)}
                  onClear={handleSearchClear}
                  onBlur={() => setValidateError(false)}
                  className={validateError ? 'validation-error' : ''}
                  testId="business-table-search-field"
                />
                <p
                  className="input-error-message"
                  data-testid="input-error-validation-message"
                  style={validateError ? { visibility: 'initial' } : { visibility: 'hidden' }}
                >
                  Only alphanumeric and standard special characters allowed
                </p>
              </View>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredBusinesses.length > 0 ? (
            filteredBusinesses.slice(listBounds[0], listBounds[1]).map((business) => (
              <tr
                className="manage-connections-authorised-table-row"
                key={business.id}
                data-testid="manage-connections-authorised-business"
              >
                <td>
                  <BusinessBasicInfo business={business} />
                </td>
                <td className="manage-connections-table-options-row">
                  <ManageConnectionsMenu
                    switchCallback={() => handleSwitch(business)}
                    disconnectCallback={() => handleRemove(business)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="manage-connections-no-authorised"
                colSpan={2}
                data-testid="manage-connections-authorised-business"
              >
                {searchValue.length > 0
                  ? 'No businesses match this search'
                  : 'No other businesses are currently authorised'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <View className="pagination-container">
        <Pagination
          currentPage={currentPageIndex}
          totalPages={totalPages}
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          onChange={handlePageChange}
          aria-label="Authorised Businesses page controls"
          pageLabel="Jump to page"
          currentPageLabel="You are on page"
          previousLabel="Back to previous page"
          nextLabel="Forward to next page"
          style={totalPages <= 1 ? { display: 'none' } : { display: 'initial' }}
          testId="pagination-controls"
        />
      </View>
    </div>
  );
};

interface MoreMenuProps {
  switchCallback: () => void;
  disconnectCallback: () => void;
}
const ManageConnectionsMenu = (props: MoreMenuProps) => {
  const { switchCallback, disconnectCallback } = props;
  return (
    <Menu
      className="manage-connections-menu"
      size="small"
      menuAlign="end"
      trigger={
        <MenuButton
          className="manage-connections-menu-btn"
          title="more"
          testId="manage-connections-menu-btn"
          onKeyDown={(event) => {
            if (event.code === 'Enter') event.nativeEvent.preventDefault();
          }}
        >
          <MoreIconSvg />
        </MenuButton>
      }
    >
      <MenuItem testId="manage-connections-menu-switch-btn" onClick={switchCallback}>
        <SwitchIconSvg width={26} />
        Switch to this business
      </MenuItem>
      <MenuItem testId="manage-connections-menu-remove-btn" onClick={disconnectCallback} color="#DC2626">
        <CloseIconSvg width={26} className="manage-connections-menu-close-svg" />
        Disconnect business
      </MenuItem>
    </Menu>
  );
};

const BusinessBasicInfo = ({ business }: { business?: AuthorisedBusiness }) => {
  const dspInfo = getDsp(business?.dsp);

  return (
    <Flex alignItems="center">
      <Flex minWidth="60px" justifyContent="center">
        {dspInfo.manageConnectionsIcon}
      </Flex>
      <Flex direction="column" gap="0.3rem">
        <View className="manage-connections-current-business-heading">{`${business?.name}, ${business?.suburb}`}</View>
        {dspInfo.showDspBusinessName && business?.dspBusinessName !== '' && (
          <View className="manage-connections-dsp-name-heading">{`${business?.dspBusinessName}`}</View>
        )}
      </Flex>
    </Flex>
  );
};

export default ManageConnections;

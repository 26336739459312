import { makeVar } from '@apollo/client';

export const ExpandedReview = makeVar<boolean>(false);
export const ExpandedReviewClicked = makeVar<boolean>(false);
export const ExpandersArray = makeVar<{ dataItem: string; expanded: boolean }[]>([]);

export function addToExpandersArray(dataItem: string) {
  if (!ExpandersArray().find((item) => item.dataItem === dataItem)) {
    ExpandersArray([...ExpandersArray(), { dataItem, expanded: false }]);
  }
}

export function updateExpandersArray(dataItem: string, expanded: boolean) {
  ExpandersArray(
    ExpandersArray().map((item) => {
      if (item.dataItem === dataItem) item.expanded = expanded;
      return item;
    })
  );
}

import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { getTopicByPath } from 'models/LocalState/TopicFullDetail';
import { useLocation } from 'react-router-dom';

export const useCurrentReportTopic = () => {
  const { pathname } = useLocation();
  const topics = useCurrentReportTopics();
  if (!topics) return undefined;

  const topicDetail = getTopicByPath(topics, pathname);

  return topicDetail;
};

import { Button } from '@aws-amplify/ui-react';

export const ErrorStateGraph: React.FC<{
  onRetry: (event: React.MouseEvent<HTMLElement>) => void;
  showRetry?: boolean;
}> = ({ onRetry, showRetry = true }) => {
  const showReloadButton = () => (
    <Button variation="primary" onClick={onRetry}>
      {' '}
      Try again{' '}
    </Button>
  );

  return (
    <div
      style={{
        height: '400px',
        backgroundColor: '#EFF0F0',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '1rem',
        borderRadius: '8px',
      }}
    >
      The industry data could not be loaded. {showRetry ? showReloadButton() : false}
    </div>
  );
};

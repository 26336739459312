/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Card, Divider, Flex, Heading, Text, Image, Button, Link } from '@aws-amplify/ui-react';
import { useState } from 'react';
import Select, { components, PlaceholderProps } from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { businessSelectStyles, CustomDropdownIndicator, Control } from 'lib/BusinessSelectComponents';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { ClaimObligation } from 'models';
import { InlineError } from 'components/InlineError/InlineError';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { externalLinks } from 'lib/externalLinks';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import { checkErrorMessages } from 'utils/errorHandling';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';

import myobLogo from 'assets/logo-myob.svg';

import './SelectBusinessPage.css';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';

interface SelectOptionType {
  value: '';
  label: '';
}

interface SelectBusinessState {
  newOrganisations: { organisationId: string; businessName: string }[];
  dsp: string;
}

// Workaround to simulate api call variables changing on re-submission of same business
// Updating the counter in the call's onCompleted callback
let updateCounter = 0;

const SelectBusinessPage = () => {
  const navigate = useNavigate();
  // Get authorised business ids to filter out connected businesses
  const currentBusinesses = authorisedBusinesses();
  const authorisedBusinessIds = currentBusinesses.authorisedBusinesses.map((business) => business.id);
  // Extract the DSP and the list of businesses from router state
  const newOrganisations = (useLocation().state as SelectBusinessState)?.newOrganisations ?? [];
  // Filter out businesses that are already connected
  const newBusinesses = newOrganisations.filter((business) => !authorisedBusinessIds.includes(business.organisationId));

  const dsp = (useLocation().state as SelectBusinessState)?.dsp ?? '';

  const selectOptions = newBusinesses.map((business) => ({
    value: business.organisationId,
    label: business.businessName,
  }));

  let dspLogo = '';
  switch (dsp.toLocaleLowerCase()) {
    case 'myob':
      dspLogo = myobLogo;
      break;
    default:
  }

  const [selectedBusiness, setSelectedBusiness] = useState<SelectOptionType | undefined>(
    selectOptions.length === 1 ? (selectOptions[0] as SelectOptionType) : undefined
  );

  const [inlineError, setInlineError] = useState(false);
  const [myobSsoError, setMyobSsoError] = useState(false);

  const [claimObligation, claimObligationResponse] = useLazyQuery<ClaimObligation | undefined>(CLAIM_OBLIGATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      updateCounter += 1;
      const errorMessages = response?.claimObligation.errorMessages ?? [];
      if (response?.claimObligation.success) {
        setActiveBusinessAfterClaim(claimObligationResponse.variables?.organisationId);
        navigate('/dashboard');
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.requestFailed401)) {
        setMyobSsoError(true);
      } else if (
        checkErrorMessages(errorMessages, ApiErrorMsg.noAbnFoundForClaim) ||
        checkErrorMessages(errorMessages, ApiErrorMsg.dspApiNoAbnError)
      ) {
        navigate('/claim-obligation', {
          state: {
            organisationId: claimObligationResponse.variables?.organisationId,
            businessName: response?.claimObligation?.dspBusinessName ?? '',
            businessAbn: response?.claimObligation?.dspAbn ?? '',
            role: claimObligationResponse.variables?.role,
            dsp: claimObligationResponse.variables?.dsp,
          },
        });
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)) {
        navigate('/authorisation', {
          state: {
            addingBusiness: true,
            claimAlreadyClaimed: true,
          },
        });
      } else {
        setInlineError(true);
      }
    },
    onError: () => setInlineError(true),
  });

  const handleChange = (newvalue: unknown) => {
    setSelectedBusiness(newvalue as SelectOptionType);
  };

  const handleSubmit = () => {
    setMyobSsoError(false);
    setInlineError(false);
    claimObligation({
      variables: {
        organisationId: selectedBusiness?.value,
        role: 'role',
        dsp: dsp.toUpperCase(),
        updateCounter,
      },
    });
  };

  return (
    <>
      {myobSsoError && (
        <AlertComponent
          title="Problem connecting to ABS Business Reporting"
          textHeaderComponent={
            <>
              Please make sure you have linked the User ID for the company file to your my.MYOB. Visit{' '}
              <Link href={externalLinks.myobSsoInstructions} isExternal>
                MYOB instructions
              </Link>{' '}
              or{' '}
              <Link
                href={externalLinks.absContactUs}
                isExternal
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
              >
                contact us
              </Link>{' '}
              for help resolving this issue.
            </>
          }
          variation="error"
          alertProps={{ isDismissible: true, margin: '16px auto 0' }}
        />
      )}
      <Card className="select-business-card" paddingTop={myobSsoError ? '77px' : '112px'}>
        <Image
          src={dspLogo}
          alt={`${dsp} company logo`}
          height="67px"
          width="100%"
          margin="0 auto"
          testId="select-business-logo"
        />
        <Heading className="select-business-heading" testId="select-business-heading" level={1}>
          Connect to ABS Business Reporting
        </Heading>
        <Text className="select-business-text" testId="select-business-text">
          ABS Business Reporting is requesting access to the following from your {dsp} account
        </Text>
        <Flex className="select-business-permissions" testId="select-business-permissions">
          <Text fontWeight="700">View your:</Text>
          <Divider className="select-business-divider" />
          <Text margin="16px 0">Business's reports and organisation settings</Text>
        </Flex>
        <Flex direction="column" gap="8px" testId="select-business-input">
          <label className="select-business-label" data-testid="select-business-label" htmlFor="select-business-input">
            Business
          </label>
          <Select
            value={selectedBusiness}
            inputId="select-business-input"
            styles={businessSelectStyles}
            options={selectOptions}
            maxMenuHeight={215}
            onChange={(selected) => handleChange(selected)}
            components={{
              Placeholder,
              DropdownIndicator: CustomDropdownIndicator,
              Control,
            }}
          />
        </Flex>
        <Flex direction="column" gap="4px" alignItems="center">
          <Flex direction="row-reverse" justifyContent="center" marginTop="60px" gap="19px">
            <Button
              variation="primary"
              className="select-business-btn"
              onClick={handleSubmit}
              isDisabled={selectedBusiness === undefined}
              isLoading={claimObligationResponse.loading}
              loadingText="Loading..."
            >
              Connect business
            </Button>
            <Button
              className="select-business-btn"
              onClick={() => navigate('/authorisation', { state: { addingBusiness: true } })}
            >
              Cancel
            </Button>
          </Flex>
          {inlineError && <InlineError styles="select-business-inline-error" />}
        </Flex>
      </Card>
    </>
  );
};

const Placeholder = ({ isFocused, ...props }: PlaceholderProps) => (
  <components.Placeholder {...props} isFocused={isFocused}>
    {isFocused ? <Text>Search...</Text> : <Text>Choose a business</Text>}
  </components.Placeholder>
);

export default SelectBusinessPage;

import {
  SavedActionListNumeric,
  SavedActionListNumericItem,
  SavedActionNumber,
  SavedActionText,
  SavedReportDataItem,
  SavedReportDataItems,
} from 'models';
import { payloadLimitReportDataItems } from './payloadLimit';
import { asSavedActionNumber, asSavedActionText } from './savedActionTypeUtil';

/**
 * Look up a matching answer from a list of answers.
 *
 * @param referenceAnswer answer to find a match for, by dataItemId
 * @param savedAnswers answers to look for match in
 * @returns the answer from savedAnswers with matching dataItemId to referenceAnswer, or undefined if none match
 */
const getAssociatedSavedAnswer = (
  referenceAnswer: SavedReportDataItem,
  savedAnswers: SavedReportDataItems
): SavedReportDataItem | undefined =>
  savedAnswers.savedAnswers.find((answer) => answer.dataItemId === referenceAnswer.dataItemId);

/**
 * Return an item if it represents an actual non-blank and non-deleted item.
 *
 * @param item item to return if it represents an item with a value present
 */
const savedReportDataItemPresence = (item?: SavedReportDataItem): SavedReportDataItem | undefined => {
  if (typeof item === 'undefined') return undefined;
  if (item.delete) return undefined;

  const savedItem = item.savedReportDataItem;

  // check each type's "empty" case
  // Note: list types count as defined even with an empty array, so we don't check them
  const savedNumber = asSavedActionNumber(savedItem);
  if (typeof savedNumber !== 'undefined' && typeof savedNumber.savedNumber === 'undefined') return undefined;

  const savedText = asSavedActionText(savedItem);
  if (typeof savedText !== 'undefined' && (typeof savedText.savedText === 'undefined' || !savedText.savedText.length))
    return undefined;

  return item;
};

/**
 * Like getUpdate but works with undefined values too.
 */
const nullSafeGetUpdate = (
  local?: SavedReportDataItem,
  server?: SavedReportDataItem
): SavedReportDataItem | undefined => {
  const localPresence = savedReportDataItemPresence(local);
  const serverPresence = savedReportDataItemPresence(server);

  if (typeof localPresence === 'undefined') {
    if (typeof serverPresence === 'undefined') {
      // No local or server item, nothing to do (should not happen but checked for safety)
      return undefined;
    }

    // Deleted item: not present locally but present on server
    const savedNumber = asSavedActionNumber(serverPresence.savedReportDataItem);
    const savedText = asSavedActionText(serverPresence.savedReportDataItem);

    // get empty item value for the delete operation
    let savedReportDataItem: SavedReportDataItem['savedReportDataItem'];
    if (typeof savedNumber !== 'undefined') {
      savedReportDataItem = {
        ...savedNumber,
        savedNumber: undefined,
      };
    } else if (typeof savedText !== 'undefined') {
      savedReportDataItem = {
        ...savedText,
        savedText: '',
      };
    } else {
      savedReportDataItem = {
        ...serverPresence.savedReportDataItem,
        selectedReportDataItems: [],
      };
    }

    const update = {
      ...serverPresence,
      delete: true,
      overwrite: false,
      savedReportDataItem,
    };

    return update;
  }

  if (typeof serverPresence === 'undefined') {
    // Added item, send as non-overwrite
    return {
      ...localPresence,
      overwrite: false,
    };
  }

  // Get possible update between local and server values
  return getUpdate(localPresence, serverPresence);
};

const getUpdate = (
  localSavedAnswer: SavedReportDataItem,
  serverSavedAnswer: SavedReportDataItem
): SavedReportDataItem | undefined => {
  // Check for updates if answer is SavedActionNumber

  if ('savedNumber' in localSavedAnswer.savedReportDataItem)
    return checkForUpdatedSavedNumber(localSavedAnswer, serverSavedAnswer);
  if ('savedText' in localSavedAnswer.savedReportDataItem)
    return checkForUpdatedSavedText(localSavedAnswer, serverSavedAnswer);
  if ('selectedReportDataItems' in localSavedAnswer.savedReportDataItem)
    return checkForUpdatedSavedActionListNumeric(localSavedAnswer, serverSavedAnswer);
  return undefined;
};

interface PairedSavedReportDataItem {
  local?: SavedReportDataItem;
  server?: SavedReportDataItem;
}

const asPairedArray = (
  localSavedData: SavedReportDataItems,
  serverSavedData: SavedReportDataItems
): PairedSavedReportDataItem[] => {
  const output: PairedSavedReportDataItem[] = [];

  // ids that have been handled, to check for orphaned server items
  const addedDataItemIds: string[] = [];

  // Use defaults to prevent crash if called without defined values
  // (was happening on multi-stage save when report is unloading)
  const localAnswers = localSavedData ?? { savedAnswers: [] };
  const serverAnswers = serverSavedData ?? { savedAnswers: [] };

  localAnswers.savedAnswers.forEach((local) => {
    const server = getAssociatedSavedAnswer(local, serverAnswers);
    output.push({ local, server });
    addedDataItemIds.push(local.dataItemId);
  });

  // also add orphaned server values
  serverAnswers.savedAnswers
    .filter((item) => !addedDataItemIds.includes(item.dataItemId))
    .forEach((server) => {
      output.push({ local: undefined, server });
    });

  return output;
};

/**
 * Check if a possibly-undefined type is defined,
 * in a way that allows TypeScript to narrow the type
 *
 * @param val value that may be undefined
 * @returns true if the value is defined
 */
function isDefined<T>(val: T | undefined): val is T {
  return typeof val !== 'undefined';
}

export const getUpdatedReportDataItems = (
  localSavedData: SavedReportDataItems,
  serverSavedData: SavedReportDataItems
): SavedReportDataItems => {
  const paired = asPairedArray(localSavedData, serverSavedData);
  const savedAnswers = paired.map(({ local, server }) => nullSafeGetUpdate(local, server)).filter(isDefined);

  return {
    savedAnswers,
  };
};

/**
 * Count saved data items intelligently, counting simple items a 1, and list items as their length.
 *
 * @param savedItems to count
 * @returns total of simple items and nested list items
 */
export function countSavedDataItems(savedItems: SavedReportDataItems) {
  return savedItems.savedAnswers
    .map((dataItem) => {
      if ('selectedReportDataItems' in dataItem.savedReportDataItem) {
        // Updates are only sent for the selected data items, so do not count the item itself
        return dataItem.savedReportDataItem.selectedReportDataItems.length;
      }
      return 1;
    })
    .reduce((a, b) => a + b, 0);
}

/**
 * Clamp the size of updates to within the configured size.
 *
 * Note: limit is intentionally passed in to facilitate testing.
 *
 * @param updatedSavedValues all updated values that need to be saved
 * @param limit maximum number of items that can be included in a single save
 * @returns the passed in updatedSavedValues, or a new object with a subset of values that is within the limit
 */
export function limitUpdateReportDataItems(updatedSavedValues: SavedReportDataItems, limit: number) {
  const totalItems = countSavedDataItems(updatedSavedValues);

  if (totalItems <= limit) {
    // No limiting required, give back original
    return updatedSavedValues;
  }

  let tally = 0;
  const updatesWithinLimit: SavedReportDataItem[] = [];
  const limitedUpdatedSavedValues: SavedReportDataItems = {
    savedAnswers: updatesWithinLimit,
  };

  for (const answer of updatedSavedValues.savedAnswers) {
    if ('selectedReportDataItems' in answer.savedReportDataItem) {
      // LISTSUM: count based on mapped items
      const selectedItems = answer.savedReportDataItem.selectedReportDataItems;

      if (tally + selectedItems.length <= limit) {
        // all can fit, add item unchanged and continue
        tally += selectedItems.length;
        updatesWithinLimit.push(answer);
      } else {
        // cannot fit all, include what can fit
        const availableSpace = limit - tally;
        const limitedSelectedItems = selectedItems.slice(0, availableSpace);
        const limitedAnswer = {
          ...answer,
          savedReportDataItem: {
            ...answer.savedReportDataItem,
            selectedReportDataItems: limitedSelectedItems,
          },
        };

        tally += limitedSelectedItems.length;
        updatesWithinLimit.push(limitedAnswer);
      }
    } else {
      // simple items, or list item with no changes to list: count as single items
      tally += 1;
      updatesWithinLimit.push(answer);
    }

    if (tally === limit) {
      return limitedUpdatedSavedValues;
    }
  }

  // should not be reached based on ititial count, but include as safety net
  return limitedUpdatedSavedValues;
}

/**
 * Get the merged result of saving a subset of local changes to the server.
 *
 * This is needed for partial saves so that the difference between local and server
 * will be accurate when preparing the next save.
 *
 * Only use this if the update is partial. Full updates should just update server
 * value to the local value at the time save was triggered.
 *
 * @param localSavedData
 * @param serverSavedData
 * @param updates
 * @returns
 */
export function getMergedReportDataItems(
  localSavedData: SavedReportDataItems,
  serverSavedData: SavedReportDataItems,
  updates: SavedReportDataItems
): SavedReportDataItems {
  const { savedAnswers: serverAnswers } = serverSavedData;
  const { savedAnswers: updateAnswers } = updates;

  // Current state on server as the starting point
  const answersAfterSave: SavedReportDataItem[] = [...serverAnswers];

  for (const answerUpdate of updateAnswers) {
    const serverAnswer = getAssociatedSavedAnswer(answerUpdate, serverSavedData);
    const localAnswer = getAssociatedSavedAnswer(answerUpdate, localSavedData);

    if (localAnswer) {
      // must be add or update
      const answerIndex = answersAfterSave.findIndex((answer) => answer.dataItemId === answerUpdate.dataItemId);
      if (answerIndex < 0) {
        // add
        answersAfterSave.push(getMergedAnswerUpdate(undefined, answerUpdate, localAnswer));
      } else {
        // update
        answersAfterSave[answerIndex] = getMergedAnswerUpdate(answersAfterSave[answerIndex], answerUpdate, localAnswer);
      }
    } else if (serverAnswer) {
      // delete
      const answerIndex = answersAfterSave.findIndex((answer) => answer.dataItemId === answerUpdate.dataItemId);
      if (answerIndex >= 0) {
        // delete at the index (in-place)
        answersAfterSave.splice(answerIndex, 1);
      }
    }
  }

  return { savedAnswers: answersAfterSave };
}

type SavedActionTypes = SavedReportDataItem['savedReportDataItem'];
function isSavedActionListNumeric(
  savedReportDataItem: SavedActionTypes
): savedReportDataItem is SavedActionListNumeric {
  return 'selectedReportDataItems' in savedReportDataItem;
}

/**
 * Get the new value for a data item server state after a save, based on current states and update
 *
 * @param serverAnswer currente server answer if present
 * @param answerUpdate update operation generated from getUpdatedReportDataItems
 * @param localAnswer current local answer
 * @returns localAnswer if item is a simple type or a list item where the update includes all items,
 *   otherwise a clone of localAnswer with the expected items after the update is applied
 */
export function getMergedAnswerUpdate(
  serverAnswer: SavedReportDataItem | undefined,
  answerUpdate: SavedReportDataItem,
  localAnswer: SavedReportDataItem
) {
  // single-value types always use the local value if they are in the update
  if (!isSavedActionListNumeric(answerUpdate.savedReportDataItem)) {
    return localAnswer;
  }

  const dataItemUpdate = answerUpdate.savedReportDataItem;
  const serverDataItem = serverAnswer?.savedReportDataItem as SavedActionListNumeric | undefined;
  const localDataItem = localAnswer.savedReportDataItem as SavedActionListNumeric;

  const selectedItemUpdates = dataItemUpdate.selectedReportDataItems;
  // Default empty for new items as they have no server value
  const serverSelectedItems = serverDataItem?.selectedReportDataItems ?? [];
  const localSelectedItems = localDataItem.selectedReportDataItems;

  const serverUids = serverSelectedItems.map((item) => item.responseUid);
  const localUids = localSelectedItems.map((item) => item.responseUid);

  const deleteOperationUids = selectedItemUpdates.filter((item) => item.delete).map((item) => item.responseUid);
  const deletedUids = serverUids.filter((uid) => !localUids.includes(uid));
  const allDeletedAccountedFor = deletedUids.every((uid) => deleteOperationUids.includes(uid));

  const addOperationUids = selectedItemUpdates
    .filter((item) => !item.delete && !item.overwrite)
    .map((item) => item.responseUid);
  const addedUids = localUids.filter((uid) => !serverUids.includes(uid));
  const allAddedAccountedFor = addedUids.every((uid) => addOperationUids.includes(uid));

  const overwriteOperationUids = selectedItemUpdates.filter((item) => item.overwrite).map((item) => item.responseUid);
  const updatedOrUnchangedUids = serverUids.filter((uid) => localUids.includes(uid));

  // items present in both that have no update operation
  const maybeUpdatedAndNoOverwriteOperationUids = updatedOrUnchangedUids.filter(
    (uid) => !overwriteOperationUids.includes(uid)
  );
  const allMaybeUpdatedAreIdentical = maybeUpdatedAndNoOverwriteOperationUids.every((uid) => {
    const local = localSelectedItems.find((item) => item.responseUid === uid);
    const server = serverSelectedItems.find((item) => item.responseUid === uid);

    const identical =
      server?.accountName === local?.accountName &&
      server?.code === local?.code &&
      server?.dspValue === local?.dspValue &&
      server?.userValue === local?.userValue;

    return identical;
  });

  if (allDeletedAccountedFor && allAddedAccountedFor && allMaybeUpdatedAreIdentical) {
    // includes all updates, so we can use local value
    return localAnswer;
  }

  // Selected items after save are server items with updates applied
  const selectedReportDataItems = [...serverSelectedItems]
    .map((item) =>
      overwriteOperationUids.includes(item.responseUid)
        ? localSelectedItems.find((i) => i.responseUid === item.responseUid)!
        : item
    )
    .filter((item) => !deleteOperationUids.includes(item.responseUid))
    .concat(localSelectedItems.filter((item) => addOperationUids.includes(item.responseUid)));

  return {
    ...localAnswer,
    savedReportDataItem: {
      ...localAnswer.savedReportDataItem,
      selectedReportDataItems,
    },
  };
}

export function getUpdatedSavedValuesAndServerState(
  savedValues: SavedReportDataItems,
  serverSavedValues: SavedReportDataItems
) {
  const updatedSavedValues = getUpdatedReportDataItems(savedValues, serverSavedValues);
  // limit saved values to prevent failure from excessive payload size
  const limitedUpdatedSavedValues = limitUpdateReportDataItems(updatedSavedValues, payloadLimitReportDataItems);
  const savedAnswersForContext =
    limitedUpdatedSavedValues === updatedSavedValues
      ? savedValues
      : getMergedReportDataItems(savedValues, serverSavedValues, limitedUpdatedSavedValues);

  return [limitedUpdatedSavedValues, savedAnswersForContext] as const;
}

function checkForUpdatedSavedNumber(
  localSavedAnswer: SavedReportDataItem,
  serverSavedAnswer: SavedReportDataItem
): SavedReportDataItem | undefined {
  const localSavedActionNumber = localSavedAnswer.savedReportDataItem as SavedActionNumber;

  const userValue =
    localSavedActionNumber.savedNumber !== undefined ? localSavedActionNumber.savedNumber.toString() : '';

  if (userValue === (serverSavedAnswer.savedReportDataItem as SavedActionNumber).savedNumber?.toString()) {
    return undefined;
  }

  const reportDataItem: SavedReportDataItem = {
    ...localSavedAnswer,
    overwrite: true,
  };

  if (userValue === '') {
    reportDataItem.delete = true;
    reportDataItem.overwrite = false;
  }

  return reportDataItem;
}

function checkForUpdatedSavedText(
  localSavedAnswer: SavedReportDataItem,
  serverSavedAnswer: SavedReportDataItem
): SavedReportDataItem | undefined {
  const localSavedActionText = localSavedAnswer.savedReportDataItem as SavedActionText;
  const serverSavedActionText = serverSavedAnswer.savedReportDataItem as SavedActionText;

  if (localSavedActionText.savedText === serverSavedActionText.savedText) {
    return undefined;
  }

  const reportDataItem: SavedReportDataItem = {
    ...localSavedAnswer,
    overwrite: true,
  };

  if (localSavedActionText.savedText === '') {
    reportDataItem.delete = true;
    reportDataItem.overwrite = false;
    (reportDataItem.savedReportDataItem as SavedActionText).savedText = '';
  }

  return reportDataItem;
}

function checkForUpdatedSavedActionListNumeric(
  localSavedAnswer: SavedReportDataItem,
  serverSavedAnswer: SavedReportDataItem
): SavedReportDataItem | undefined {
  const updatedSavedActionListNumericItems: SavedActionListNumericItem[] = [];

  (localSavedAnswer.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems.forEach(
    (localReportDataItem) => {
      const associatedServerReportDataItem = (
        serverSavedAnswer.savedReportDataItem as SavedActionListNumeric
      ).selectedReportDataItems.find(
        (serverReportDataItem) => serverReportDataItem.responseUid === localReportDataItem.responseUid
      );

      // No server value yet
      const previouslySaved = associatedServerReportDataItem !== undefined;

      // Server deleted, local updated
      const updateAfterDelete = previouslySaved && associatedServerReportDataItem.delete && !localReportDataItem.delete;

      if (!previouslySaved || updateAfterDelete) {
        updatedSavedActionListNumericItems.push(localReportDataItem);
        // has been saved and not in deleted state, did value/name/code change?
      } else if (
        checkForLocalUpdatedDataItem(associatedServerReportDataItem.userValue, localReportDataItem.userValue)
      ) {
        localReportDataItem.overwrite = localReportDataItem.userValue !== '';
        updatedSavedActionListNumericItems.push(localReportDataItem);
      } else if (
        checkForLocalUpdatedDataItem(associatedServerReportDataItem.accountName, localReportDataItem.accountName)
      ) {
        localReportDataItem.overwrite = true;
        updatedSavedActionListNumericItems.push(localReportDataItem);
      } else if (checkForLocalUpdatedDataItem(associatedServerReportDataItem.dspValue, localReportDataItem.dspValue)) {
        localReportDataItem.overwrite = true;
        updatedSavedActionListNumericItems.push(localReportDataItem);
      } else if (
        !!associatedServerReportDataItem.code &&
        associatedServerReportDataItem.code !== localReportDataItem.code
      ) {
        localReportDataItem.overwrite = true;
        updatedSavedActionListNumericItems.push(localReportDataItem);
      }
    }
  );

  // have any items on the server been removed from local?
  (serverSavedAnswer.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems.forEach(
    (serverReportDataItem) => {
      const associatedLocalReportDataItem = (
        localSavedAnswer.savedReportDataItem as SavedActionListNumeric
      ).selectedReportDataItems.find(
        (localReportDataItem) => localReportDataItem.responseUid === serverReportDataItem.responseUid
      );

      if (associatedLocalReportDataItem === undefined) {
        serverReportDataItem.overwrite = false;
        serverReportDataItem.delete = true;
        updatedSavedActionListNumericItems.push(serverReportDataItem);
      }
    }
  );

  // Only update item if any of its items are updated
  if (!updatedSavedActionListNumericItems.length) return undefined;

  const updatedSavedActionListNumeric: SavedActionListNumeric = {
    dataItemId: localSavedAnswer.dataItemId,
    selectedReportDataItems: updatedSavedActionListNumericItems,
  };

  const updatedSavedAction: SavedReportDataItem = {
    ...localSavedAnswer,
    savedReportDataItem: updatedSavedActionListNumeric,
  };

  return updatedSavedAction;
}

export function checkForLocalUpdatedDataItem(
  serverDataItem: string | undefined,
  localDataItem: string | undefined
): boolean {
  return (
    serverDataItem !== undefined &&
    (serverDataItem?.length ?? 0) > 0 && // This was failing when it recieved null
    serverDataItem !== localDataItem
  );
}

/* istanbul ignore file */
export const modalMarginFix = (action: 'open' | 'close') => {
  if (action === 'open') {
    document.body.style.marginRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
    document.body.style.marginRight = '';
  }
};

import { Button, Flex, VisuallyHidden, View, Link, Autocomplete, ComboBoxOption } from '@aws-amplify/ui-react';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import usePostcodeRegions from './usePostcodeRegions';
import './PostcodeSearch.css';

const baseDbrUrl = 'https://dbr.abs.gov.au';

const emptyOptions: ComboBoxOption[] = [];
const filterOptionsByLabel = (option: ComboBoxOption, value: string) => {
  const words = value.toLowerCase().split(' ');
  const [postcode, ...locations] = words;
  const label = option.label.toLowerCase();

  // 8## or 9## postcodes should match 08## and 09## (every postcode starting with 8 or 9 is 3 digits and has 0 prefix)
  // all postcodes in data have 0 prefix for these postcodes
  const matchPostcode = ['8', '9'].includes(postcode[0]) ? `0${postcode}` : postcode;

  return label.startsWith(matchPostcode) && locations.every((word) => label.includes(word));
};

const PostcodeSearch = ({ postcode }: { postcode: string | undefined }) => {
  const [error, setError] = useState<string>();
  const [goClicked, setGoClicked] = useState(false);

  // observing changes only (not control)
  const [inputVal, setInputVal] = useState(postcode);
  const [options, setOptions] = useState(emptyOptions);
  const [menuAbove, setMenuAbove] = useState(false);
  const [loadingPostcodeRegions, postcodeRegionsError, postcodeRegions] = usePostcodeRegions(inputVal);

  const handleGoClick = () => {
    setGoClicked(true);
    if (inputVal) {
      const filteredOptions: ComboBoxOption[] = options.filter((opt) => filterOptionsByLabel(opt, inputVal));

      const firstMatch = filteredOptions[0];
      if (firstMatch && inputVal.length > 3) {
        recordRumCustomEvent(RumCustomEvent.valueBackClickSuccess, getRumAttributes());
        window.open(`${baseDbrUrl}/region.html?lyr=sa2&rgn=${firstMatch.SA2_CODE_2021}`, '_blank');
        return;
      }
    }

    // At this point the displayed options contain no matches for the input
    setError('Postcode not found');
    recordRumCustomEvent(RumCustomEvent.valueBackClickError, getRumAttributes());
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const checkMenuPosition = () => {
    if (!inputRef.current) return;

    setMenuAbove(window.innerHeight - inputRef.current.getBoundingClientRect().bottom < 162);
  };

  useEffect(() => {
    setInputVal(postcode);
  }, [postcode]);

  useEffect(() => {
    checkMenuPosition(); // Initial check
    window.addEventListener('scroll', checkMenuPosition);
    window.addEventListener('resize', checkMenuPosition);

    return () => {
      window.removeEventListener('scroll', checkMenuPosition);
      window.removeEventListener('resize', checkMenuPosition);
    };
  }, []);

  useEffect(() => {
    const newOptions = postcodeRegions.map((item) => {
      const option = {
        // must use combination because some SA2_CODE_2021 span multiple POSTCODE
        id: `${item.POSTCODE}${item.SA2_CODE_2021}`,
        label: `${item.POSTCODE} ${item.SA2_NAME_2021}`,
      };
      // Assign without including in spread to option element
      Object.defineProperty(option, 'SA2_CODE_2021', { value: item.SA2_CODE_2021 });
      return option;
    });
    setOptions(newOptions);
  }, [postcodeRegions]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setError(undefined);
    setInputVal(ev.target.value);
  };

  // selecting does not trigger change event, so record selected option
  const handleSelectOption = (option: ComboBoxOption) => {
    setError(undefined);
    setInputVal(option.label);
  };

  const errorToShow = goClicked ? error || postcodeRegionsError : undefined;
  const hideOptionDropdown = !!errorToShow || !inputVal || inputVal.length < 3;
  const wrapperClassNames = `postcode-search-component ${hideOptionDropdown ? 'hide-option-dropdown' : ''}`;

  return (
    <Flex
      testId="postcode-search-component"
      className={wrapperClassNames}
      flex="0 0 469px"
      direction="column"
      gap="0"
      margin="10px 0"
    >
      <Flex alignItems="flex-start" gap="20px">
        <Autocomplete
          ref={inputRef}
          className={menuAbove ? 'menu-above' : ''}
          testId="search-postcode-input"
          onChange={handleInputChange}
          onSelect={handleSelectOption}
          label="Enter postcode"
          menuSlots={{
            Empty: <View>Postcode not found</View>,
          }}
          flex="0 0 329px"
          labelHidden={false}
          isLoading={loadingPostcodeRegions}
          options={options}
          value={inputVal}
          optionFilter={filterOptionsByLabel}
          hasSearchButton={false}
          hasSearchIcon={false}
          innerStartComponent=""
          hasError={!!errorToShow}
          errorMessage={errorToShow}
          renderOption={({ label }) => (
            <Flex justifyContent="flex-start" gap="8px" alignItems="start">
              <b>{label.substring(0, label.indexOf(' '))}</b>
              <span>{label.substring(label.indexOf(' ') + 1)} </span>
            </Flex>
          )}
        />
        <Button
          variation="primary"
          minWidth="120px"
          marginTop="37px"
          lineHeight="22px"
          padding="7px 16px"
          onClick={handleGoClick}
          style={{ whiteSpace: 'nowrap' }}
        >
          Go to Data by region
          <VisuallyHidden>(opens new window)</VisuallyHidden>
        </Button>
      </Flex>
      <div aria-live="polite">
        {error && (
          <View
            testId="search-postcode-info-box"
            backgroundColor="#FEF3C7"
            padding="14px 20px"
            marginBottom="-10px"
            maxWidth="500px"
          >
            <VisuallyHidden>Postcode not found.</VisuallyHidden>
            Visit{' '}
            <Link href={baseDbrUrl} target="_blank">
              Data by region
              <VisuallyHidden>(external link)</VisuallyHidden>
            </Link>{' '}
            to search other locations
          </View>
        )}
      </div>
    </Flex>
  );
};

export default PostcodeSearch;

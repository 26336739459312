/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Grid, Heading, Image, TextField, View, Button, Link, AlertProps } from '@aws-amplify/ui-react';
import { useLazyQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';

import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';
import { InlineError } from 'components/InlineError/InlineError';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { GET_DSP_ACCESS_TOKEN } from 'apollo/queries/getDSPAccessToken';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { ClaimObligation, GetDSPAccessToken } from 'models';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import { checkErrorMessages, providerDetailsNotFound } from 'utils/errorHandling';
import { externalLinks } from 'lib/externalLinks';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { showNoDsp } from 'utils/getShowNoDspValue';

import ManualAuthHero from 'assets/claim-obligation.jpg';

import './ManualAuthorisation.css';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';

const errorBannerProps: AlertProps = {
  testId: 'manual-authorise-error-banner',
  isDismissible: true,
  marginLeft: '-32px',
  padding: '24px 0',
};

const ManualAuthorisation = () => {
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{businessId: string;}>();
  const navigate = useNavigate();

  const [inlineError, setInlineError] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [claimedError, setClaimedError] = useState(false);

  const [callGetDspAccessToken, GetDspAccessTokenResponse] = useLazyQuery<GetDSPAccessToken | undefined>(GET_DSP_ACCESS_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getDSPAccessToken.success) {
        callClaimObligation({ variables: { dsp: 'NODSP', organisationId: getValues().businessId }});
      } else {
        setInlineError(true);
      }
    },
    onError: () => {
      setInlineError(true);
    },
  });

  const [callClaimObligation, ClaimObligationResponse] = useLazyQuery<ClaimObligation | undefined>(CLAIM_OBLIGATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const errorMessages = data?.claimObligation.errorMessages ?? [];
      if (data?.claimObligation.success) {
        if (data.claimObligation.organisationId) setActiveBusinessAfterClaim(data.claimObligation.organisationId);
        navigate('/dashboard');
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.internalSystemError, providerDetailsNotFound)) {
        setMatchError(true)
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)) {
        setClaimedError(true);
      } else {
        setInlineError(true);
      }
    },
    onError: () => {
      setMatchError(true);
    },
  });


  const handleAuthSubmit = () => {
    setInlineError(false);
    setMatchError(false);
    setClaimedError(false);
    callGetDspAccessToken({ variables: { dsp: 'NODSP', authCode: '' } });
  }

  return showNoDsp ? (
    <Grid
      className="manual-authorise-container"
      templateColumns="repeat(12, 1fr)"
      templateRows="1fr"
      maxWidth="1440px"
      margin="0 auto"
      padding="0 32px"
      gap="16px"
    >
      <View columnStart={1} columnEnd={7}>
      {matchError && (
          <AlertComponent
            title="Entered reference number does not match our records"
            textHeaderComponent={
              <>
                Your reference number is two letters (AT or MU) followed by eight numbers such as 'AT12345678'. 
								This can be found in the email you received asking you to report.
                <br />
                <br />
                If you are still having trouble please{' '}
                <Link 
                  href={externalLinks.absContactUs} 
                  isExternal 
                  onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                >
                  contact us
                </Link>{' '}
                for assistance.
              </>
            }
            variation="error"
            alertProps={errorBannerProps}
          />
        )}
        {claimedError && (
          <AlertComponent
            title="Reference number already claimed"
            textHeaderComponent={
              <>
                That reference number has already been claimed by another organisation. Please{` `}
                <Link 
                  href={externalLinks.absContactUs} 
                  isExternal 
                  onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                >
                  contact us
                </Link>{' '}
                for assistance.
              </>
            }
            variation="error"
            alertProps={errorBannerProps}
          />
        )}
        <Breadcrumb
          text="Back to accounting software selection"
          onClick={() => navigate('/authorisation', { state: { addingBusiness: true }, replace: true })}
        />
        <Heading level={1} testId="manual-authorise-heading" className="manual-authorise-heading">
          Continue to your ABS Business Report
        </Heading>
        <form onSubmit={handleSubmit(handleAuthSubmit)}>
          <Text margin="20px 0">Please enter your reference number</Text>
          
          <Controller
                  name="businessId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="manual-authorise-input"
                      testId="manual-authorise-input"
                      label={
                        <Flex alignItems="center" gap="4px">
                          <Text>Reference number</Text>
                          <InputLabelTooltip
                            title={
                              <Text id="business-id-tooltip" role="tooltip">
                               Your reference number is two letters (AT or MU) followed by eight numbers such as 'AT12345678'. 
															 This can be found in the email you received asking you to report.
                              </Text>
                            }
                            helpTextFor="Business ID"
                            placement="top-start"
                            arrow
                          />
                        </Flex>
                      }
                      hasError={!!errors.businessId}
                      errorMessage={errors.businessId?.message}
                      isDisabled={GetDspAccessTokenResponse.loading || ClaimObligationResponse.loading}
                    />
                  )}
                  rules={{
                    required: 'Reference number is required',
                    pattern: {
                      value: /^[\da-z]{10}$/i,
                      message: 'Reference number must be two letters followed by eight numbers',
                    },
                  }}
                />
          <Flex direction="column" alignItems="flex-start">
            <Button
              className="manual-authorise-submit-btn"
              testId="manual-authorise-submit-btn"
              variation="primary"
              type="submit"
              name="submit-btn"
              isLoading={GetDspAccessTokenResponse.loading || ClaimObligationResponse.loading}
              loadingText="Loading"
              marginTop="16px"
            >
              Submit
            </Button>
            {inlineError && <InlineError />}
          </Flex>
        </form>
        <Text marginTop="40px">
          Need help?{' '}
          <Link 
            href={externalLinks.absContactUs} 
            isExternal 
            testId="claim-obligation-help-link"
            onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
          >
            Contact us!
          </Link>
        </Text>
      </View>
      <View columnStart={7} columnEnd={-1} marginRight="-32px">
        <Image src={ManualAuthHero} alt="" />
      </View>
    </Grid>
  ) : (
    <ErrorPage selectedError={0} />
  );
};

export default ManualAuthorisation;

import { ApolloError } from '@apollo/client';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { rumConfig } from 'lib/authConfig';

let awsRum: AwsRum | null = null;
try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: rumConfig.guestRoleArn,
    identityPoolId: rumConfig.identityPoolId,
    endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: false,
    sessionEventLimit: 0,
  };
  const { APPLICATION_ID } = rumConfig;
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'ap-southeast-2';

  awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

/**
 * Send errors through to rum that are not standard JS errors. For example Apollo type errors
 * @param error Apollo Error object
 */
export const recordRumError = (error: ApolloError) => {
  const maxErrorMsgLength = 200;
  if (error?.message.length > maxErrorMsgLength) error.message = `${error.message.substring(0, maxErrorMsgLength)}...`;
  if (awsRum) awsRum.recordError(error);
};

/**
 * Adds a custom event to send to RUM
 * @param eventType The name that appears in RUM as the event type
 * @param object Data structure to send with the event. Can be any object type
 */
export const recordRumCustomEvent = (eventType: string, object: object = {}) => {
  const typeTrimmed = eventType.trim().replaceAll(' ', '_');
  awsRum?.recordEvent(`custom.${typeTrimmed}`, object);
};

export const recordRumCustomEventWithPageId = (eventType: string, object: object = {}) => {
  const typeTrimmed = eventType.trim().replaceAll(' ', '_');
  awsRum?.recordEvent(`custom.${typeTrimmed}`, { pageId: window.location.pathname, ...object });
};

/**
 * Adds some custom attributes to current RUM connection
 * @param attributes The attribute object. Must be a string, number or bool for value
 */
export const addRumSessionAttributes = (attributes: { [key: string]: string | number | boolean }) => {
  awsRum?.addSessionAttributes(attributes);
};

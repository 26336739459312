import { Button, Flex, Heading } from '@aws-amplify/ui-react';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useLocation } from 'react-router-dom';

import { doLogout } from 'utils/doLogout';

import { ReactComponent as DashboardIconSvg } from 'assets/icon-dashboard.svg';
import { ReactComponent as ManageConnectionsIconSvg } from 'assets/icon-manage-connections.svg';
import { ReactComponent as AnalyticsIconSvg } from 'assets/icon-analytics.svg';
import { ReactComponent as LogoutIconSvg } from 'assets/icon-logout-side-bar.svg';

import './DashboardSideNav.scss';

const DashboardSideNav = () => {
  const location = useLocation().pathname;
  const { oktaAuth } = useOktaAuth();

  const HandleLogout = () => {
    doLogout(oktaAuth);
  };
  return (
    <nav className="dashboard-nav">
      <Heading level={2} testId="navigate-to" marginBottom="18px" fontSize="18px" fontWeight="400">
        Navigate to
      </Heading>
      <ul>
        <li>
          <Link
            data-testid="nav-to-dashboard"
            aria-current={location === '/dashboard'}
            to="/dashboard"
            className={`dashboard dashboard-nav-link ${location === '/dashboard' ? 'active-dashboard-link' : ''}`}
          >
            <Flex alignItems="center" paddingLeft="12px">
              <DashboardIconSvg className="side-nav-icon" />
              Dashboard
            </Flex>
          </Link>
        </li>
        <li>
          <Link
            data-testid="nav-to-manage-business"
            aria-current={location === '/manage-connections'}
            to="/manage-connections"
            className={`manage-connections dashboard-nav-link ${
              location === '/manage-connections' ? 'active-dashboard-link' : ''
            }`}
          >
            <Flex alignItems="center" paddingLeft="12px">
              <ManageConnectionsIconSvg className="side-nav-icon" />
              Manage connections
            </Flex>
          </Link>
        </li>
        <li>
          <Link
            data-testid="nav-to-manage-business"
            aria-current={location === '/industry-insights'}
            to="/industry-insights"
            className={`industry-insights dashboard-nav-link ${
              location === '/industry-insights' ? 'active-dashboard-link' : ''
            }`}
          >
            <Flex alignItems="center" paddingLeft="12px">
              <AnalyticsIconSvg />
              Industry insights
            </Flex>
          </Link>
        </li>
        <li>
          <Button data-testid="dashboard-log-out" className="log-out" onClick={HandleLogout}>
            <Flex alignItems="center">
              <LogoutIconSvg className="side-nav-icon" />
              Log out
            </Flex>
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default DashboardSideNav;

import { ErrorMessage } from 'models';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';

export const providerDetailsNotFound = 'Internal System Error: Unable to find provider details for';

export function checkErrorMessages(errorMessages: ErrorMessage[], checkType: string, checkText?: string) {
  if (checkType === ApiErrorMsg.internalSystemError && checkText) {
    return errorMessages.some((x) => x.message.startsWith(checkText));
  }

  return errorMessages.some((x) => x.errorType === checkType);
}

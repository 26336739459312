import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { env } from 'lib/env';

import Menu from 'components/Menu/Menu';
import { UserInactivity } from 'components/UserInactivity/UserInactivity';
import { View } from '@aws-amplify/ui-react';
import { ServiceDownCheck } from 'pages/ServiceDown/ServiceDownCheck';
import { ServiceDown } from 'pages/ServiceDown/ServiceDown';
import SkipLink from 'components/SkipLink/SkipLink';

// specific 'false' value to suppress in development env
const allowTimeout = env.REACT_APP_TIMEOUT !== 'false';

// total time till log out
const INACTIVITY_TIMEOUT = 1200 * 1000; // 1200s (20 mins)
// time left that a prompt should be shown
const PROMPT_TIMEOUT = 300 * 1000; // 300s (5 mins)

const Layout = ({ pathname }: { pathname: string }) => {
  const auth = useOktaAuth();
  const mointorInactivity = allowTimeout && auth.authState?.isAuthenticated;
  const [apiDown, setApiDown] = useState<boolean>(false);

  ServiceDownCheck(setApiDown);

  // reset focus to highest element on route change
  const focusRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    focusRef.current?.focus();
    focusRef.current?.blur();
  }, [pathname]);

  return !apiDown ? (
    <View tabIndex={-1} ref={focusRef}>
      <SkipLink />
      <Menu />
      <main className="main-wrapper">
        {mointorInactivity && <UserInactivity timeouts={[INACTIVITY_TIMEOUT, PROMPT_TIMEOUT]} />}
        <Outlet />
      </main>
    </View>
  ) : (
    <ServiceDown />
  );
};

export default Layout;

import { useMemo } from 'react';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { TopicKey } from 'models/LocalState/TopicFullDetail';

/**
 * The 'ID' for a step is the URL part used to navigate to them.
 *
 * For topics, this is the 'key' of the topic, which is the short label
 * with spaces removed and lowercased. Not TopicDetail.topic
 */
export type StepID = 'instructions' | TopicKey | 'review' | 'submit';

/**
 * The end part of a report step path, or confirmation.
 */
type StepOrSubmitID = StepID | 'confirmation';

interface StepLink {
  id: StepOrSubmitID;
  link: string;
}

const PREFIX_STEPS = [{ id: 'instructions', link: '/report/instructions' }] as const;

const SUFFIX_STEPS: readonly StepLink[] = [
  { id: 'review', link: '/report/review' },
  { id: 'submit', link: '/report/submit' },
  { id: 'confirmation', link: '/report/confirmation' },
] as const;

/**
 * Find the next or previous step to navigate from a given step by path.
 *
 * Falls back to previous step if next is not available. I believe that
 * behaviour was to support opening the URL of a step that is not available
 * in the current report, and navigating to the closest possible step. This
 * is no longer supported with dynamic topics, but we could make it work for
 * review/confirmation steps.
 *
 * @param currentReportSteps output of useCurrentReportSteps hook
 * @param path the path of the current step
 * @param getPreviousStep true to get previous step, otherwise returns next step
 * @returns a step to navigate to
 */
export const findNextReportStep = (
  currentReportSteps: StepLink[],
  path: string,
  getPreviousStep?: boolean
): StepLink | undefined => {
  const stepIndex = currentReportSteps.findIndex((step) => step.link === path);

  // Fall back on instructions if no step matches path
  if (stepIndex === -1) return currentReportSteps[0];

  const previousStep = stepIndex > 0 ? currentReportSteps[stepIndex - 1] : currentReportSteps[0];

  if (getPreviousStep) return previousStep;

  const nextStep = stepIndex + 1 < currentReportSteps.length ? currentReportSteps[stepIndex + 1] : undefined;

  return nextStep || currentReportSteps[0];
};

const useCurrentReportSteps = () => {
  const currentReportTopics = useCurrentReportTopics();
  return useMemo(
    () => [
      ...PREFIX_STEPS,
      ...currentReportTopics
        .filter(({ isFeedback }) => !isFeedback)
        .map((topicDetail) => ({ id: topicDetail.key, link: topicDetail.path })),
      ...SUFFIX_STEPS,
    ],
    [currentReportTopics]
  );
};

export default useCurrentReportSteps;

import { gql } from '@apollo/client';

export const GET_VALUE_BACK = gql`
  query GetValueBack($providerId: String!, $noOfYears: Int!, $organisationId: String!, $dsp: String!) {
    valueBack(providerId: $providerId, noOfYears: $noOfYears, organisationId: $organisationId, dsp: $dsp) {
      salesToWagesRatio
      percentageBreakdownForSales
      percentageBreakdownForWages
      quarterDates
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        name
        errorStatusCode
        stack
        url
        time
      }
      success
    }
  }
`;

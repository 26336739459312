import { TopicFullDetail } from 'models';
import { getTopicByName } from 'models/LocalState/TopicFullDetail';
import { CompiledRule } from './parseTopicRule';

/**
 * Turn rule parts into human-readable text
 *
 * @param compiledRule rule to convert
 * @param topics topics to look up display names from
 * @returns string representation of the rule
 */
export const generateRuleExplanation = (compiledRule: CompiledRule | undefined, topics: TopicFullDetail[]) => {
  if (!compiledRule) return '';

  // Turn rule parts into human-readable text
  const explanationText = (compiledRule?.ruleParts ?? [])
    .map(({ operation, topicName }) => {
      const ruleTopic = getTopicByName(topics, topicName);
      const ruleTopicText = (ruleTopic?.topicTotalText ?? `total ${topicName}`).toLocaleLowerCase();

      // Capitalise initial value as it is at the start
      if (operation === 'initial') {
        return ruleTopicText.charAt(0).toLocaleUpperCase() + ruleTopicText.slice(1);
      }

      const operationText = { '+': 'plus ', '-': 'less ' }[operation];
      return `${operationText}${ruleTopicText}`;
    })
    .join(' ');

  return explanationText;
};

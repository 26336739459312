/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { useReactiveVar } from '@apollo/client';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { asSavedActionListNumeric, asSavedActionNumber, asSavedActionText } from './savedActionTypeUtil';

export const useTopicsWithMissingData = () => {
  const excludedTopics = ['ASPFeedback', 'Arbitrary', 'Profit or Loss'];

  const localSavedDataItems = useReactiveVar(savedReportDataItems);
  const reportTopics = useCurrentReportTopics();

  return reportTopics
    .filter(({ topic }) => {
      if (excludedTopics.includes(topic)) return false;

      const allAnswers = localSavedDataItems.savedAnswers;
      const thisTopicAnswers = allAnswers.filter((answer) => answer.topic === topic);
      const hasSomeAnswer = thisTopicAnswers.some((answer) => {
        // skip if this represents an answer that has been deleted
        if (answer.delete) return false;

        const listsumItem = asSavedActionListNumeric(answer.savedReportDataItem);
        if (listsumItem)
          return listsumItem.selectedReportDataItems.some(
            ({ userValue, dspValue }) =>
              (userValue !== undefined && userValue.length > 0) || (dspValue !== undefined && dspValue.length > 0)
          );

        const numberItem = asSavedActionNumber(answer.savedReportDataItem);
        if (numberItem) return numberItem.savedNumber !== undefined;

        const textItem = asSavedActionText(answer.savedReportDataItem);
        if (textItem) return textItem.savedText !== '';

        // Not sure what type of item this would be, so exclude it.
        // If we're getting missing data warnings when there is an item with data in it,
        // we have probably missed that type of item here.
        return false;
      });

      // We want topics with no answers, so only valid with 0 non-empty answers
      return !hasSomeAnswer;
    })
    .map(({ topic }) => topic);
};

import { TopicKey, TopicName, TopicFullDetail } from 'models/LocalState/TopicFullDetail';

/**
 * Default topics for use in tests so we don't have so much repetition defining them
 *
 * Use as a base, and add variations by spreading to a new object with any overrides.
 *
 * Access single topics with mockDefaultTopics.{topic}
 * Get a topics array with Object.values(mockDefaultTopics)
 */
export const mockDefaultTopics: Record<string, TopicFullDetail> = {
  employment: {
    key: 'employment' as TopicKey,
    topic: 'Employment' as TopicName,
    topicTitle: 'Employment',
    topicShortTitle: 'Employment',
    topicTotalText: '',
    totalDisplay: 'SHOW',
    path: '/report/employment',
    isFeedback: false,
  },
  income: {
    action: 'LISTSUM',
    key: 'income' as TopicKey,
    topic: 'Income' as TopicName,
    topicTitle: 'Income',
    topicShortTitle: 'Income',
    topicTotalText: 'Total income',
    topicTotalInfo:
      'Dependent on account selections, this may not match the total income value in your accounting software. Please review which accounts have been allocated above.',
    totalDisplay: 'SHOW',
    path: '/report/income',
    isFeedback: false,
  },
  expense: {
    action: 'LISTSUM',
    key: 'expenses' as TopicKey,
    topic: 'Expense' as TopicName,
    topicTitle: 'Expenses',
    topicShortTitle: 'Expenses',
    topicTotalText: 'Total expenses',
    topicTotalInfo:
      'Dependent on account selections, this may not match the total expenses value in your accounting software. Please review which accounts have been allocated above.',
    totalDisplay: 'SHOW',
    path: '/report/expenses',
    isFeedback: false,
  },
  profitorloss: {
    key: 'profitorloss' as TopicKey,
    topic: 'Profit or Loss' as TopicName,
    topicTitle: 'Profit or loss',
    topicShortTitle: 'Profit or loss',
    topicTotalText: 'Net profit or loss before tax',
    topicTotalInfo: 'Total income less total expenses',
    totalDisplay: 'SHOW',
    path: '/report/profitorloss',
    isFeedback: false,

    rule: '$Income-$Expense',
    ruleText: 'Net profit or loss before tax',
    ruleDescription: [
      'Dependent on account selections, this may not match the net profit or loss in your accounting software.',
      'If it is incorrect, please review which accounts have been allocated in the income and expenses sections.',
    ],
    compiledRule: {
      rule: '$Income-$Expense',
      topicNames: ['Income' as TopicName, 'Expense' as TopicName],
      ruleParts: [
        { operation: 'initial', topicName: 'Income' as TopicName },
        { operation: '-', topicName: 'Expense' as TopicName },
      ],
    },
  },
  inventory: {
    action: 'LISTSUM',
    key: 'inventories' as TopicKey,
    topic: 'Inventory' as TopicName,
    topicTitle: 'Closing inventories',
    topicShortTitle: 'Inventories',
    topicTotalText: 'Total closing inventories',
    topicTotalInfo:
      'This value should be the total value of closing inventories, rather than the change in value over the quarter.',
    totalDisplay: 'SHOW',
    path: '/report/inventories',
    isFeedback: false,
  },
};
